import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { AuthGate } from '../Auth';
import HeaderLogos from './HeaderLogos';
import styles from './style.module.scss';

class Header extends Component {

  getUserName(user: any) {
    if (user && user.profile) {
      return user.profile.displayName ? user.profile.displayName : user.id
    } else {
      return ''
    }
  }

  render() {

    return (
      <header className={styles.Header}>
        <AuthGate>
          {({ user, isAuthenticated }) => {
            return (
              <>
                <HeaderLogos isAuthenticated={isAuthenticated} />
                <div className={styles.AuthGateContainer}>
                  {
                    isAuthenticated ? (<div className={styles.AuthGateContainer}>
                      <div className={styles.Logout}>
                        <FormattedHTMLMessage id="user.hi.text" />, {this.getUserName(user)} |
                        <a href={`logout`}>Logout</a>
                      </div>
                    </div>) : (<div className={styles.AuthGateContainer}>
                      <div style={{ color: 'white' }}>
                        <a href="saml/login" style={{ color: 'white' }}>
                          Bocconi Login
              </a>{' '}
              |{' '}
                        <a
                          href={`local/login`}
                          style={{ color: 'white' }}>
                          External Login
              </a>
                      </div>
                    </div>)
                  }
                </div>
              </>
            )

          }}
        </AuthGate>
      </header >
    )
  }
};

export default Header;
