"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var WinnerType;

(function (WinnerType) {
  WinnerType["HIGHEST_SCORE"] = "HIGHEST_SCORE";
  WinnerType["LOWEST_SCORE"] = "LOWEST_SCORE";
})(WinnerType = exports.WinnerType || (exports.WinnerType = {}));

var CompetitionStatus;

(function (CompetitionStatus) {
  CompetitionStatus["DRAFT"] = "DRAFT";
  CompetitionStatus["PUBLISHED"] = "PUBLISHED";
  CompetitionStatus["RUNNING"] = "RUNNING";
  CompetitionStatus["COMPLETED"] = "COMPLETED";
})(CompetitionStatus = exports.CompetitionStatus || (exports.CompetitionStatus = {})); // const user = {
//   id : 'usr1',
//   competitions : {
//     "cmp1" : {
//       playerID : "pl1",
//       teamID : "5"
//     }
//   }
// }
// user.competitions[":competionID"].teamID