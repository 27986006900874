import React, { Fragment, Component } from 'react';
import { BlockMath } from 'react-katex';

import { DSCompetitionKind, DSCClassificationScoreFnName } from '@vas/dsc-share';
import Formulas from '../../shared/formulas';
import { ICompetition } from '@vas/competition-service-share';
import { FormattedHTMLMessage } from 'react-intl';


interface IProp {
  competition: ICompetition
}

class CompetitionSelectedFormula extends Component<IProp> {

  render(){
    const { competition } = this.props;
    if( !competition ) return ;

    let formulas = this.props.competition.meta.kind == DSCompetitionKind.CLASSIFICATION ? Formulas.Classification : Formulas.Regression;
    let founded = null;
    for( var formula of formulas ){
      if(formula.id == competition.meta.scoreFunctionName) founded = formula
    }
    if(!founded) return null;
    
    return (
      <Fragment>
        <BlockMath math={founded.math} />
        {
          this.props.competition.meta.kind == DSCompetitionKind.CLASSIFICATION && 
          this.props.competition.meta.scoreFunctionName == DSCClassificationScoreFnName.AVG &&
          <FormattedHTMLMessage id="classification.standardized" />
        }
      </Fragment>
    )
  }
}

export default CompetitionSelectedFormula;