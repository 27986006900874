import React from 'react';
import classes from './style.module.scss';

interface highlightedBoxProps {
	title: any;
	children: any;
	styles?: object;
}

const highlightedBox = ({ children, title, styles }: highlightedBoxProps) => {
	return (
		<div className={classes.Box} style={styles}>
			{title && <h4 className={classes['Box-title']}>{title}</h4>}
			<div className={classes['Box-content']}>
				{children}
			</div>
		</div>
	);
};

export default highlightedBox;
