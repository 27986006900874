import { updateObject } from '../../shared/utilities';
import { ILeaderBoard } from '@vas/competition-service-share';

interface IAction {
  type: string;
  leaderboard: ILeaderBoard;
  error: string;
  removed: boolean;
  response?: any;
}

const INITIAL_STATE = {
  leaderboard: {},
  status: null,
  isFetching: false,
  isStoring: false,
  didInvalidate: false,
  error: null,
  removed: null
};



interface ILeaderboardIDArgs {
  competitionID: string
  id: string
}

export function leaderboardID( opts: ILeaderboardIDArgs ) {
  return `${opts.competitionID}:${opts.id}`
}


// FETCH / READ
const getLeaderboardStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isFetching: true,
  });
};

const getLeaderboardSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    leaderboard: { ...state.leaderboard, [leaderboardID(action.response)] : action.response },
    isFetching: false
  });
};

const getLeaderboardFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

const leaderboardReducer = (state = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    // READ/GETING
    case `GET_LEADERBOARD_START`:
      return getLeaderboardStart(state, action);
    case `GET_LEADERBOARD_SUCCESS`:
      return getLeaderboardSuccess(state, action);
    case `GET_LEADERBOARD_FAIL`:
      return getLeaderboardFail(state, action);
    default:
      return state;
  }
};

export default leaderboardReducer;
