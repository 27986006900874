import DSCClient from '@vas/dsc-client';

// Import specific actions
import getCompetitionActions from './competition';
import getPlayerActions from './player';
import getSubmissionActions from './submission';
import getTeamActions from './team';

import getLeaderboardActions from './leaderboard';
import { toast } from 'react-toastify';

// @var get data by mocks! (@shared folder)
const MOCK_ACTIVE = false;

/*
 * @todo: Set endpoint and authToken by object inserted in index
 */
const endpoint = window.API_ENDPOINT;
const authToken = '';

const Client = new DSCClient({
  endpoint,
  authToken,
  competitionsNS: 'cs',
  submissionsNS: 'ss',
  adminNS: 'adm'
});
// End Client Config

interface IActionData {
  actionPrefix: string;
  actionTypePrefix: string;
  action?: (data?: any, dispatch?: Function) => Promise<any>;
  mock?: any;
}

const data: IActionData[] = [
  /* COMPETITION */
  ...getCompetitionActions(Client),
  /* PLAYER */
  ...getPlayerActions(Client),
  /* SUBMISSIONS */
  ...getTeamActions(Client),
  /* SUBMISSIONS */
  ...getSubmissionActions(Client),
  /* LEADERBOARD */
  ...getLeaderboardActions(Client)
];

const actions = data.reduce((acc: any, actionData: IActionData) => {
  acc[`${actionData.actionPrefix}`] = (data?: any) => {
    return (dispatch: any) => {
      dispatch({
        type: `${actionData.actionTypePrefix}_START`,
        data
      });
      MOCK_ACTIVE
        ? dispatch({
            type: `${actionData.actionTypePrefix}_SUCCESS`,
            response: actionData.mock
          })
        : actionData.action
        ? actionData
            .action(data, dispatch)
            .then((response: any) => {
              dispatch({
                type: `${actionData.actionTypePrefix}_SUCCESS`,
                response
              });
            })
            .catch((error: any) => {
              console.log(error);
              toast.error(error.message);
              dispatch({
                type: `${actionData.actionTypePrefix}_FAIL`,
                error
              });
            })
        : dispatch({
            type: `${actionData.actionTypePrefix}_SUCCESS`,
            response: actionData.mock
          });
    };
  };
  return acc;
}, {});

// Already attached methods

// Competitions
export const getCompetitions = actions.getCompetitions;
export const getCompetitionsByUser = actions.getCompetitionsByUser;
export const getCompetition = actions.getCompetition;
export const deleteCompetition = actions.deleteCompetition;
export const createCompetition = actions.createCompetition;
export const updateCompetition = actions.updateCompetition;
export const publishCompetition = actions.publishCompetition;
export const resetCompetition = actions.resetCompetition;

// Players
export const getPlayers = actions.getPlayers;
export const setPlayers = actions.setPlayers;
export const appendPlayers = actions.appendPlayers;
export const deletePlayers = actions.deletePlayers;
export const deletePlayer = actions.deletePlayer;

// Teams
export const getTeam = actions.getTeam;
export const getTeams = actions.getTeams;
export const deleteTeam = actions.deleteTeam;

// Submissions
export const getLastSubmission = actions.getLastSubmission;

// Leaderboard
export const getLeaderboard = actions.getLeaderboard;

//@todo: To check according DSCClient cause they'are seem inactive!
export const loadCompetitionDataset = actions.loadCompetitionDataset;
export const sendSubmission = actions.sendSubmission;
export const sendWorkflow = actions.sendWorkflow;
export const getLastWorkflow = actions.getLastWorkflow;
export const getWorkflows = actions.getWorkflows;
export const getCompetitionsByOwner = actions.getCompetitionsByOwner;
