import React, { Component } from 'react';
import styles from './style.module.scss';
import NumPad from 'react-numpad';

interface Props {
  value: number;
  size?: string | number;
  disabled?: boolean;
  onChange: (n: number) => void;
}

class MatrixInput extends Component<Props> {
  static defaultProps = {
    disabled: false,
    size: '40px'
  };
  render() {
    const { value, disabled, size, onChange } = this.props;
    const myTheme = {
      header: {
        primaryColor: '#263238',
        secondaryColor: '#f9f9f9',
        highlightColor: '#e563bb',
        backgroundColor: '#2e418a'
      },
      body: {
        primaryColor: '#263238',
        secondaryColor: '#32a5f2',
        highlightColor: '#FFC107',
        backgroundColor: '#f9f9f9'
      },
      panel: {
        backgroundColor: '#CFD8DC'
      }
    };
    return disabled ? (
      <input
        className={`${styles.MatrixInput} ${styles.disabled}`}
        value={value}
        disabled={true}
        type="number"
        style={{
          width: size
        }}
      />
    ) : (
      <NumPad.Number
        theme={myTheme}
        disabled={disabled}
        value={value}
        negative={false}
        position={'startBottomRight'}
        onChange={(value: any) => {
          onChange(value != '' && value >= 0 ? Number(value) : 1);
        }}>
        <input
          className={`${styles.MatrixInput}`}
          value={value}
          type="number"
          style={{
            width: size
          }}
        />
      </NumPad.Number>
    );
    /*return (
      <input
        className={`${styles.MatrixInput} ${disabled ? styles.disabled : ''}`}
        value={value}
        disabled={disabled}
        type="number"
        style={{
          width: size
        }}
        onChange={e => {
          const value = Number(e.target.value);
          onChange(value >= 0 ? value : 0);
        }}
        onBlur={e => {
          const value = Number(e.target.value);
          onChange(value >= 1 ? value : 1);
        }}
      />
    );*/
  }
}

export default MatrixInput;
