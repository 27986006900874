import React, { Component, CSSProperties, ReactText } from 'react';
import classes from './style.module.scss';
import Pagination from './Pagination';
import chunk from 'lodash/chunk';

interface TableData {
  content: any;
  style?: CSSProperties;
  colSpan?: number;
}
interface Props {
  headers?: TableData[];
  data?: any[][];
  pagination?: boolean;
  paginationRows?: number;
  serverSidePagination?: boolean;
  cursor?: string;
  totalCount?: number;
  fetchMore?: (
    page: number,
    cursor?: string,
    paginationRows?: number,
    totalCount?: number
  ) => void;
}
interface State {
  activePage: number;
}

class Table extends Component<Props, State> {
  static defaultProps = {
    headers: [{ content: 'number' }, { content: 'name' }],
    data: [[{ content: '1' }, { content: 'NAME' }]],
    pagination: false,
    paginationRows: 8,
    serverSidePagination: false
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  render() {
    const {
      headers,
      data,
      pagination,
      paginationRows,
      serverSidePagination,
      totalCount,
      cursor,
      fetchMore
    } = this.props;
    const { activePage } = this.state;
    const pages = chunk(data, paginationRows).length;
    const rows =
      pagination && !serverSidePagination
        ? chunk(data, paginationRows)[activePage - 1]
        : data;

    return (
      <div className={classes['Table-container']}>
        <table
          className={[classes.Table].join(' ')}
          cellSpacing="0"
          cellPadding="0">
          {headers && (
            <thead>
              <tr>
                {headers.map((header, i) => (
                  <th
                    key={i}
                    style={header.style || {}}
                    colSpan={header.colSpan || 1}>
                    {header.content}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          {data && (
            <tbody>
              {rows &&
                rows.map((row, i) => (
                  <tr key={i}>
                    {row.map((cell, y) => (
                      <td
                        key={y}
                        style={cell.style || {}}
                        colSpan={cell.colSpan || 1}>
                        {cell.content}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          )}
        </table>
        {pagination && (
          <Pagination
            activePage={
              serverSidePagination
                ? Math.ceil((Number(cursor) || 1) / (paginationRows || 1))
                : activePage
            }
            pages={
              serverSidePagination
                ? Math.ceil((totalCount || 1) / (paginationRows || 1))
                : pages
            }
            setActivePage={(page: number) =>
              serverSidePagination
                ? fetchMore &&
                  fetchMore(page, cursor, paginationRows, totalCount)
                : this.setState({ activePage: page })
            }
          />
        )}
      </div>
    );
  }
}

export default Table;
