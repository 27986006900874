let partialGet = false;
const getLeaderboardActions = (client: any) => [
  {
    actionPrefix: 'getLeaderboard',
    actionTypePrefix: 'GET_LEADERBOARD',
    action: (data: any) => {
      partialGet = true;
      return client.getLeaderboard(data);
    }
  }
];

export default getLeaderboardActions;
