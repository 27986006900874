const ANCHOR = ':anchor';

export const WELCOME = '/';
export const LOGOUT = '/logout';
export const COMPETITIONS = '/competitions';
export const COMPETITION = `${COMPETITIONS}/:competitionID`;
export const LEADERBOARD = `${COMPETITION}/${ANCHOR}`;

export const ADMIN = '/admin';
export const ADMIN_COMPETITION = `${ADMIN}/competition/:competitionID`;
export const ADMIN_COMPETITION_DATASET = `${ADMIN_COMPETITION}/dataset`;
export const ADMIN_COMPETITION_TEAMS = `${ADMIN_COMPETITION}/teams`;
export const ADMIN_COMPETITION_DETAILS = `${ADMIN_COMPETITION}/details`;
export const ADMIN_COMPETITION_CHOOSE = `${ADMIN_COMPETITION}/choose`;
export const ADMIN_COMPETITION_LEADERBOARD = `${ADMIN_COMPETITION}/leaderboard`;

export const PLAYGROUND = '/playground';
