export const updateObject = (oldState: any, updatedObject: any) => {
  return {
    ...oldState,
    ...updatedObject
  };
};

export const getUploadEndopoint = () => {
  return window.UPLOAD_ENDPOINT;
};
