import React from 'react';
import { IDSCompetition } from '@vas/dsc-share';

export interface IDSCompetitionView extends IDSCompetition {
  actualLeaderboardID: string,
  isCompleted: boolean
  isRunning: boolean
}


/* Data */
export interface IResponse {
  payload: any;
  meta: any;
}

export interface IUser {
  firstName: string;
  lastName: string;
  fullName: string;
  enrollments: Array<IEnrollment>;
}

interface IEnrollment {
  competitionID: string;
  enrollmentDate: string;
}

export interface ICompetition {
  id: string;

  title: string;
  creator: string;
  course: string;
  image?: any;
  kind: 'regression' | 'classification';

  regressionFormula?: number;
  categories?: number;
  weightsMatrix?: number[][];
  standardizeFormula: boolean;
  dataset?: any;

  uploadPlayers: string;

  intro?: any;
  documents: null;
  guideLines?: any;
  regulation?: any;
  addRating?: any;

  pdfSubmission: string;
  startDate: string; // ok
  endDate: string; // ok
  status: 'RUNNING' | 'COMPLETED';

  // manca description
  // manca project
  // manca relatedCompetitions

  imageURL?: string;
  rating?: string;
  uploadGuidelines?: string;
  documentation?: Array<IFile>;
  datasets?: Array<IFile>;
  teacher: string;
  project: string;
  relatedCompetitions?: Array<ICompetitionPreview>;
  players: any;
}

export interface ICompetitionPreview {
  id: string;
  title: string;
  imageURL: string;
}

export interface ICompetitionStatus {
  competitionID: string;
  totalSubmissionCount: number;
  status: string;
  actualServerDate: string;
  players: Array<IPlayer>;
}

export interface IPlayer {
  id: string;
  userID: string;
  name: string;
  lastRank: number | null;
  lastScore: number;
  submissionCount: number;
  lastSubmissionDate: string;
  finalScore: number | null;
  finalRank: number | null;
}

interface ISubmission {
  score: number;
  rank: number;
  submissionDate: string;
  datasetURL: string;
}

interface IRank {
  competitionID: string;
  rank: number;
  score: number;
  updatedAt: string;
}

export interface IFile {
  name: string;
  description?: string;
  url?: string;
  onClick?: Function;
}

/* Components */
export interface IOption {
  value: string;
  label: any;
  selected: boolean;
}

export interface ISelectItem {
  label: string;
  id?: string | undefined;
  value: string;
}

export interface ISelect {
  reference?: any;
  disabled?: boolean;
  value: string;
  items: Array<ISelectItem>;
  name: string;
  clicked: () => void;
  itemClicked: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  styles?: object;
  showList?: string;
}


export enum ILeaderboardType{
  TOTAL='total',
  PARTIAL='partial'
}
