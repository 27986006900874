import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import { RouteComponentProps, matchPath } from 'react-router'

import Competition from '../components/Competition';
import Loader from '../components/Loader';
import Error from '../components/Error';
import NotFound from '../components/NotFound';
import { IDSCompetitionView } from '../shared/interfaces';
import { LEADERBOARD } from '../shared/routes';

const COMPETITION_INTERVAL = 10000;

interface ICompetitionParams {
  competitionID: string
  anchor: string
}

interface ICompetitionControllerProps extends RouteComponentProps<ICompetitionParams> {
  getCompetition( competitionID : string): void
  competition: IDSCompetitionView
  loading: boolean
  error: any
}


class CompetitionController extends Component<ICompetitionControllerProps>{

  interval : any;

  constructor(props: ICompetitionControllerProps){
    super(props);
  }

  getCompetition() {
    this.props.getCompetition( this.props.match.params.competitionID )
  }

  componentDidMount(): void {
    this.getCompetition();
    this.startCompetitionPoolQuery();
  }

  componentDidUpdate(prevProps: ICompetitionControllerProps) {
    if (this.props.match.params.competitionID != prevProps.match.params.competitionID) {
      this.startCompetitionPoolQuery();
    }
  }

  componentWillUnmount() {
    clearInterval( this.interval );
  }

  startCompetitionPoolQuery(){
    clearInterval( this.interval );
    this.interval = setInterval(() => this.getCompetition(), COMPETITION_INTERVAL)
  }

  goLeaderboard(){
    const match = matchPath<ICompetitionParams>(this.props.history.location.pathname, {
      path: LEADERBOARD,
      exact: true,
      strict: false
    })
    return (match && match.params && match.params.anchor == 'leaderboard') ? true : false
  }

  render(){

    if ( this.props.error ) {
      return (<Error />)
    }

    if ( this.props.loading && !this.props.competition ) {
      return <Loader />
    }

    if (!this.props.loading && !this.props.competition) {
      return null;
    }

    return <Competition 
      competition={this.props.competition} 
      goLeaderboard={this.goLeaderboard()}
    />
  }
}



const mapStateToProps = (state: any, props : ICompetitionControllerProps ) => ({
  competition: state.competitionState.competition[props.match.params.competitionID],
  error: state.competitionState.error,
  loading: state.competitionState.isFetching
});

const mapDispatchToProps = (dispatch: any, props: ICompetitionControllerProps) => ({
  getCompetition: ( competitionID : string ) => {
    dispatch(actions.getCompetition({ competitionID }))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetitionController);
