import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import './style.scss';
interface Props {
  value: Date;
  onChange: (date: any) => void;
  minDate?: Date;
  maxDate?: Date;
}
interface State {}

class DatePickerComponent extends Component<Props, State> {
  render() {
    const { value, onChange, minDate, maxDate } = this.props;
    return (
      <DatePicker
        selected={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="dd/MM/yyyy HH:mm"
        timeCaption="time"
      />
    );
  }
}

export default DatePickerComponent;
