import { InputTemplate, IProps, IState } from '../';
import classes from '../styles.module.scss';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

export interface ITextProps extends IProps {
  value: string;
  onBlur?: (data: any) => void;
}

/**
 * This class implements InputTemplate
 */
export abstract class TextInput extends InputTemplate<ITextProps> {
  props: ITextProps = { value: '' };
  constructor(props: ITextProps) {
    super(props);
    this.props = props;
  }
  render() {
    const { controlled, onBlur, value } = this.props;

    return (
      <div
        className={[classes.Input, this.getCustomClasses()].join(' ')}
        style={this.props.styles}>
        {this.props.labelId && this.getLabel()}
        {
          <FormattedMessage
            id={this.props.placeholderId || 'general.emptyMessage'}
            defaultMessage={''}>
            {(placeholder: any) => (
              <input
                type="text"
                defaultValue={controlled ? undefined : value}
                value={controlled ? value : undefined}
                name={this.props.name}
                placeholder={placeholder}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  this.value(value);
                  if (this.props.onChange) this.props.onChange(value);
                }}
                onBlur={() => {
                  controlled
                    ? undefined
                    : onBlur
                    ? onBlur(value)
                    : undefined;
                }}
              />
            )}
          </FormattedMessage>
        }
      </div>
    );
  }
}

export default TextInput;
