import React, { Component } from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import itLocaleData from 'react-intl/locale-data/it';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import AdminSwitch from './components/AdminSwitch';
import { AuthProvider } from './components/Auth';
import Competition from './components/Competition';
import Competitions from './components/Competitions';
// Test for english (remove comment on language)
//language = 'en';
// Containers
import Header from './components/Header';
import Main from './components/Main';
// Routes Components
import Welcome from './components/Welcome';
import CompetitionController from './controllers/CompetitionController';
import history from './history';
import * as routes from './shared/routes';
import enMessages from './translations/en.json';
import itMessages from './translations/it.json';





addLocaleData(itLocaleData);
addLocaleData(enLocaleData);

const messages: any = {
  en: enMessages,
  it: itMessages
};

let language = navigator.language.toLowerCase().split(/[_-]+/)[0];

/* temp: inseriamo en come default */

language = 'en';

if (!messages[language]) {
  language = Object.keys(messages)[0];
}

class App extends Component {
  render() {
    return (
      <IntlProvider
        defaultLocale="it"
        locale={language}
        messages={messages[language] || messages['it']}>
        <AuthProvider endpoint={window.USER_ENDPOINT}>
          <Router history={history}>
            <div className="App">
              <Header />
              <Main>
                <Switch>
                  {/*<Route path="/local/login">
                    {() => (
                      <form action="/local/login" method="post">
                        <input type="text" name="username" />
                        <input type="password" name="password" />
                        <input type="submit" value="login" />
                      </form>
                    )}
                  </Route>*/}
                  <Route path={routes.ADMIN} component={AdminSwitch} />
                  <Route
                    exact={true}
                    path={routes.COMPETITIONS}
                    component={Competitions}
                  />
                  <Route
                    path={routes.COMPETITION}
                    component={CompetitionController}
                  />
                  <Route
                    exact={true}
                    path={routes.LEADERBOARD}
                    component={Competition}
                  />
                  <Route path={routes.WELCOME} component={Welcome} />
                </Switch>
                <ToastContainer
                  position="bottom-right"
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                />
              </Main>
            </div>
          </Router>
        </AuthProvider>
      </IntlProvider>
    );
  }
}

export default App;
