import React from 'react';
import classes from './style.module.scss';
import { FormattedMessage } from 'react-intl';
import { IFile } from '../../shared/interfaces';
import downloadIconUrl from '../../assets/icons/download.svg';

interface IList {
  titleId: string;
  uppercaseTitle?: boolean;
  descriptionId?: string;
  items: any;
  styles?: object;
  showDownloadCta?: boolean;
}

const list = (props: IList) => {
  const {
    titleId,
    uppercaseTitle,
    descriptionId,
    items,
    styles,
    showDownloadCta
  } = props;

  const itemsTpl = items.map((item: IFile, index: number) => {
    const { name, description, url, onClick } = item;

    return (
      <li key={index} className={classes['List-item']}>
        <div>
          <span>{name}</span>
          {description && <span>{description}</span>}
        </div>
        {url ? (
          <a
            className={classes['List-button']}
            href={url}
            title={name}
            download={name}
          >
            {showDownloadCta && <img src={downloadIconUrl} alt="download" />}
            {showDownloadCta ? 'Download' : name}
          </a>
        ) : (
          <div
            className={classes['List-button']}
            onClick={() => onClick && onClick()}>
            {showDownloadCta && <img src={downloadIconUrl} alt="download" />}
            {showDownloadCta ? 'Download' : name}
          </div>
        )}
      </li>
    );
  });

  if (!items.some((item: any) => item.url)) {
    return (
      <div className={classes.List} style={styles}>
        <h4
          className={[
            classes['List-title'],
            uppercaseTitle ? classes['List-title--uppercase'] : ''
          ].join(' ')}>
          <FormattedMessage id={titleId} />
        </h4>
        <p className={classes['List-description']}>
          <FormattedMessage id={'missing-datasets'} />
        </p>
      </div>
    );
  } else {
    return (
      <div className={classes.List} style={styles}>
        <h4
          className={[
            classes['List-title'],
            uppercaseTitle ? classes['List-title--uppercase'] : ''
          ].join(' ')}>
          <FormattedMessage id={titleId} />
        </h4>
        {descriptionId && (
          <p className={classes['List-description']}>
            <FormattedMessage id={descriptionId} />
          </p>
        )}
        <ul className={classes['List-items']}>{itemsTpl}</ul>
      </div>
    );
  }
};

export default list;
