import React, { Component } from 'react';
import styles from './style.module.scss';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Button from '../../Button';
import UploadBox from '../../UploadBox';
import RegressionFormulaChoice from './RegressionFormulaChoice';
import RegressionUploadDataset from './RegressionUploadDataset';
import ClassificationUploadDataset from './ClassificationUploadDataset';
import ClassificationFormulaChoice from './ClassificationFormulaChoice';
import UploadedDatasets from './UploadedDatasets';
import ProgressBar from '../../ProgressBar';
import {
  DSCompetitionKind,
  IRegressionCompetitionMeta,
  IClassificationCompetitionMeta,
  DSCClassificationScoreFnName
} from '@vas/dsc-share';
import CheckBoxInput from '../../InputTemplate/CheckBoxInput';
import { ICompetition } from '@vas/competition-service-share';
import moment from 'moment';
import { range } from 'lodash';

interface IProps {
  data:
    | ICompetition<IRegressionCompetitionMeta>
    | ICompetition<IClassificationCompetitionMeta>;
  setDecision: Function;
  setDecisions: Function;
  disabled: boolean;
  onLoadCompetitionDataset(data?: any): ICompetition;
  goToNextTab: () => void;
  setUpdateCompetition: (lastStep?: boolean) => void;
}

/**
 * Current component state
 */
interface IState {
  progress: {
    value: number;
    show: boolean;
  };
}

class AdminNewCompetitionDatasetUpload extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      progress: {
        value: 0,
        show: true
      }
    };
  }

  render() {
    const { progress } = this.state;
    const {
      data,
      setDecision,
      setDecisions,
      setUpdateCompetition,
      onLoadCompetitionDataset,
      disabled
    } = this.props;
    const {
      trainingVectorUrl,
      testVectorUrl,
      datasetProductionDate
    } = data.meta;
    return (
      <div className={styles.AdminNewCompetitionDatasetUpload}>
        <div className={styles['AdminNewCompetition--row']}>
          <div
            className={[
              styles['AdminNewCompetition--column'],
              styles['align-start']
            ].join(' ')}>
            <label style={{ marginBottom: 25 }}>
              <FormattedMessage id="admin.competition.kind" />
            </label>
            <CheckBoxInput
              disabled={disabled}
              value={
                data.meta && data.meta.kind === DSCompetitionKind.REGRESSION
              }
              placeholderId={'admin.competition.crud.regression'}
              onChange={() => {
                setDecision(
                  'meta.kind',
                  DSCompetitionKind.REGRESSION,
                  setUpdateCompetition
                );
              }}
            />
            <CheckBoxInput
              disabled={disabled}
              value={
                data.meta && data.meta.kind === DSCompetitionKind.CLASSIFICATION
              }
              placeholderId={'admin.competition.crud.classification'}
              onChange={() => {
                const categories = ['1', '2'];
                const matrix = range(2).map(row =>
                  range(2).map(col => (col === row ? 0 : 1))
                );
                setDecisions(
                  [
                    {
                      id: 'meta.kind',
                      value: DSCompetitionKind.CLASSIFICATION
                    },
                    {
                      id: 'meta.categories',
                      value: categories
                    },
                    {
                      id: 'meta.weightsMatrix',
                      value: matrix
                    },
                    {
                      id: 'meta.scoreFunctionName',
                      value: DSCClassificationScoreFnName.AVG
                    }
                  ],
                  setUpdateCompetition
                );
              }}
            />
          </div>
        </div>
        {data.meta.kind === DSCompetitionKind.REGRESSION && (
          <div>
            <RegressionFormulaChoice
              disabled={disabled}
              value={data.meta.scoreFunctionName}
              onChange={(value: any) => {
                setDecision(
                  'meta.scoreFunctionName',
                  value,
                  setUpdateCompetition
                );
              }}
            />
            <RegressionUploadDataset />
          </div>
        )}
        {data.meta.kind === DSCompetitionKind.CLASSIFICATION && (
          <div>
            <ClassificationFormulaChoice
              disabled={disabled}
              setUpdateCompetition={setUpdateCompetition}
              setDecision={setDecision}
              setDecisions={setDecisions}
              data={data as ICompetition<IClassificationCompetitionMeta>}
            />
            <ClassificationUploadDataset
              setUpdateCompetition={setUpdateCompetition}
            />
          </div>
        )}
        {data.meta.kind && (
          <div className={styles['AdminNewCompetition--row']}>
            <div
              className={styles['AdminNewCompetition--column']}
              style={{ width: '49%' }}>
              <div
                className={styles[`AdminNewCompetitionDatasetUpload-upload`]}>
                <UploadBox
                  title={<FormattedMessage id={'upload.dataset.title'} />}
                  description={
                    <FormattedMessage id={'upload.dataset.description'} />
                  }
                  disabled={disabled || data.meta.loadingDataset}
                  endpoint={window.UPLOAD_ENDPOINT}
                  onUploaded={(uploaded: any) => {
                    onLoadCompetitionDataset({
                      competitionID: data.id,
                      datasetURL: uploaded.url
                    });
                  }}
                  accept={'text/csv'}
                />
                {data.meta.loadingDataset && !data.meta.loadingDatasetError && (
                  <div
                    className={
                      styles['AdminNewCompetitionDatasetUpload-status']
                    }>
                    <FormattedMessage
                      id={'admin.competition.loading.dataset'}
                    />
                  </div>
                )}
                {data.meta.loadingDatasetError && (
                  <div
                    className={
                      styles['AdminNewCompetitionDatasetUpload-error']
                    }>
                    <FormattedHTMLMessage
                      id={'competition.datasets.error'}
                      values={{
                        errorsUrl: data.errorsLogUrl
                      }}
                    />
                  </div>
                )}
                {progress && progress.show && (
                  <ProgressBar
                    styles={{ marginTop: '25px' }}
                    value={progress.value}
                  />
                )}
                <a
                  download={true}
                  className={
                    styles['AdminNewCompetitionDatasetUpload-download']
                  }
                  href={
                    data.meta.kind === DSCompetitionKind.REGRESSION
                      ? 'regression-example.csv'
                      : 'classification-example.csv'
                  }>
                  <FormattedMessage id={'admin.download.dataset'} />
                </a>
              </div>
            </div>
            <div style={{ width: '49%', color: 'white' }}>
              <UploadedDatasets
                links={[
                  {
                    name: 'training.csv',
                    url: trainingVectorUrl,
                    disabled: !trainingVectorUrl
                  },
                  {
                    name: 'tests.csv',
                    url: testVectorUrl,
                    disabled: !testVectorUrl
                  }
                ]}
              />
              {datasetProductionDate && (
                <p style={{ textAlign: 'center' }}>
                  <a download={true} href={data.meta.originalDatasetUrl}>
                    last upload:{' '}
                    {moment(datasetProductionDate).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}
                  </a>
                </p>
              )}
            </div>
          </div>
        )}
        <div className={styles['AdminNewCompetition--row']}>
          <div className={styles['AdminNewCompetition--column']}>
            {/*}<div
              className={[
                styles.InputTemplate,
                styles['InputTemplate--start']
              ].join(' ')}>
              <Button
                styles={{ margin: 'auto 0 0' }}
                type={'primary'}
                disabled={true}>
                <FormattedMessage id={'general.download.dataset'} />
              </Button>
            </div>*/}
          </div>
          <div
            className={[
              styles['AdminNewCompetition--column'],
              'u-fullWidth'
            ].join(' ')}>
            <div
              className={[
                styles.InputTemplate,
                styles['InputTemplate--end']
              ].join(' ')}>
              <Button
                styles={{ margin: 'auto 0 0' }}
                type={'primary'}
                clicked={() => {
                  this.props.goToNextTab();
                }}>
                <FormattedMessage id={'general.next'} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminNewCompetitionDatasetUpload;
