import React, { Component } from 'react';
import styles from './style.module.scss';
import Paragraph from '../Paragraph';
import Table from '../Table';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import downloadIconUrl from '../../assets/icons/download.svg';
import playersIcon from '../../assets/icons/players.svg';
import submissionIcon from '../../assets/icons/submission.svg';
import workflowIcon from '../../assets/icons/workflow.svg';
import moment from 'moment';
import { ICompetition } from '@vas/competition-service-share';
import { WorkflowInfo } from '@vas/dsc-share';
import { NavLink } from 'react-router-dom';
import * as routes from '../../shared/routes';
import { FormattedMessage } from 'react-intl';
import isNil from 'lodash/isNil';
import Loader from '../Loader';
import {
  ILeaderBoard,
  ILeaderBoardPositionTotal
} from '@vas/competition-service-share';
import find from 'lodash/find';
import { leaderboardID } from '../../store/reducers/leaderboard';
import ZipButton from '../Zip/ZipController';
interface Props {
  match: any;
  competition: ICompetition;
  loading: boolean;
  error: any;
  onGetCompetition(data?: any): Array<ICompetition>;
  onGetLeaderboard(data?: any): ILeaderBoard;
  getLastWorkflow: Function;
  getWorkflows: Function;
  partialLeaderboard?: ILeaderBoard;
  totalLeaderboard?: ILeaderBoard;
  workflows: any[];
}
interface State {
  feedbackId: string;
  totalLeaderboard?: ILeaderBoard;
  partialLeaderboard?: ILeaderBoard;
}

class AdminCompetition extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      feedbackId: 'competition.leaderboard.loader'
    };
  }

  componentDidMount(): void {
    const {
      onGetCompetition,
      onGetLeaderboard,
      getWorkflows,
      match: {
        params: { competitionID }
      }
    } = this.props;

    onGetCompetition({ competitionID });
    getWorkflows({ competitionID });

    onGetLeaderboard({
      competitionID,
      leaderboardID: 'total'
    });
    onGetLeaderboard({
      competitionID,
      leaderboardID: 'partial'
    });
  }

  renderLeaderboards(): any[] {
    const { totalLeaderboard, partialLeaderboard } = this.props;
    if (
      partialLeaderboard &&
      partialLeaderboard.positions &&
      partialLeaderboard.positions.length
    ) {
      let res: any[] = [];
      (partialLeaderboard.positions as ILeaderBoardPositionTotal[]).map(
        (position: ILeaderBoardPositionTotal) => {
          const { competition } = this.props;
          const team = find(
            competition.teams,
            team => team.id == position.team.id
          );
          if (totalLeaderboard) {
            const totalPosition = totalLeaderboard.positions.filter(
              p => p.team.id === position.team.id
            )[0];
            res.push([
              { content: totalPosition.rank },
              { content: totalPosition.team.name },
              { content: (!isNil(totalPosition.score) && totalPosition.score.toFixed(2)) },
              { content: position.rank },
              { content: (!isNil(position.score) && position.score.toFixed(2)) },
              { content: position.submissionCount },
              {
                content: moment(position.lastSubmissionDate).format(
                  'DD.MM.YYYY - HH:mm'
                )
              },
              {
                content: (
                  <a
                    download
                    href={`${window.API_ENDPOINT}ss/submissions/${
                      this.props.competition.id
                    }/teams/${position.team.id}/csv`}>
                    <img src={downloadIconUrl} alt={'download submissions'} />
                  </a>
                )
              },
              {
                content:
                  team && team.workflowUrl ? (
                    <a href={team.workflowUrl} download>
                      <img src={workflowIcon} alt={'download workflow'} />
                    </a>
                  ) : null
              }
            ]);
          } else {
            res.push([
              { content: position.team.name },
              { content: 'N/D' },
              { content: 'N/D' },
              { content: position.rank },
              { content: position.score },
              { content: position.submissionCount },
              {
                content: moment(position.lastSubmissionDate).format(
                  'DD.MM.YYYY - HH:mm'
                )
              },
              {
                content: (
                  <a
                    download
                    href={`${window.API_ENDPOINT}ss/submissions/${
                      this.props.competition.id
                    }/teams/${position.team.id}/csv`}>
                    <img src={downloadIconUrl} alt={'download submissions'} />
                  </a>
                )
              },
              {
                content:
                  team && team.workflowUrl ? (
                    <a download href={team.workflowUrl}>
                      <img src={playersIcon} alt={'download workflow'} />
                    </a>
                  ) : null
              }
            ]);
          }
        }
      );
      return res;
    } else {
      return [
        [
          {
            content: (
              <FormattedMessage id={'admin.competition.no.leaderboards'} />
            ),
            colSpan: 8
          }
        ]
      ];
    }
  }

  getSubmissionCount(): number {
    const { totalLeaderboard, partialLeaderboard } = this.props;
    return totalLeaderboard
      ? totalLeaderboard.updateCounts
      : partialLeaderboard
      ? partialLeaderboard.updateCounts
      : 0;
  }

  getExtension(url: String) {
    const splitted = url.split('.');
    return splitted[splitted.length - 1];
  }

  render() {
    const { competition, loading, workflows } = this.props;
    const { feedbackId } = this.state;
    if (loading || !competition) {
      return (
        <div className={styles['AdminCompetition-feedback']}>
          <Loader styles={{ margin: '0 20px 0 0' }} />
          <FormattedMessage id={feedbackId} />
        </div>
      );
    }
    return (
      <div className={styles.AdminCompetition}>
        <div className={styles['AdminCompetition-content']}>
          <NavLink
            to={routes.ADMIN}
            className={styles['AdminCompetition-backButton']}>
            <FormattedMessage id={'admin.competition.back'} />
          </NavLink>
          <div className={styles['AdminCompetition-header']}>
            <Paragraph
              large
              title={competition.title}
              styles={{ maxWidth: '600px' }}
            />
            <ul className={styles['AdminCompetition-info']}>
              <li>
                <img src={playersIcon} />
                <span>
                  {competition.players ? competition.players.length : 0}
                </span>
              </li>
              <li>
                <img src={submissionIcon} />
                <span>{this.getSubmissionCount()}</span>
              </li>
            </ul>
          </div>
          {workflows && workflows.length > 0 && (
            <ZipButton
              zipName={'workflows.zip'}
              files={workflows.map((wf: any) => ({
                filename: `${wf.teamID} - ${moment(wf.creationDate).format(
                  `YYYY-MM-DD hh-mm-ss`
                )}.${this.getExtension(wf.workflowUrl)}`,
                url: wf.workflowUrl
              }))}
            />
          )}
          <Table
            pagination={true}
            headers={[
              { content: <FormattedMessage id="table.hash.text" /> },
              { content: <FormattedMessage id="table.name.text" /> },
              { content: <FormattedMessage id="table.score.text" /> },
              { content: <FormattedMessage id="table.partialHash.text" /> },
              { content: <FormattedMessage id="table.partialScore.text" /> },
              { content: <FormattedMessage id="table.submission.text" /> },
              { content: <FormattedMessage id="table.lastUploads.text" /> },
              { content: <FormattedMessage id="table.blank.text" /> },
              { content: <FormattedMessage id="table.blank.text" /> }
            ]}
            data={this.renderLeaderboards()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any, props: Props) => ({
  competition:
    state.competitionState.competition[props.match.params.competitionID],
  workflows: state.competitionState.workflows,
  error: state.competitionState.error,
  loading: state.competitionState.isFetching,
  storing: state.competitionState.isStoring,
  removed: state.competitionState.removed,
  partialLeaderboard:
    state.leaderboardState.leaderboard[
      leaderboardID({
        competitionID: props.match.params.competitionID,
        id: 'partial'
      })
    ],
  totalLeaderboard:
    state.leaderboardState.leaderboard[
      leaderboardID({
        competitionID: props.match.params.competitionID,
        id: 'total'
      })
    ]
});

const mapDispatchToProps = (dispatch: any) => ({
  onGetCompetition: (data?: any) => dispatch(actions.getCompetition(data)),
  onGetLeaderboard: (data?: any) => dispatch(actions.getLeaderboard(data)),
  onGetPartialLeaderboard: (data?: any) =>
    dispatch(actions.getLeaderboard(data)),
  getLastWorkflow: (data?: any) => dispatch(actions.getLastWorkflow(data)),
  getWorkflows: (data?: any) => dispatch(actions.getWorkflows(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCompetition);
