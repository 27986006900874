import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import styles from './style.module.scss';
import { IDSCompetitionView } from '../../shared/interfaces';
import playersIconUrl from '../../assets/icons/players.svg';
import submissionIconUrl from '../../assets/icons/submission.svg';

interface ICompetitionInfoProps {
  competition: IDSCompetitionView;
}

const CompetitionInfo = ({ competition }: ICompetitionInfoProps) => (
  <div className={styles['Competition-description']}>
    <ul className={styles['Competition-registrations']}>
      <li>
        <img src={playersIconUrl} alt={'players'} />
        {competition.players.length}
      </li>
      <li>
        <img src={submissionIconUrl} alt={'submissions'} />
        {competition.leaderboards[competition.actualLeaderboardID]
          ? competition.leaderboards[competition.actualLeaderboardID]
              .updateCounts
          : 0}
      </li>
    </ul>
    <div className={styles['Competition-course']}>
      <FormattedMessage id={'label.course'} /> {competition.meta.project}
    </div>
    <div className={styles['Competition-teacher']}>
      <FormattedMessage id={'label.teacher'} /> {competition.meta.teacher}
    </div>
    <div className={styles['Competition-duration']}>
      <FormattedMessage id={'label.duration'} />{' '}
      <strong>
        {`${moment(new Date(competition.startDate)).format(
          'DD.MM.YYYY HH:mm'
        )} - ${moment(new Date(competition.endDate)).format(
          'DD.MM.YYYY HH:mm'
        )}`}
      </strong>
    </div>
  </div>
);

export default CompetitionInfo;

// styles = {{ margin: '0 0 auto auto' }}
// titleId = { 'competition.leaderboard.title'}
// items = { shortLeaderboard.items }
// isTemp = { shortLeaderboard.isTemp }

//   < Button
// outline
// styles = {{
//   margin: '0 0 10px'
// }}
// clicked = {() => onBackButtonClick()}
// small
// mouseOut = { this.onBackButtonMouseOut }
// mouseOver = { this.onBackButtonMouseOver } >
//   {
//     back?(
//             <img src = { backDarkIconUrl } alt = { 'back'} />
//           ): (
//         <img src={ backIconUrl } alt={ 'back' } />
//             )}
// <FormattedMessage id={'button.backToCompetitionsList'} />
//         </Button >
