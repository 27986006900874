import classes from './styles.module.scss';
import React, { Component } from 'react';
import { ISelectItem } from '../../../../shared/interfaces';

interface ISelectItemProps {
  /**
   * SelectItem properties
   */
  item: ISelectItem;
  /**
   * Name of parent Select
   */
  selectName: string;
  /**
   * On item click event
   */
  onClick?: (item: ISelectItem) => void;
}

export const DEFAULT: ISelectItem = {
  label: 'Seleziona un valore...',
  id: '',
  value: ''
};
/**
 * This class implements InputTemplate
 */
export class SelectItem extends Component<ISelectItemProps> {
  render() {
    return (
      <button
        onClick={(ev: any) => {
          if (this.props.onClick) this.props.onClick(this.props.item);
        }}
        data-id={this.props.item.id}
        data-label={this.props.item.label}
        data-value={this.props.item.value}
        data-name={this.props.selectName}
        className={classes.SelectItem}>
        {this.props.item.label}
      </button>
    );
  }
}

export default SelectItem;
