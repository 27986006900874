import React, { Component } from 'react';
import styles from './style.module.scss';

interface Props {
  checked: boolean;
  disabled?: boolean;
  onChange: (n: boolean) => void;
  label?: string;
}
interface State {}

class Checkbox extends Component<Props, State> {
  render() {
    const { checked, onChange, label, disabled } = this.props;
    return (
      <div
        className={[
          styles.Checkbox,
          styles['Checkbox--reverse'],
          styles['Checkbox--start'],
          'u-shrinkAll'
        ].join(' ')}>
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={(e: any) => !disabled && onChange(e.target.value)}
        />
        <label className={styles['Checkbox--small--label']}>{label}</label>
      </div>
    );
  }
}

export default Checkbox;
