import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-scroll';
import classes from './style.module.scss';
import leaderboardIconUrl from '../../assets/icons/leaderboard.svg';
import { ILeaderBoard } from '@vas/competition-service-share';
import { ILeaderboardType } from '../../shared/interfaces';

const SHORTLEADERBOARD_DIMENSION = 5;

interface ShortLeaderboardProps {
	leaderboard: ILeaderBoard
}

const ShortLeaderboard = ({
	leaderboard
}: ShortLeaderboardProps) => {

	return (
		<div className={classes.ShortLeaderboard} style={{ margin: '0 0 auto auto' }}>
			<div className={classes['ShortLeaderboard-header']}>
				<img
					className={classes['ShortLeaderboard-icon']}
					src={leaderboardIconUrl}
					alt={'leaderboard'}
				/>
				<div>
					<h4 className={classes['ShortLeaderboard-title']}>
						<FormattedMessage id={'competition.leaderboard.title'} />
					</h4>
					{
						leaderboard.id == ILeaderboardType.PARTIAL &&
							<small className={classes['ShortLeaderboard-temp']}>
								(<FormattedMessage id={'general.temporary'} />)
							</small>
					}
				</div>
			</div>
			<ul className={classes['ShortLeaderboard-list']}>
				{
					leaderboard.positions
					&& leaderboard.positions
						.slice(0,SHORTLEADERBOARD_DIMENSION)
						.map((position, index) => {
							return (
								<li className={classes['ShortLeaderboard-item']} key={index}>
									<span>{position.team.name}</span>
								</li>
							);
						})
				}
			</ul>
			<Link
				className={classes['ShortLeaderboard-button']}
				spy={true}
				smooth={true}
				duration={500}
				offset={-50}
				to='leaderboard'
			>
				<FormattedMessage id={'button.leaderboard'} />
			</Link>
		</div>
	);
};

export default ShortLeaderboard;
