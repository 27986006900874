import React from 'react';
import classes from './style.module.scss';

interface sectionProps {
	id?: string;
	children?: any;
	dark?: boolean;
	styles?: object;
	inline?: boolean;
	reference?: any;
}

const section = ({
	id,
	children,
	dark,
	styles,
	inline,
	reference
}: sectionProps) => {
	const sectionClass = [
		classes.Section,
		dark ? classes['Section--dark'] : '',
		inline ? classes['Section--inline'] : ''
	].join(' ');

	return (
		<section ref={reference} id={id} className={sectionClass}>
			<div className={classes['Section-content']} style={styles}>
				{children}
			</div>
		</section>
	);
};

export default section;
