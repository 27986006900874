import { LOCAL_STORAGE } from './constants';

export const checkValidEmail = (email: string): boolean => {
	if (!email || email.trim() === '') {
		return false;
	}

	const regExp =
		'^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

	const matcher = new RegExp(regExp);
	const keywordTest = email.trim();

	return matcher.test(keywordTest);
};

export const checkValidPhone = (prefix: string, phone: string): boolean => {
	if ((!prefix || !phone) && (prefix.trim() === '' || phone.trim() === '')) {
		return false;
	}

	let valid = false;

	const fullPhone = prefix.trim() + phone.trim();

	if (fullPhone.length > 15) {
		valid = false;
	} else {
		if (phone.trim() !== '') {
			const regExp = '^[0-9]*$';
			const matcher = new RegExp(regExp);

			const keywordTest = phone.trim();

			valid = matcher.test(keywordTest);
		} else {
			valid = false;
		}
	}

	return valid;
};

export const resetSession = (): void => {
	localStorage.removeItem(LOCAL_STORAGE.KEYS.AUTH_TOKEN);
};

export const textTruncate = (str: string, length: number, ending: string) => {
	if (length == null) {
		length = 100;
	}
	if (ending == null) {
		ending = '...';
	}
	if (str.length > length) {
		return str.substring(0, length - ending.length) + ending;
	} else {
		return str;
	}
};
