import React, { Component } from 'react';
import styles from './style.module.scss';
import closeIconUrl from '../../assets/icons/close.svg';
import { FormattedMessage } from 'react-intl';

interface IProps {
  titleId?: string;
  children: any;
  onModalCloseClicked: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  height?: number;
  width?: number;
  style?: object;
  showCloseButton?: boolean;
}

interface IState {
  showContainer: boolean;
}

class Modal extends Component<IProps, IState> {
  private modal!: HTMLDivElement;
  private lastScroll = 0;
  constructor(props: IProps) {
    super(props);

    this.state = {
      showContainer: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.lastScroll = window.scrollY;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event: any) {
    event.preventDefault();
    event.stopPropagation();
    window.scrollTo(0, this.lastScroll);
    return false;
  }

  handleClick = (e: any) => {
    if (this.modal && !this.modal.contains(e.target)) {
      this.props.onModalCloseClicked(e);
    }
  };

  render() {
    const {
      style,
      children,
      height,
      width,
      showCloseButton,
      onModalCloseClicked,
      titleId
    } = this.props;

    const containerStyles = {
      ...style,
      width: width ? `${width}px` : '470px',
      //height: height ? `${height}px`: '470px',
      top: height ? `calc(50% - ${height / 2}px)` : `calc(50% - 235px)`,
      left: width ? `calc(50% - ${width / 2}px)` : `calc(50% - 235px)`
    };

    return (
      <div className={styles.Modal} style={style}>
        <div
          style={containerStyles}
          className={styles['Modal-container']}
          ref={(node: any) => {
            this.modal = node;
          }}>
          {showCloseButton && (
            <button
              onClick={onModalCloseClicked}
              className={styles['Modal-close']}>
              <img src={closeIconUrl} alt="close" />
            </button>
          )}
          {titleId && (
            <h1 className={styles['Modal-title']}>
              <FormattedMessage id={titleId} />
            </h1>
          )}
          <div className={styles['Modal-content']}>{children}</div>
        </div>
      </div>
    );
  }
}

export default Modal;
