import React from 'react';
import classes from './style.module.scss';

interface ISeparator {
	styles?: object;
}

const separator = (props: ISeparator) => {
	return <div style={props.styles} className={classes.Separator} />;
};

export default separator;
