import history from '../../history';

const getCompetitionActions = (client: any) => [
  {
    actionPrefix: 'getCompetitions',
    actionTypePrefix: 'GET_COMPETITIONS',
    action: client.getCompetitions
  },
  {
    actionPrefix: 'getCompetitionsByUser',
    actionTypePrefix: 'GET_USER_COMPETITIONS',
    action: client.getCompetitionsByUser
  },
  {
    actionPrefix: 'getCompetitionsByOwner',
    actionTypePrefix: 'GET_OWNER_COMPETITIONS',
    action: client.getCompetitionsByOwner
  },
  {
    actionPrefix: 'getCompetition',
    actionTypePrefix: 'GET_COMPETITION',
    action: client.getCompetition
  },
  {
    actionPrefix: 'createCompetition',
    actionTypePrefix: 'ADD_COMPETITION',
    action: client.createCompetition
  },
  {
    actionPrefix: 'updateCompetition',
    actionTypePrefix: 'UPDATE_COMPETITION',
    action: client.updateCompetition
  },
  {
    actionPrefix: 'publishCompetition',
    actionTypePrefix: 'PUBLISH_COMPETITION',
    action: async (data: any) => {
      return await client.publishCompetition(data);
    }
  },
  {
    actionPrefix: 'resetCompetition',
    actionTypePrefix: 'RESET_COMPETITION',
    action: client.resetCompetition
  },
  {
    actionPrefix: 'updateCompetitionMeta',
    actionTypePrefix: 'UPDATE_COMPETITION_META',
    action: client.updateCompetitionMeta
  },
  {
    actionPrefix: 'deleteCompetition',
    actionTypePrefix: 'DELETE_COMPETITION',
    action: client.deleteCompetition
  },
  {
    actionPrefix: 'sendWorkflow',
    actionTypePrefix: 'SEND_WORKFLOW',
    action: client.sendWorkflow
  },
  {
    actionPrefix: 'getLastWorkflow',
    actionTypePrefix: 'GET_LAST_WORKFLOW',
    action: client.getLastWorkflow
  },
  {
    actionPrefix: 'getWorkflows',
    actionTypePrefix: 'GET_WORKFLOWS',
    action: client.getWorkflows
  },
  {
    actionPrefix: 'loadCompetitionDataset',
    actionTypePrefix: 'LOAD_COMPETITION_DATASET',
    action: client.loadCompetitionDataset
  }
];

export default getCompetitionActions;
