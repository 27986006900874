import React from 'react';
import classes from './style.module.scss';

interface titleProps {
	children?: any;
	styles?: object;
	large?: boolean;
	medium?: boolean;
}

const title = ({ children, styles, large, medium }: titleProps) => {
	const titleClass = [
		classes.Title,
		large ? classes['Title--large'] : '',
		medium ? classes['Title--medium'] : ''
	].join(' ');

	return <h1 className={titleClass}>{children}</h1>;
};

export default title;
