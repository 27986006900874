import React from 'react';
import deleteIcon from '../../assets/icons/delete.svg';
import classes from './style.module.scss';
interface IFile {
  name: string;
  url: string;
}
interface uploadedFilesProps {
  files?: Array<IFile>;
  onDelete?: (index: number) => void;
}

const UploadedFiles = ({ files, onDelete }: uploadedFilesProps) => (
  <div className={classes.UploadedFiles}>
    {files &&
      files.map((file, index) => (
        <div key={index} className={classes.UploadedFile}>
          <span>
            <a
              style={{ color: '#FFF' }}
              download={file.name}
              href={file.url}>
              {file.name}
            </a>
          </span>
          {onDelete && (
            <img src={deleteIcon} onClick={() => onDelete && onDelete(index)} />
          )}
        </div>
      ))}
  </div>
);

export default UploadedFiles;
