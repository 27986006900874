import React, { Component } from 'react';
import { ButtonStatus } from '../types';
import styles from './style.module.scss';
interface Props {
  request?: () => void;
  download?: string;
  status: ButtonStatus;
  tailIndex?: number;
}
interface State {}

class ZipButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  render() {
    const { status, tailIndex, request, download } = this.props;
    switch (status) {
      case ButtonStatus.ACTIVE:
        return (
          <a
            className={`${styles.zipButton} ${styles.active}`}
            onClick={() => request && request()}>
            Download Workflows
          </a>
        );
      case ButtonStatus.WAITING:
        return (
          <a className={`${styles.zipButton} ${styles.waiting}`}>
            ...waiting for {tailIndex} zips
          </a>
        );
      case ButtonStatus.PROCESSING:
        return (
          <a className={`${styles.zipButton} ${styles.processing}`}>
            ...processing
          </a>
        );
      case ButtonStatus.READY:
        return (
          <a className={`${styles.zipButton} ${styles.ready}`} href={download}>
            DOWNLOAD
          </a>
        );
      default:
        return (
          <a className={`${styles.zipButton}`}>ERROR: Status not found!</a>
        );
    }
  }
}

export default ZipButton;
