import React, { Component } from 'react';
import classes from './style.module.scss';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';

export interface ITabButton {
  titleId: string;
  component: any;
  relativePath: string;
  fullPath: any;
  route?: string;
  active?: boolean;
}

interface IProps {
  buttons: ITabButton[];
  step: boolean;
  onTabButtonClick?: (o: ITabButton) => void;
}

interface IState {
  buttons: ITabButton[];
}

export class Tabs extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      buttons: this.props.buttons
    };
  }

  /**
   * create tab header according with props buttons
   */
  private createTabsHeader() {
    const { buttons } = this.state;
    let result = [];

    for (let i in buttons) {
      result.push(
        <Button
          small
          key={`${buttons[i].titleId}_button`}
          type={!buttons[i].active ? 'tab--inactive' : 'tab--active'}
          clicked={() => {
            if (this.props.onTabButtonClick)
              this.props.onTabButtonClick(buttons[i]);
          }}>
          <FormattedMessage id={buttons[i].titleId} />
        </Button>
      );
    }

    return result;
  }

  /**
   * returns current tab progress
   */
  private getCurrentProgress(): number {
    let lastButtonIndex = 0;

    const { buttons } = this.state;
    const buttonsLength = buttons.length - 1;

    for (let o of buttons) {
      if (o.active) {
        lastButtonIndex++;
      }
    }

    return (100 * lastButtonIndex) / buttonsLength - 100 / buttonsLength;
  }

  /**
   * return progress html if step is true
   */
  getTabProgress() {
    const { step } = this.props;
    if (!step) {
      return '';
    }
    const currentProgress = this.getCurrentProgress();
    const progressStyle = {
      backgroundImage: `linear-gradient(to right, #fff ${currentProgress}%, transparent ${currentProgress}%)`
    };
    return <div className={classes['Tabs-separator']} style={progressStyle} />;
  }

  render() {
    return (
      <div className={classes.Tabs}>
        <div className={classes['Tabs-selector']}>
          {this.createTabsHeader()}
          {this.getTabProgress()}
        </div>
      </div>
    );
  }
}
