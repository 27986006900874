import React, { Component, Fragment } from 'react';
import Table from '../../Table';
import { IPlayer, IPage, IPageFilter } from '@vas/competition-service-share';
import modifyIconUrl from '../../../assets/icons/modify.svg';
import deleteIconUrl from '../../../assets/icons/delete.svg';
import { FormattedMessage } from 'react-intl';
import Modal from '../../Modal';
import Button from '../../Button';

interface Props {
  players: IPage<IPlayer>;
  onFetchMore: (page: IPageFilter) => void;
  onPlayerEdit?: (player: IPlayer) => void;
  onPlayerDelete?: (player: IPlayer) => void;
  tableRows: number;
  disabled: boolean;
}
interface State {
  showModal: boolean;
  playerToRemove: IPlayer | null;
}

class PlayersTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      playerToRemove: null
    };
  }
  getPlayers() {
    let res: any[] = [];
    const { players, disabled } = this.props;
    if (players && players.results && players.results.length) {
      players.results.map((player: IPlayer, n) =>
        res.push([
          { content: n + 1 },
          { content: player.name || '' },
          { content: player.team ? player.team.name : '' },
          {
            content: (
              <div>
                {/*<img
                  style={{ marginRight: '20px' }}
                  src={modifyIconUrl}
                  onClick={(ev: any) => {
                    if (this.props.onPlayerEdit)
                      this.props.onPlayerEdit(player);
                  }}
                />*/}
                {!disabled && (
                  <img
                    src={deleteIconUrl}
                    onClick={() =>
                      this.setState({ showModal: true, playerToRemove: player })
                    }
                  />
                )}
              </div>
            )
          }
        ])
      );
    } else {
      res.push([
        {
          content: <FormattedMessage id="players.table.no.players" />,
          colSpan: 4
        }
      ]);
    }

    return res;
  }
  closeModal() {
    this.setState({ showModal: false, playerToRemove: null });
  }
  render() {
    const { players, onFetchMore, tableRows, onPlayerDelete } = this.props;
    const { showModal, playerToRemove } = this.state;
    return (
      <Fragment>
        {showModal && (
          <Modal
            titleId={'admin.player.remove'}
            showCloseButton={false}
            onModalCloseClicked={() => {
              this.closeModal();
            }}>
            <Button type={'undo'} clicked={() => this.closeModal()}>
              <FormattedMessage id={'button.undo.remove'} />
            </Button>
            <Button
              type={'confirm'}
              clicked={() => {
                this.closeModal();
                if (onPlayerDelete && playerToRemove)
                  onPlayerDelete(playerToRemove);
              }}>
              <FormattedMessage id={'button.accept.remove'} />
            </Button>
          </Modal>
        )}
        <Table
          pagination={true}
          serverSidePagination={true}
          cursor={players.cursor}
          totalCount={players.totalCount}
          paginationRows={tableRows}
          fetchMore={page => {
            onFetchMore({
              cursor: `${(page - 1) * tableRows}`,
              limit: tableRows
            });
          }}
          headers={[
            { content: <FormattedMessage id="players.table.id" /> },
            { content: <FormattedMessage id="players.table.name" /> },
            { content: <FormattedMessage id="players.table.team" /> },
            { content: <FormattedMessage id="players.table.actions" /> }
          ]}
          data={this.getPlayers()}
        />
      </Fragment>
    );
  }
}

export default PlayersTable;
