import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminNewCompetition from '../AdminNewCompetition';
import Admin from '../Admin';
import AdminCompetition from '../AdminCompetition';
import * as routes from '../../shared/routes';
import { AuthGate } from '../Auth';
interface Props {}
interface State {}

class AdminSwitch extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <AuthGate>
        {({ user, isAuthenticated, isAuthorized }) => {
          if (!isAuthenticated)
            return 'Ops! It seems you are not authorized to enter this page. Please Login to enter.';
          // if (!isAuthorized) return 'Ops! It seems you are not authorized to enter this page. Please Login to enter.'

          return (
            <Switch>
              <Route exact={true} path={routes.ADMIN}>
                {props => user && <Admin {...props} user={user} />}
              </Route>
              <Route
                exact={true}
                path={routes.ADMIN_COMPETITION}
                component={AdminNewCompetition}
              />
              <Route
                exact={true}
                path={routes.ADMIN_COMPETITION_CHOOSE}
                component={AdminNewCompetition}
              />
              <Route
                exact={true}
                path={routes.ADMIN_COMPETITION_DATASET}
                component={AdminNewCompetition}
              />
              <Route
                exact={true}
                path={routes.ADMIN_COMPETITION_TEAMS}
                component={AdminNewCompetition}
              />
              <Route
                exact={true}
                path={routes.ADMIN_COMPETITION_DETAILS}
                component={AdminNewCompetition}
              />
              <Route
                exact={true}
                path={routes.ADMIN_COMPETITION}
                component={AdminCompetition}
              />
              <Route
                exact={true}
                path={routes.ADMIN_COMPETITION_LEADERBOARD}
                component={AdminCompetition}
              />
            </Switch>
          );
        }}
      </AuthGate>
    );
  }
}

export default AdminSwitch;
