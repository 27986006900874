import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './style.module.scss';

import logoUrl from '../../assets/img/bocconi.png';

interface logoProps {
	alt?: string;
	to?: string;
}

const logo = ({ alt, to }: logoProps) => {
	const logoImageClass = [classes['Logo-image']].join(' ');

	return (
		<div className={classes.Logo}>
			{to ? (
				<NavLink to={to}>
					<img className={logoImageClass} src={logoUrl} alt={alt || 'logo'} />
				</NavLink>
			) : (
				<img className={logoImageClass} src={logoUrl} alt={alt || 'logo'} />
			)}
		</div>
	);
};

export default logo;
