import React, { Component } from 'react';
import styles from './style.module.scss';
import Modal from '../../Modal';
import Section from '../../Section';
import UploadBox from '../../UploadBox';
import ProgressBar from '../../ProgressBar';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import TextInput from '../../InputTemplate/TextInput';
import Button from '../../Button';
import ToggleInput from '../../InputTemplate/ToggleInput';
import AutoCompleteInput from '../../InputTemplate/AutoCompleteInput';
import * as actions from '../../../store/actions';
import PlayersTable from './PlayersTable';
import { connect } from 'react-redux';
import {
  IPlayer,
  IPlayerInput,
  IPageFilter,
  IPage,
  ITeam
} from '@vas/competition-service-share';
import XLSX from 'xlsx';
const TableRows = 8;

interface IProps {
  data: Partial<any>;
  setDecision: Function;
  refetchPlayers: boolean;
  disabled: boolean;
  goToNextTab: () => void;
  onGetPlayers: (data?: any) => IPlayer[];
  onAppendPlayers: (data?: any) => IPlayer[];
  onUpdatePlayer: (data?: any) => IPlayer[];
  onDeletePlayer: (data?: any) => IPlayer[];
  onGetTeams: (data?: any) => ITeam[];
  players?: IPage<IPlayer>;
  teams: ITeam[];
  setUpdateCompetition: () => void;
}

interface IState {
  data: object;
  progress: {
    value: number;
    show: boolean;
  };
  showModal: boolean;
  teamModalValue?: string;
  players?: IPage<IPlayer>;
  cursor: string;
  playerId: string;
  selectedTeamID: string;
}

class AdminCompetitionCrudTeamStudents extends Component<IProps, IState> {
  pullInterval: any;
  constructor(props: IProps) {
    super(props);
    const { data } = this.props;

    this.state = {
      selectedTeamID: '',
      data: data || {},
      progress: {
        value: 0,
        show: true
      },
      showModal: false,
      cursor: '0',
      playerId: ''
    };

    this.onSelectedFiles = this.onSelectedFiles.bind(this);
    this.onHandleTeamCompositionToggled = this.onHandleTeamCompositionToggled.bind(
      this
    );
  }

  componentDidMount() {
    const { data, onGetPlayers, onGetTeams } = this.props;
    onGetPlayers({
      competitionID: data.id,
      page: { cursor: '0', limit: TableRows }
    });
    onGetTeams({ competitionID: data.id });
  }
  componentWillUnmount() {
    clearInterval(this.pullInterval);
  }
  componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    if (nextProps.refetchPlayers === true) {
      const { data, onGetPlayers, onGetTeams } = this.props;
      onGetPlayers({
        competitionID: data.id,
        page: { cursor: '0', limit: TableRows }
      });
      onGetTeams({ competitionID: data.id });
    }
  }

  parseXLXS(file: File) {
    const { data } = this.props;
    let reader = new FileReader();
    return new Promise<IPlayerInput[]>(resolve => {
      reader.onload = (e: any) => {
        const rawData = new Uint8Array(e.target.result);
        const workbook = XLSX.read(rawData, { type: 'array' });
        let rows = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]]
        );
        resolve(
          rows.map((row: any) => ({
            userID: row.id,
            teamID: row.team,
            competitionID: data.id,
            displayName: row.displayName
          }))
        );
      };
      reader.readAsArrayBuffer(file);
    });
  }

  async onSelectedFiles(uploaded: any, rejected: any) {
    const { data } = this.props;
    let newPlayers: Array<IPlayerInput> = [];
    if (uploaded) {
      newPlayers = await this.parseXLXS(uploaded[0] as File);
    }
    if (newPlayers.length > 0) {
      this.props.onAppendPlayers({
        competitionID: data.id,
        players: newPlayers
      });
    }
  }

  onHandleTeamCompositionToggled = (checked: boolean) => {
    const { setDecision, setUpdateCompetition } = this.props;
    setDecision('singlePlayerTeams', checked, setUpdateCompetition);
  };

  addPlayer() {
    const { data, onAppendPlayers } = this.props;
    const { selectedTeamID, playerId } = this.state;
    const newPlayer = {
      teamID: data.singlePlayerTeams ? playerId.trim() : selectedTeamID,
      competitionID: data.id,
      userID: playerId.trim()
    };
    onAppendPlayers({
      competitionID: data.id,
      players: [newPlayer]
    });
    this.setState({
      selectedTeamID: '',
      playerId: ''
    });
  }

  render() {
    const { progress, showModal, selectedTeamID, playerId } = this.state;
    const {
      onGetPlayers,
      onUpdatePlayer,
      onDeletePlayer,
      data,
      players,
      teams
    } = this.props;
    const disabled = false;
    const sectionStyles = {
      padding: '40px 0 50px',
      borderBottom: '1px solid rgba(0,0,0,0.2)',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    };
    // console.log('players', players);
    return (
      <div className={styles.AdminNewCompetitionTeamStudents}>
        {/*showModal && (
          <Modal
            titleId={'admin.player.edit'}
            showCloseButton={true}
            onModalCloseClicked={() => this.setState({ showModal: false })}>
            <TextInput
              customClasses={['Input--vertical']}
              labelId={'admin.player.name'}
              theme={'dark'}
              value={editPlayer.name || ''}
              controlled={false}
              placeholderId={'admin.player.name'}
              styles={{ maxWidth: '270px', alignItems: 'flex-start' }}
              onBlur={value => {
                editPlayer.name = value;
                this.setState({ editPlayer });
              }}
            />
            <Button
              type={'confirm'}
              clicked={() => {
                onUpdatePlayer({ competitionID: data.id, {} });
              }}>
              <FormattedMessage id={'admin.player.edit'} />
            </Button>
          </Modal>
        )*/}
        <Section inline styles={sectionStyles}>
          <div>
            <span className="AdminSectionTitle">
              <FormattedMessage id={'admin.participants.title'} />
            </span>
            <ToggleInput
              styles={{ maxWidth: '23rem', margin: '2rem 0 1rem 0' }}
              value={data.singlePlayerTeams}
              labelId={'admin.team.switch.singles'}
              disabled={disabled || !players || players.totalCount > 0}
              onChange={this.onHandleTeamCompositionToggled}
            />
            <div className={styles['AdminNewCompetitionTeamStudents-column']}>
              <TextInput
                customClasses={['Input--vertical']}
                value={this.state.playerId}
                controlled={true}
                labelId={'admin.participants.placeholder'}
                placeholderId={'admin.participants.placeholder'}
                styles={{
                  maxWidth: '230px',
                  alignItems: 'flex-start',
                  marginRight: '5rem'
                }}
                disabled={disabled}
                onChange={value => this.setState({ playerId: value })}
              />
              {!data.singlePlayerTeams && (
                <AutoCompleteInput
                  value={selectedTeamID}
                  placeholderId={'admin.team.placeholder'}
                  labelId={'admin.team.title'}
                  controlled={true}
                  disabled={disabled}
                  items={
                    teams
                      ? teams.map(team => ({
                          id: team.id,
                          value: team.id,
                          label: team.id
                        }))
                      : []
                  }
                  onItemClicked={item =>
                    this.setState({ selectedTeamID: item.value })
                  }
                  onChange={team => this.setState({ selectedTeamID: team })}
                />
              )}
            </div>
            <Button
              styles={{ maxWidth: '220px', height: '52px' }}
              type={'primary'}
              small
              disabled={
                !playerId || (data.singlePlayerTeams ? false : !selectedTeamID)
              }
              clicked={() => {
                this.addPlayer();
              }}>
              <FormattedMessage id={'admin.participant.add'} />
            </Button>
          </div>
          <div style={{ maxWidth: '480px' }}>
            <label className={styles['AdminNewCompetitionTeamStudents-label']}>
              <FormattedMessage id={'admin.upload.participants.title'} />
            </label>
            <label
              className={[
                styles['AdminNewCompetitionTeamStudents-label'],
                styles['AdminNewCompetitionTeamStudents-label--small']
              ].join(' ')}>
              <FormattedHTMLMessage
                id={'admin.upload.participants.description'}
              />
            </label>
            <UploadBox
              disabled={disabled}
              title={
                <FormattedMessage
                  id={'admin.upload.participants.placeholder'}
                />
              }
              onSelectedFiles={this.onSelectedFiles}
              progress={{
                show: true,
                value: 70
              }}
              styles={{ height: '80px' }}
              local={true}
            />
            {progress && progress.show && (
              <ProgressBar
                styles={{ marginTop: '25px' }}
                value={progress.value}
              />
            )}
          </div>
        </Section>
        {players && (
          <Section styles={{ ...sectionStyles, border: 0 }}>
            <div className={styles['AdminNewCompetitionTeamStudents-table']}>
              <label
                className={styles['AdminNewCompetitionTeamStudents-label']}>
                <FormattedMessage id={'admin.team.table.title'} />
              </label>
              {players && (
                <PlayersTable
                  disabled={disabled}
                  tableRows={TableRows}
                  players={players}
                  onFetchMore={(page: IPageFilter) => {
                    onGetPlayers({ competitionID: data.id, page });
                  }}
                  onPlayerEdit={(pl: IPlayer) => {
                    //this.setState({ showModal: true, editPlayer: pl });
                  }}
                  onPlayerDelete={(pl: IPlayer) => {
                    onDeletePlayer({
                      competitionID: data.id,
                      playerID: pl.id
                    });
                  }}
                />
              )}
            </div>
          </Section>
        )}

        <div className={styles['AdminNewCompetition--row']}>
          <div
            className={[
              styles['AdminNewCompetition--column'],
              'u-fullWidth'
            ].join(' ')}>
            <div
              className={[
                styles.InputTemplate,
                styles['InputTemplate--end']
              ].join(' ')}>
              <Button
                styles={{ margin: 'auto 0 0' }}
                type={'primary'}
                clicked={() => {
                  this.props.goToNextTab();
                }}>
                <FormattedMessage id={'general.next'} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  players: state.playerState.players,
  refetchPlayers: state.playerState.refetchPlayers,
  teams: state.teamState.teams
});

const mapDispatchToProps = (dispatch: any) => ({
  onGetPlayers: (data?: any) => dispatch(actions.getPlayers(data)),
  onAppendPlayers: (data?: any) => dispatch(actions.appendPlayers(data)),
  //onUpdatePlayer: (data?: any) => dispatch(actions.updatePlayer(data)),
  onDeletePlayer: (data?: any) => dispatch(actions.deletePlayer(data)),
  onGetTeams: (data?: any) => dispatch(actions.getTeams(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCompetitionCrudTeamStudents);
