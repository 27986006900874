import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions';

import { ILeaderBoard } from '@vas/competition-service-share';
import Loader from '../components/Loader';
import Error from '../components/Error';
import { leaderboardID } from '../store/reducers/leaderboard';

const LEADERBOARD_INTERVAL = 10000;

interface OwnProps {
  competitionID: string
  leaderboardID: string
  children: any
}

interface StateProps {
  leaderboard: ILeaderBoard
  error?: any
  loading?: boolean
}

interface DispatchProps {
  getLeaderboard: Function
}

type Props = OwnProps & StateProps & DispatchProps

class LeaderboardController extends Component<Props>{

  private interval : any

  constructor(props: Props){
    super(props);
    this.interval = null;
  }

  getLeaderboard(){
    const { competitionID, leaderboardID } = this.props;
    if( competitionID &&  leaderboardID){
      this.props.getLeaderboard(competitionID, leaderboardID)
    }
  }

  startLeaderboardPollQuery(){
    clearInterval( this.interval );
    this.getLeaderboard();
    this.interval = setInterval(() => (
      this.getLeaderboard()
    ), LEADERBOARD_INTERVAL)
  }
  
  componentDidMount() {
    this.startLeaderboardPollQuery();
  }

  componentDidUpdate(prevProps: Props) {
    if(this.props.competitionID != prevProps.competitionID){
      this.startLeaderboardPollQuery();
    }
  }

  componentWillUnmount(){
    clearInterval( this.interval );
  }
  
  render(){

    if (this.props.error) {
      return (<Error />)
    }

    if (this.props.loading && !this.props.leaderboard) {
      return <Loader />
    }

    if (!this.props.loading && !this.props.leaderboard) {
      return null
    }

    return (this.props.children({ leaderboard: this.props.leaderboard }))

  }
}
const mapStateToProps = (state: any, props: OwnProps) => ({
  leaderboard: state.leaderboardState.leaderboard[leaderboardID({ competitionID : props.competitionID, id : props.leaderboardID })],
  error: state.leaderboardState.error,
  loading: state.leaderboardState.isFetching
});

const mapDispatchToProps = (dispatch: any, props: OwnProps) => ({
  getLeaderboard: ( competitionID: string, leaderboardID: string ) => {
    dispatch(actions.getLeaderboard({ competitionID, leaderboardID }))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaderboardController);