import React, { Component, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { formatRoute } from 'react-router-named-routes';
import { connect } from 'react-redux';
import orderBy from 'lodash/orderBy'
import * as actions from '../../store/actions';

import classes from './style.module.scss';

import Separator from '../Separator';
import Title from '../Title';
import CompetitionCard from '../CompetitionCard';
import SwitchFilter from '../SwitchFilter';
import Loader from '../Loader';

// Stone images
import stoneBlueImageUrl from '../../assets/img/stone-blue.png';
import stoneGreenImageUrl from '../../assets/img/stone-green.png';
import stoneOrangeImageUrl from '../../assets/img/stone-orange.png';
import stoneVioletImageUrl from '../../assets/img/stone-violet.png';
import stoneOpacityImageUrl from '../../assets/img/stone-opacity.png';
import {
  IPage,
  IPageFilter,
  ICompetition
} from '@vas/competition-service-share';
import { IOption } from '../../shared/interfaces';
import { LEADERBOARD } from '../../shared/routes';
import { AuthGate } from '../Auth';

interface IProps {
  competitions: IPage<ICompetition>;
  loading: boolean;
  onGetCompetitions(data?: any): IPage<ICompetition>;
  onGetCompetitionsByUser(data?: any): IPage<ICompetition>;
  error: any;
}

interface IState {
  itemsForScroll: number;
  loading: boolean;
  noMore: boolean;
  feedbackId: string;
  filterOptions: Array<IOption>;
  type: string;
  cursor: string;
  limit: number;
  error: any;
  user?: any;
}

const LIMIT = 100;

class Competitions extends Component<IProps, IState> {
  _isMounted = false;
  currentScrollTimeout: any = null;
  private competitions = createRef<HTMLDivElement>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      feedbackId: 'competitions.fetch',
      itemsForScroll: 9,
      loading: false,
      noMore: false,
      type: 'all',
      cursor: '0',
      limit: LIMIT,
      error: null,
      filterOptions: [
        {
          value: 'all',
          label: <FormattedMessage id={'label.all'} />,
          selected: true
        },
        {
          value: 'joined',
          label: <FormattedMessage id={'label.joined'} />,
          selected: false
        }
      ]
    };

    this.handleFilterClicked = this.handleFilterClicked.bind(this);
  }

  componentDidMount(): void {
    this._isMounted = true;
    const { cursor, limit } = this.state;

    //window.document.addEventListener('scroll', this.onScroll, false);

    this.getCompetitions({
      cursor,
      limit
    });
  }

  componentWillUnmount() {
    this._isMounted = false;

    //window.document.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll = () => {
    if (
      window.document.body.scrollTop + window.document.body.clientHeight >=
        window.document.body.scrollHeight - 100 &&
      !this.state.noMore &&
      this._isMounted &&
      !this.state.error
    ) {
      if (this.currentScrollTimeout) {
        clearTimeout(this.currentScrollTimeout || 0);
      }
      this.currentScrollTimeout = setTimeout(() => {
        this.loadMoreCompetitions();
      }, 200);
    }
  };

  loadMoreCompetitions() {
    if (this.state.loading) {
      return;
    }

    const { competitions } = this.props;

    if (this._isMounted) {
      this.getCompetitions({
        cursor: competitions.cursor,
        limit: LIMIT
      });
    }
  }

  // Read/Fetch
  getCompetitions = (page: IPageFilter): void => {
    const { type } = this.state;
    if (type === 'joined' && this.state.user) {
      this.props.onGetCompetitionsByUser({ userID: this.state.user.id, page });
    } else {
      this.props.onGetCompetitions({ page });
    }
  };

  handleFilterClicked = (user: any, value: string): void => {
    let filterOptions = [...this.state.filterOptions];

    filterOptions.map(option => (option.selected = option.value === value));
    this.setState(
      { filterOptions, type: value, cursor: '0', limit: LIMIT, user },
      () =>
        this.getCompetitions({
          cursor: '0',
          limit: LIMIT
        })
    );
  };

  componentWillReceiveProps(
    nextProps: Readonly<IProps>,
    nextContext: any
  ): void {
    const { competitions, error } = this.props;
    if (
      nextProps.competitions &&
      competitions &&
      competitions.results !== nextProps.competitions.results &&
      nextProps.competitions.results &&
      this._isMounted
    ) {
    }

    if (error !== nextProps.error) {
      if (nextProps.error) {
        console.error(
          'An error has occurred. Please try later.',
          nextProps.error.message
        );
        // @todo: manage errors
        this.setState({
          error: <FormattedMessage id={'error.generic'} />
        });
      }
    }
  }

  render() {
    const { loading }: IProps = this.props;
    const { feedbackId, filterOptions, noMore, error }: IState = this.state;

    const competitionsTpl = orderBy(this.props.competitions.results, ['startDate'],['desc'])
      .map((competition: ICompetition, index: number) => {
        const startDate = new Date(competition.startDate);
        const endDate = new Date(competition.endDate);
        const duration = `${moment(startDate).format('DD.MM.YYYY')} - ${moment(
          endDate
        ).format('DD.MM.YYYY')}`;
        return (
          <AuthGate key={index} scope={`competitions.${competition.id}`}>
            {({ isAuthorized }) => (
              <CompetitionCard
                id={competition.id}
                title={competition.title}
                duration={duration}
                teacher={competition.meta.teacher}
                project={competition.meta.project}
                players={competition.players ? competition.players.length : 0}
                submissions={competition.leaderboards.partial.updateCounts}
                kind={competition.meta.kind}
                to={`/competitions/${competition.id}`}
                backgroundImageUrl={competition.imageURL}
                disabled={!isAuthorized}
                leaderboardLink={formatRoute(LEADERBOARD, {
                  competitionID: competition.id,
                  anchor: 'leaderboard'
                })}
              />
            )}
          </AuthGate>
        );
      }
    );

    const competitionFeedbackTpl = (loading || this.state.loading) && (
      <div className={classes['Competitions-feedback']}>
        <Loader styles={{ margin: '0 20px 0 0' }} />
        <FormattedMessage id={feedbackId} />
      </div>
    );

    return (
      <div className={classes.Competitions} ref={this.competitions}>
        <img
          className={[
            classes['Competitions-image'],
            classes['Competitions-image--orange']
          ].join(' ')}
          src={stoneOrangeImageUrl}
          alt="stone"
        />
        <img
          className={[
            classes['Competitions-image'],
            classes['Competitions-image--opacity']
          ].join(' ')}
          src={stoneOpacityImageUrl}
          alt="stone"
        />
        <img
          className={[
            classes['Competitions-image'],
            classes['Competitions-image--blue']
          ].join(' ')}
          src={stoneBlueImageUrl}
          alt="stone"
        />
        <img
          className={[
            classes['Competitions-image'],
            classes['Competitions-image--green']
          ].join(' ')}
          src={stoneGreenImageUrl}
          alt="stone"
        />
        <img
          className={[
            classes['Competitions-image'],
            classes['Competitions-image--violet']
          ].join(' ')}
          src={stoneVioletImageUrl}
          alt="stone"
        />
        <div className={classes['Competitions-title']}>
          <Separator styles={{ maxWidth: '60px', margin: '30px 0' }} />
          <Title large>
            <FormattedMessage id="app.title" />
          </Title>
        </div>
        <div>
          <AuthGate>
            {({ user, isAuthenticated }) =>
              isAuthenticated && (
                <SwitchFilter
                  clicked={value => this.handleFilterClicked(user, value)}
                  options={filterOptions}
                  label={<FormattedMessage id={'label.filterCompetitions'} />}
                />
              )
            }
          </AuthGate>
        </div>
        {error ? (
          <div
            className={[
              classes['Competitions-content'],
              classes['Competitions-content--error']
            ].join(' ')}>
            <span className={classes['Competitions-error']}>{error}</span>
          </div>
        ) : (
          <div className={classes['Competitions-content']}>
            <div className={classes['Competitions-list']}>
              {competitionsTpl}
            </div>
            {(feedbackId === 'competitions.fetch' || this.state.loading) &&
              competitionFeedbackTpl}
          </div>
        )}

        {!noMore && <div className={classes['Competitions-background']} />}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  competitions: state.competitionState.competitions,
  error: state.competitionState.error,
  loading: state.competitionState.isFetching
});

const mapDispatchToProps = (dispatch: any) => ({
  onGetCompetitions: (data?: any) => dispatch(actions.getCompetitions(data)),
  onGetCompetitionsByUser: (data?: any) =>
    dispatch(actions.getCompetitionsByUser(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Competitions);
