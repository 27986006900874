import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import styles from './style.module.scss';
import RegressionSvg from '../../../../assets/img/regression.svg';

interface Props {}
interface State {}

const descriptions = [
  {
    title: 's',
    descriptionID: 'admin.upload.regression.image.description.S'
  },
  {
    title: 'w',
    descriptionID: 'admin.upload.regression.image.description.W'
  },
  {
    title: 'y',
    descriptionID: 'admin.upload.regression.image.description.Y'
  },
  {
    title: 'X',
    descriptionID: 'admin.upload.regression.image.description.X'
  }
];

class UploadDataset extends Component<Props, State> {
  render() {
    return (
      <div className={styles.UploadDataset}>
        <span className={`AdminSectionTitle ${styles['UploadDataset-title']}`}>
          <FormattedMessage id={`admin.upload.dataset`} />
        </span>
				<div className={styles["UploadDataset-subtitle"]}>
					<FormattedMessage id={`admin.upload.dataset.description`} />
				</div>
        <div className={styles[`RegressionUploadDataset-imageContainer`]}>
          <img
            src={RegressionSvg}
            className={styles[`RegressionUploadDataset-image`]}
          />
          <div className={styles[`RegressionUploadDataset-imageDescription`]}>
            {descriptions.map((desc, index) => (
              <div
                key={index}
                className={
                  styles[`RegressionUploadDataset-imageDescriptionItem`]
                }>
                <div
                  className={`AdminSectionTitle ${
                    styles[`AdminSectionTitle`]
                  }`}>
                  {desc.title}
                </div>
                <div className={`AdminSectionText`}>
                  <FormattedHTMLMessage id={desc.descriptionID} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={{maxWidth: '800px', margin: '100px 0'}} className={`AdminSectionText ${styles['UploadDataset-text']}`}>
          <FormattedHTMLMessage id="admin.upload.dataset.notes" />
        </div>
      </div>
    );
  }
}

export default UploadDataset;
