import papaparse from 'papaparse';

const PART_SIZE = 100000;

export default (data: any, filename: string) => {
  let partID = 0;
  let partCount = Math.ceil(data.length / PART_SIZE);

  do {
    const part = data.splice(0, PART_SIZE);
    partID++;

    // const computedFilename = `${filename}-${partID}_${partCount}-${
    //   part.length
    // }.csv`;

    const computedFilename = `${filename}`

    const blob = new Blob([
      papaparse.unparse(part, {
        // worker: true,
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        header: false,
        newline: '\r\n'
      })
    ]);

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, computedFilename);
    } else {
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      //{ type: 'text/plain' }
      a.download = computedFilename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  } while (data.length);
};
