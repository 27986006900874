import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Cover from '../Cover';
import CompetitionImage from '../CompetitionImage';
import Title from '../Title';
import styles from './style.module.scss';
import { IDSCompetitionView } from '../../shared/interfaces';
import LeaderboardController from '../../controllers/LeaderboardController';
import CompetitionInfo from '../CompetitionInfo';
import ShortLeaderboard from '../ShortLeaderboard';
import { ILeaderBoard } from '@vas/competition-service-share';
import Button from '../Button';

interface ICompetitionCoverProps {
  competition: IDSCompetitionView
}

const CompetitionCover = ( props : ICompetitionCoverProps ) => (
  <Cover backgroundImageUrl={props.competition.imageURL}>
    <div className={styles['Competition-cover']}>
      <CompetitionImage src={props.competition.imageURL} />  
      <div className={styles['Competition-card']}>
        <NavLink to="/competitions">
          <Button
            outline
            styles={{
              margin: '0 0 10px'
            }}
            small
/*             mouseOut={this.onBackButtonMouseOut}
            mouseOver={this.onBackButtonMouseOver} */
            >
{/*             {back ? (
              <img src={backDarkIconUrl} alt={'back'} />
            ) : (
                <img src={backIconUrl} alt={'back'} />
              )} */}
            <FormattedMessage id={'button.backToCompetitionsList'} />
          </Button>
        </NavLink>
        <Title>{props.competition.title}</Title>
        <CompetitionInfo competition={props.competition} />
      </div>
      <LeaderboardController
        competitionID={props.competition.id}
        leaderboardID={props.competition.actualLeaderboardID} >
        {(data: { leaderboard: ILeaderBoard }) => (
          <ShortLeaderboard leaderboard={data.leaderboard} />
        )}
      </LeaderboardController>
    </div>
  </Cover>
)

export default CompetitionCover;



  //TODO capire se vogliamo avere questa logica

  /* props.competition.isCompleted 
    ? (
      <Route path="/leaderboard/:leaderboardID">
        {({ match }) => (
          <LeaderboardController
            competitionID={props.competition.id}
            leaderboardID={match.params.leaderboardID} />
        )}
      </Route>
    ) : ( */
  /* <LeaderboardController
            competitionID={props.competition.id}
            leaderboardID={props.competition.actualLeaderboardID} /> */
          /* ) */
