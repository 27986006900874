import React from 'react';
import classes from './style.module.scss';

interface ILoader {
	styles?: object;
}

const loader = (props: ILoader) => {
	return (
		<div style={props.styles} className={classes.Loader}>
			<div className={classes['Loader-first']} />
			<div className={classes['Loader-second']} />
		</div>
	);
};

export default loader;
