import React, { Component, Fragment } from 'react';
import { FormattedMessage, FormattedDate, FormattedHTMLMessage } from 'react-intl';
import Table from '../Table';
import CompetitionLeaderboardButtons from './CompetitionLeaderboardButtons';
import {
  ITeam,
  ILeaderBoard,
  ILeaderBoardPosition
} from '@vas/competition-service-share';
import moment from 'moment';
import { isNumber } from 'util';
import isNil from 'lodash/isNil';

interface Props {
  leaderboard?: ILeaderBoard;
  showTotals?: boolean;
}
interface State {
  active: number;
}

class CompetitionLeaderboard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0
    };
  }

  private getTotalsData() {
    const res = [];
    const { positions } = this.props.leaderboard || { positions: [] };

    if (positions && positions.length) {
      (positions as ILeaderBoardPosition[]).map(
        (position: ILeaderBoardPosition) =>
          res.push([
            { content: position.rank },
            { content: position.team.id },
            {
              content: ((!isNil(position.score)) && position.score.toFixed) ? position.score.toFixed(2) : null
            },
            { content: position.submissionCount },
            {
              content: moment(position.lastSubmissionDate).format(
                'DD.MM.YYYY - HH:mm'
              )
            }
          ])
      );
    } else {
      res.push([
        {
          content: <FormattedMessage id={'generic.no.data'} />,
          colSpan: 5
        }
      ]);
    }

    return res;
  }

  private getWaitingsData() {
    const res = [];
    const { waitingTeams } = this.props.leaderboard || { waitingTeams: [] };
    if (waitingTeams && waitingTeams.length) {
      (waitingTeams as ITeam[]).map((team: ITeam) =>
        res.push([{ content: team.id }])
      );
    } else {
      res.push([
        {
          content: <FormattedMessage id={'generic.no.data'} />,
          colSpan: 1
        }
      ]);
    }

    return res;
  }
  render() {
    const { active } = this.state;
    const { showTotals } = this.props;
    return (
      <Fragment>
        <p>
          <FormattedHTMLMessage id="competition.leaderboard.description" />
        </p>
        <CompetitionLeaderboardButtons
          active={active}
          setActive={active => this.setState({ active })}
          showTotals={showTotals}
        />
        {active == 0 ? (
          <Table
            pagination={true}
            paginationRows={8}
            headers={[
              {
                content: (
                  <FormattedMessage id="competitionLeaderBoard.table.position.text" />
                )
              },
              {
                content: (
                  <FormattedMessage id="competitionLeaderBoard.table.name.text" />
                )
              },
              {
                content: showTotals ? (
                  <FormattedMessage id="competitionLeaderBoard.table.score.text" />
                ) : (
                  <FormattedMessage id="competitionLeaderBoard.table.partialScore.text" />
                )
              },
              {
                content: (
                  <FormattedMessage id="competitionLeaderBoard.table.submission.text" />
                )
              },
              {
                content: (
                  <FormattedMessage id="competitionLeaderBoard.table.lastUploads.text" />
                )
              }
            ]}
            data={this.getTotalsData()}
          />
        ) : (
          <Table
            pagination={true}
            paginationRows={8}
            headers={[
              {
                content: (
                  <FormattedMessage id="competitionLeaderBoard.otherTable.name.text" />
                )
              }
            ]}
            data={this.getWaitingsData()}
          />
        )}
      </Fragment>
    );
  }
}

export default CompetitionLeaderboard;
