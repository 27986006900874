import { updateObject } from '../../shared/utilities';
import { IDSCompetitionView } from '../../shared/interfaces';
import {
  IPage,
  ICompetition,
  CompetitionStatus
} from '@vas/competition-service-share';
import uniqBy from 'lodash/uniqBy';
import { IDSCompetition } from '@vas/dsc-share';
import { toast } from 'react-toastify';
import { FormattedHTMLMessage } from 'react-intl';
import React from 'react';

interface IAction<D = any> {
  type: string;
  competitions: IPage<ICompetition>;
  competition: ICompetition;
  error: string;
  removed: boolean;
  response?: any;
  data: D;
}

const INITIAL_STATE = {
  competitions: { results: [], cursor: '0', totalCount: 0 },
  lastCreatedCompetition: null,
  competition: {},
  status: null,
  isFetching: false,
  isByOwnerFetching: false,
  isStoring: false,
  didInvalidate: false,
  error: null,
  removed: null
};

function competitionView(competition: IDSCompetition): IDSCompetitionView {
  return Object.assign({}, competition, {
    actualLeaderboardID:
      competition.status === CompetitionStatus.COMPLETED ? 'total' : 'partial',
    isCompleted: competition.status === CompetitionStatus.COMPLETED,
    isRunning: competition.status === CompetitionStatus.RUNNING
  });
}

// CREATE
const createCompetitionStart = (
  state = INITIAL_STATE,
  action: IAction
): IAction => {
  return updateObject(state, {
    lastCreatedCompetition: null,
    isStoring: true,
    error: null,
    didInvalidate: false,
    isStored: null
  });
};

const createCompetitionSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    competition: {
      ...state.competition,
      [action.response.id]: action.response
    },
    lastCreatedCompetition: action.response,
    isStoring: false
  });
};

const createCompetitionFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isStoring: false,
    didInvalidate: true
  });
};

// FETCH / READ
const getCompetitionStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isFetching: true,
    error: null,
    didInvalidate: false
  });
};

const getCompetitionSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    competition: {
      ...state.competition,
      [action.response.id]: competitionView(action.response)
    },
    isFetching: false
  });
};

const getCompetitionFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

// FETCH (ALL) COMPETITIONS
const getCompetitionsStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isFetching: true
  });
};

const getCompetitionsSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    competitions: {
      cursor: action.response.cursor,
      results: uniqBy(
        state.competitions.results.concat(action.response.results),
        'id'
      ),
      totalCount: action.response.totalCount
    },
    isFetching: false
  });
};

const getCompetitionsFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

// FETCH COMPETITION FOR JOINED PLAYER (SWITCH JOINED ON PLAYER SIDE)
const getCompetitionsByUserStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    competitions:
      action.data.page.cursor == '0'
        ? { cursor: '0', results: [], totalCount: 0 }
        : state.competitions,
    isFetching: true,
    error: null,
    didInvalidate: false
  });
};

const getCompetitionsByUserSuccess = (
  state = INITIAL_STATE,
  action: IAction
) => {
  return updateObject(state, {
    competitions: {
      cursor: action.response.cursor,
      results: uniqBy(
        state.competitions.results.concat(action.response.results),
        'id'
      ),
      totalCount: action.response.totalCount
    },
    isFetching: false
  });
};

const getCompetitionsByUserFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

// FETCH COMPETITION BY OWNER
const getCompetitionsByOwnerStart = (
  state = INITIAL_STATE,
  action: IAction
) => {
  return updateObject(state, {
    error: null,
    didInvalidate: false,
    deletedCompetition: false,
    isByOwnerFetching: true
  });
};

const getCompetitionsByOwnerSuccess = (
  state = INITIAL_STATE,
  action: IAction
) => {
  return updateObject(state, {
    competitions: action.response,
    isByOwnerFetching: false
  });
};

const getCompetitionsByOwnerFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    didInvalidate: true,
    isByOwnerFetching: false
  });
};

// UPDATE COMPETITION
const updateCompetitionStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isStoring: true,
    error: null,
    didInvalidate: false,
    isStored: null
  });
};

const updateCompetitionSuccess = (state = INITIAL_STATE, action: IAction) => {
  toast.success(() => <FormattedHTMLMessage id="competition.updated" />, {
    toastId: 'competition.updated'
  });
  return updateObject(state, {
    competition: {
      ...state.competition,
      [action.response.id]: action.response
    },
    isStoring: false
  });
};

const updateCompetitionFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isStoring: false,
    didInvalidate: true
  });
};

// PUBLISH COMPETITION
const publishCompetitionStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isStoring: true,
    error: null,
    didInvalidate: false,
    isStored: null
  });
};

const publishCompetitionSuccess = (state = INITIAL_STATE, action: IAction) => {
  toast.success(() => <FormattedHTMLMessage id="competition.published" />, {
    toastId: 'competition.published'
  });
  return updateObject(state, {
    competition: {
      ...state.competition,
      [action.response.id]: action.response
    },
    isStoring: false
  });
};

const publishCompetitionFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isStoring: false,
    didInvalidate: true
  });
};

// RESET COMPETITION
const resetCompetitionStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isStoring: true,
    error: null,
    didInvalidate: false,
    isStored: null
  });
};

const resetCompetitionSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    competition: {
      ...state.competition,
      [action.response.id]: action.response
    },
    isStoring: false
  });
};

const resetCompetitionFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isStoring: false,
    didInvalidate: true
  });
};

// DELETE COMPETITION
const deleteCompetitionStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isStoring: true,
    error: null,
    deletedCompetition: false
  });
};

const deleteCompetitionSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, { deletedCompetition: true, isStoring: false });
};

const deleteCompetitionFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    deletedCompetition: true,
    isStoring: false
  });
};

const sendWorkflowStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isStoring: true
  });
};

const sendWorkflowSuccess = (state: any = INITIAL_STATE, action: IAction) => {
  toast.success(
    () => (
      <FormattedHTMLMessage
        id="competition.workflow.uploaded"
        values={{
          1: state.competition[state.workflow.competitionID].title
        }}
      />
    ),
    {
      toastId: 'submission.ready'
    }
  );
  return updateObject(state, { isStoring: false });
};

const sendWorkflowFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isStoring: false
  });
};

/// GET LAST WORKFLOW

const getLastWorkflowStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isFetching: true
  });
};

const getLastWorkflowSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    workflow: action.response,
    isFetching: false
  });
};

const getLastWorkflowFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

/// GET WORKFLOWS

const getWorkflowsStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isFetching: true
  });
};

const getWorkflowsSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    workflows: action.response,
    isFetching: false
  });
};

const getWorkflowsFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

const loadCompetitionDatasetStart = (
  state = INITIAL_STATE,
  action: IAction
) => {
  return updateObject(state, {
    isStoring: true
  });
};

const loadCompetitionDatasetSuccess = (
  state = INITIAL_STATE,
  action: IAction
) => {
  const res = updateObject(state, {
    competition: {
      ...state.competition,
      [action.response.id]: action.response
    },
    isStoring: false
  });
  if (action.response.meta.loadingDatasetError) {
    toast.error(
      () => <FormattedHTMLMessage id="competition.datasets.error.toast" />,
      {
        toastId: 'competition.upload.error'
      }
    );
  } else {
    toast.success(
      () => <FormattedHTMLMessage id="competition.dataset.uploaded" />,
      {
        toastId: 'competition.dataset.uploaded'
      }
    );
  }
  return res;
};

const loadCompetitionDatasetFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isStoring: false
  });
};

const competitionReducer = (state = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    // CREATE
    case `ADD_COMPETITION_START`:
      return createCompetitionStart(state, action);
    case `ADD_COMPETITION_SUCCESS`:
      return createCompetitionSuccess(state, action);
    case `ADD_COMPETITION_FAIL`:
      return createCompetitionFail(state, action);
    // READ/FETCHING (ALL)
    case `GET_COMPETITIONS_START`:
      return getCompetitionsStart(state, action);
    case `GET_COMPETITIONS_SUCCESS`:
      return getCompetitionsSuccess(state, action);
    case `GET_COMPETITIONS_FAIL`:
      return getCompetitionsFail(state, action);
    // READ/GETTING (JOINED)
    case `GET_USER_COMPETITIONS_START`:
      return getCompetitionsByUserStart(state, action);
    case `GET_USER_COMPETITIONS_SUCCESS`:
      return getCompetitionsByUserSuccess(state, action);
    case `GET_USER_COMPETITIONS_FAIL`:
      return getCompetitionsByUserFail(state, action);
    // READ/GETTING BY OWNER
    case `GET_OWNER_COMPETITIONS_START`:
      return getCompetitionsByOwnerStart(state, action);
    case `GET_OWNER_COMPETITIONS_SUCCESS`:
      return getCompetitionsByOwnerSuccess(state, action);
    case `GET_OWNER_COMPETITIONS_FAIL`:
      return getCompetitionsByOwnerFail(state, action);
    // READ/GETTING
    case `GET_COMPETITION_START`:
      return getCompetitionStart(state, action);
    case `GET_COMPETITION_SUCCESS`:
      return getCompetitionSuccess(state, action);
    case `GET_COMPETITION_FAIL`:
      return getCompetitionFail(state, action);
    // UPLOAD DATASET
    case `LOAD_COMPETITION_DATASET_START`:
      return loadCompetitionDatasetStart(state, action);
    case `LOAD_COMPETITION_DATASET_SUCCESS`:
      return loadCompetitionDatasetSuccess(state, action);
    case `LOAD_COMPETITION_DATASET_FAIL`:
      return loadCompetitionDatasetFail(state, action);
    // READ/GETTING WORKFLOWS
    case `SEND_WORKFLOW_START`:
      return sendWorkflowStart(state, action);
    case `SEND_WORKFLOW_SUCCESS`:
      return sendWorkflowSuccess(state, action);
    case `SEND_WORKFLOW_FAIL`:
      return sendWorkflowFail(state, action);
    case `GET_LAST_WORKFLOW_START`:
      return getLastWorkflowStart(state, action);
    case `GET_LAST_WORKFLOW_SUCCESS`:
      return getLastWorkflowSuccess(state, action);
    case `GET_LAST_WORKFLOW_FAIL`:
      return getLastWorkflowFail(state, action);
    case `GET_WORKFLOWS_START`:
      return getWorkflowsStart(state, action);
    case `GET_WORKFLOWS_SUCCESS`:
      return getWorkflowsSuccess(state, action);
    case `GET_WORKFLOWS_FAIL`:
      return getWorkflowsFail(state, action);
    // UPDATE
    case `UPDATE_COMPETITION_START`:
      return updateCompetitionStart(state, action);
    case `UPDATE_COMPETITION_SUCCESS`:
      return updateCompetitionSuccess(state, action);
    case `UPDATE_COMPETITION_FAIL`:
      return updateCompetitionFail(state, action);
    // PUBLISH
    case `PUBLISH_COMPETITION_START`:
      return publishCompetitionStart(state, action);
    case `PUBLISH_COMPETITION_SUCCESS`:
      return publishCompetitionSuccess(state, action);
    case `PUBLISH_COMPETITION_FAIL`:
      return publishCompetitionFail(state, action);
    // RESET
    case `RESET_COMPETITION_START`:
      return resetCompetitionStart(state, action);
    case `RESET_COMPETITION_SUCCESS`:
      return resetCompetitionSuccess(state, action);
    case `RESET_COMPETITION_FAIL`:
      return resetCompetitionFail(state, action);
    // DELETE
    case `DELETE_COMPETITION_START`:
      return deleteCompetitionStart(state, action);
    case `DELETE_COMPETITION_SUCCESS`:
      return deleteCompetitionSuccess(state, action);
    case `DELETE_COMPETITION_FAIL`:
      return deleteCompetitionFail(state, action);

    default:
      return state;
  }
};

export default competitionReducer;
