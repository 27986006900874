"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DSCompetitionKind;

(function (DSCompetitionKind) {
  DSCompetitionKind["REGRESSION"] = "REGRESSION";
  DSCompetitionKind["CLASSIFICATION"] = "CLASSIFICATION";
})(DSCompetitionKind = exports.DSCompetitionKind || (exports.DSCompetitionKind = {}));

var SubmissionStatus;

(function (SubmissionStatus) {
  SubmissionStatus["COMPUTING"] = "COMPUTING";
  SubmissionStatus["READY"] = "READY";
  SubmissionStatus["ERROR"] = "ERROR";
})(SubmissionStatus = exports.SubmissionStatus || (exports.SubmissionStatus = {}));

var DSCRegressionScoreFnName;

(function (DSCRegressionScoreFnName) {
  DSCRegressionScoreFnName["DIFF_SUM"] = "DIFF-SUM";
  DSCRegressionScoreFnName["DIFF_AVG"] = "DIFF-AVG";
  DSCRegressionScoreFnName["STDDEV_SUM"] = "STDDEV-SUM";
  DSCRegressionScoreFnName["STDDEV_AVG"] = "STDDEV-AVG";
})(DSCRegressionScoreFnName = exports.DSCRegressionScoreFnName || (exports.DSCRegressionScoreFnName = {}));

var DSCClassificationScoreFnName;

(function (DSCClassificationScoreFnName) {
  DSCClassificationScoreFnName["SUM"] = "SUM";
  DSCClassificationScoreFnName["AVG"] = "AVG";
})(DSCClassificationScoreFnName = exports.DSCClassificationScoreFnName || (exports.DSCClassificationScoreFnName = {}));