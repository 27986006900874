import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { ICompetition } from '@vas/competition-service-share';
import set from 'lodash/set';
import Paragraph from '../Paragraph';
import { Tabs, ITabButton } from '../Tabs';
import AdminNewCompetitionDetails from './AdminNewCompetitionDetails';
import AdminNewCompetitionDatasetUpload from './AdminNewCompetitionDatasetUpload';
import AdminNewCompetitionTeamStudents from './AdminNewCompetitionTeamStudents';
import AdminNewCompetitionChoose from './AdminNewCompetitionChoose';
import { NavLink } from 'react-router-dom';
import * as routes from '../../shared/routes';
import { formatRoute } from 'react-router-named-routes';
import Loader from '../Loader';
import cloneDeep from 'lodash/cloneDeep';

const tabButtons: ITabButton[] = [
  {
    titleId: 'admin.competition.tab.1',
    relativePath: 'choose',
    fullPath: routes.ADMIN_COMPETITION_CHOOSE,
    component: AdminNewCompetitionChoose,
    active: true
  },
  {
    titleId: 'admin.competition.tab.2',
    relativePath: 'dataset',
    fullPath: routes.ADMIN_COMPETITION_DATASET,
    component: AdminNewCompetitionDatasetUpload
  },
  {
    titleId: 'admin.competition.tab.3',
    relativePath: 'teams',
    fullPath: routes.ADMIN_COMPETITION_TEAMS,
    component: AdminNewCompetitionTeamStudents
  },
  {
    titleId: 'admin.competition.tab.4',
    relativePath: 'details',
    fullPath: routes.ADMIN_COMPETITION_DETAILS,
    component: AdminNewCompetitionDetails
  }
];

interface IProps {
  competition: ICompetition;
  loading: boolean;
  storing: boolean;
  onGetCompetition(data?: any): ICompetition;
  onUpdateCompetition(data?: any): ICompetition;
  onPublishCompetition(data?: any): ICompetition;
  onLoadCompetitionDataset(data?: any): ICompetition;
  error: any;
  removed: boolean;
  location?: any;
  history?: any;
  match?: any;
}

interface IState {
  buttons: ITabButton[];
  competition: ICompetition;
}

class AdminNewCompetition extends Component<IProps, IState> {
  currentPath = '';
  constructor(props: IProps) {
    super(props);
    this.state = {
      buttons: tabButtons,
      competition: this.props.competition
    };
  }

  componentDidMount() {
    const { onGetCompetition, match } = this.props;
    const { competitionID } = match.params;
    onGetCompetition({ competitionID });
    this.handlePath();
  }

  handlePath() {
    let splittedPath = this.props.location.pathname.split('/');
    this.currentPath = splittedPath[splittedPath.length - 1];
    const { buttons } = this.state;

    this.onTabButtonClick(
      buttons.filter(b => b.relativePath === this.currentPath)[0] || buttons[0]
    );
  }

  onTabButtonClick = (o: ITabButton) => {
    let { buttons } = this.state;
    const { match } = this.props;
    let founded = false;

    for (let i in buttons) {
      if (buttons[i].titleId == o.titleId) {
        buttons[i].active = true;
        this.currentPath = buttons[i].relativePath;
        this.props.history.push(
          formatRoute(buttons[i].fullPath, {
            competitionID: match.params.competitionID
          })
        );
        founded = true;
      } else {
        buttons[i].active = founded ? false : true;
      }
    }

    this.setState({ buttons });
  };

  getActiveTabComponent() {
    const b = this.state.buttons.filter(x => x.active === true);
    return b[b.length - 1].component;
  }

  goToNextTab() {
    let { buttons } = this.state;
    let currentIndex = 0;
    let o;

    for (o of buttons) {
      if (o.active) {
        currentIndex++;
      }
    }

    const { competition } = this.state;

    this.props.onUpdateCompetition({
      competitionID: competition.id,
      competition
    });

    this.onTabButtonClick(buttons[currentIndex]);
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    const competitionProp: any = nextProps.competition;
    if (nextProps.competition) {
      const competitionProp: any = { ...nextProps.competition };
      if (competitionProp.hasOwnProperty('_id')) {
        delete competitionProp._id;
      }
      this.setState({ competition: competitionProp });
    }
  }

  render() {
    const content = this.getActiveTabComponent();

    const { loading, match, history } = this.props;
    const { competition } = this.state;

    if (!competition && loading) return <Loader />;
    if (!competition && !loading) return null;

    return (
      <div className={styles.AdminNewCompetition}>
        <div className={styles['AdminNewCompetition-content']}>
          <NavLink
            to={routes.ADMIN}
            className={styles['AdminNewCompetition-backButton']}>
            <FormattedMessage id={'admin.competition.back'} />
          </NavLink>
          <Paragraph
            large
            title={<FormattedMessage id={`admin.competition.title`} />}
          />
          <Tabs
            step={true}
            buttons={this.state.buttons}
            onTabButtonClick={this.onTabButtonClick}
          />
          {React.createElement(content, {
            match,
            history,
            disabled: this.props.competition.published,
            data: this.state.competition,
            onLoadCompetitionDataset: this.props.onLoadCompetitionDataset,
            setDecision: (
              decision: string,
              value: any,
              callback?: Function
            ) => {
              const { competition } = this.state;
              let newCompetition: any = {
                ...competition
              };
              set(newCompetition, decision, value);
              this.setState(
                {
                  competition: newCompetition
                },
                () => {
                  callback && callback();
                }
              );
            },
            setDecisions: (decisions: any, callback?: Function) => {
              const { competition } = this.state;
              const newCompetition: any = decisions.reduce(
                (acc: any, ele: any) => {
                  set(acc, ele.id, ele.value);
                  return acc;
                },
                { ...competition }
              );

              this.setState(
                {
                  competition: newCompetition
                },
                () => {
                  callback && callback();
                }
              );
            },
            setUpdateCompetition: () => {
              const { onUpdateCompetition } = this.props;
              const { competition } = this.state;
              onUpdateCompetition({
                competitionID: competition.id,
                competition
              });
            },
            setPublishCompetition: () => {
              const { competition } = this.state;
              this.props.onPublishCompetition({
                competitionID: competition.id
              });
            },
            goToNextTab: () => {
              this.goToNextTab();
            }
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any, props: IProps) => ({
  competition:
    state.competitionState.competition[props.match.params.competitionID],
  error: state.competitionState.error,
  loading: state.competitionState.isFetching,
  storing: state.competitionState.isStoring,
  removed: state.competitionState.removed
});

const mapDispatchToProps = (dispatch: any) => ({
  onGetCompetition: (data?: any) => dispatch(actions.getCompetition(data)),
  onUpdateCompetition: (data: any) => dispatch(actions.updateCompetition(data)),
  onPublishCompetition: (data: any) =>
    dispatch(actions.publishCompetition(data)),
  onLoadCompetitionDataset: (data: any) =>
    dispatch(actions.loadCompetitionDataset(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNewCompetition);
