import React from 'react';
import classes from './style.module.scss';
import { FormattedHTMLMessage } from 'react-intl';

import Separator from '../Separator';
import Title from '../Title';

interface paragraphProps {
	title?: any;
	description?: any;
	descriptionId?: string;
	styles?: object;
	large?: boolean;
	hideSeparator?: boolean;
}

const paragraph = ({
	title,
	description,
	descriptionId,
	styles,
	large,
	hideSeparator
}: paragraphProps) => {
	const paragraphClass = [
		classes.Paragraph,
		large ? classes['Paragraph--large'] : ''
	].join(' ');

	return (
		<div className={paragraphClass} style={styles}>
			{!hideSeparator && (
				<div className={classes['Paragraph-separator']}>
					<Separator styles={{ margin: '0 0 30px' }} />
				</div>
			)}
			{title && <Title large={large}>{title}</Title>}
			{descriptionId && (
				<div className={classes['Paragraph-description']}>
					<FormattedHTMLMessage id={descriptionId} />
				</div>
			)}
			{!descriptionId && description && (
				<div
					className={classes['Paragraph-description']}
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			)}
		</div>
	);
};

export default paragraph;
