import React, { Component } from 'react';
import classes from './style.module.scss';
import range from 'lodash/range';
import leftArrow from '../../../assets/icons/arrow_l.svg';
import rightArrow from '../../../assets/icons/arrow_r.svg';

interface Props {
	pages: number;
	activePage: number;
	setActivePage(page: number): void;
}
interface State {}

class Pagination extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {};
	}
	render() {
		const { activePage, pages, setActivePage } = this.props;
		return (
			<div className={classes['Pagination']}>
				<div
					className={`${classes['Pagination-direction']} ${
						classes['Pagination-direction--left']
					}`}
					onClick={() => {
						activePage != 1 && setActivePage(activePage - 1);
					}}>
					<img src={leftArrow} />
				</div>
				<div className={classes['Pagination-pages']}>
					{range(pages).map(i => (
						<div
							key={i}
							className={`${classes['Pagination-page']} ${
								i + 1 === activePage ? classes['is-active'] : ''
							}`}
							onClick={() => {
								setActivePage(i + 1);
							}}>
							{i + 1}
						</div>
					))}
				</div>
				<div
					className={`${classes['Pagination-direction']} ${
						classes['Pagination-direction--right']
					}`}
					onClick={() => {
						activePage != pages && setActivePage(activePage + 1);
					}}>
					<img src={rightArrow} />
				</div>
			</div>
		);
	}
}

export default Pagination;
