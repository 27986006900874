import React, { Component } from 'react';
import styles from './style.module.scss';
import { BlockMath } from 'react-katex';
import Latex from '../../../Latex';
import { FormattedMessage } from 'react-intl';
import { DSCRegressionScoreFnName } from '@vas/dsc-share';
import Formulas from '../../../../shared/formulas';

interface Props {
  value: string | null;
  onChange: (id: DSCRegressionScoreFnName) => void;
  disabled: boolean;
}

class FormulaChoice extends Component<Props> {
  render() {
    const { value, onChange, disabled } = this.props;

    return (
      <div className={styles['FormulaChoice']}>
        <span className="AdminSectionTitle">
          <FormattedMessage id={`admin.choice.regression.formula`} />
        </span>
        <div className={styles['FormulaChoice-radio']}>
          {Formulas.Regression.map((item, index) => {
            return (
              <div
                className={`${value == item.id ? styles['active'] : ''} ${
                  styles['FormulaChoice-radioItem']
                }`}
                onClick={() => !disabled && onChange(item.id)}
                key={index}>
                <div className={styles['FormulaChoice-radioItemLabel']}>
                  {item.label}
                </div>
                <div className={styles['FormulaChoice-radioItemLatex']}>
                  <BlockMath math={item.math} />
                </div>
              </div>
            );
          })}
        </div>
        <ul className="AdminSectionText">
          <li>
            <FormattedMessage
              id="regression.formula.choice.label.1"
              values={{
                1: Latex('y_i'),
                2: Latex('i(i=1,...,n)')
              }}
            />
          </li>
          <li>
            <FormattedMessage
              id="regression.formula.choice.label.2"
              values={{
                1: Latex('\\hat{y}_i (i=1,...,n)'),
                2: Latex('y_i')
              }}
            />
          </li>
          <li>
            <FormattedMessage
              id="regression.formula.choice.label.3"
              values={{
                1: Latex('w_i'),
                2: Latex('y_i'),
                3: Latex('\\hat{y}_i (i=1,...,n)')
              }}
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default FormulaChoice;
