import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import styles from './style.module.scss';
import { BlockMath } from 'react-katex';
import range from 'lodash/range';
import Matrix from '../../../Matrix';
import Checkbox from '../../../Checkbox';
import NumberInput from '../../../InputTemplate/NumberInput';
import { ICompetition } from '@vas/competition-service-share';
import NumPad from 'react-numpad';
import {
  IClassificationCompetitionMeta,
  DSCClassificationScoreFnName
} from '@vas/dsc-share';
import Latex from '../../../Latex';
import formulas from '../../../../shared/formulas';

interface Props {
  data: ICompetition<IClassificationCompetitionMeta>;
  setDecision: Function;
  setDecisions: Function;
  disabled?: boolean;
  setUpdateCompetition: (lastStep?: boolean) => void;
}

class ClassificationFormulaChoice extends Component<Props> {
  render() {
    const {
      data,
      setDecision,
      setDecisions,
      setUpdateCompetition,
      disabled
    } = this.props;
    const myTheme = {
      header: {
        primaryColor: '#263238',
        secondaryColor: '#f9f9f9',
        highlightColor: '#e563bb',
        backgroundColor: '#2e418a'
      },
      body: {
        primaryColor: '#263238',
        secondaryColor: '#32a5f2',
        highlightColor: '#FFC107',
        backgroundColor: '#f9f9f9'
      },
      panel: {
        backgroundColor: '#CFD8DC'
      }
    };
    return (
      <div className={styles['ClassificationFormulaChoice']}>
        <div>
          <div className="AdminSectionTitle">
            <FormattedMessage id={`admin.choice.classification.formula`} />
          </div>
          <div
            className={`AdminSectionTitle ${
              styles['ClassificationFormulaChoice-categories']
            }`}>
            <FormattedHTMLMessage id={`admin.upload.categories`} />
          </div>
          {disabled ? (
            <NumberInput
              disabled={true}
              value={data.meta.categories ? data.meta.categories.length : 0}
              controlled={true}
              placeholderId={'Categories'}
            />
          ) : (
            <NumPad.Number
              theme={myTheme}
              disabled={disabled}
              value={data.meta.categories ? data.meta.categories.length : 0}
              negative={false}
              position={'startBottomLeft'}
              onChange={(value: any) => {
                const categories =
                  value < 2 || value == '' ? 2 : value > 10 ? 10 : value;
                const matrix = range(categories).map(row =>
                  range(categories).map(col => (col === row ? 0 : 1))
                );
                setDecisions(
                  [
                    {
                      id: 'meta.categories',
                      value: range(categories).map(i => (i + 1).toString())
                    },
                    {
                      id: 'meta.weightsMatrix',
                      value: matrix
                    }
                  ],
                  setUpdateCompetition
                );
              }}>
              <NumberInput
                disabled={false}
                value={data.meta.categories ? data.meta.categories.length : 0}
                controlled={true}
                placeholderId={'Categories'}
              />
            </NumPad.Number>
          )}
          <div className="AdminSectionText AdminSectionText--small">
            <FormattedHTMLMessage id={`admin.upload.categories.text`} />
          </div>
          <div className={styles['ClassificationFormulaChoice-formula-layout']}>
            <div
              className={
                styles['ClassificationFormulaChoice-formula-container']
              }>
              <div
                className={`AdminSectionTitle ${
                  styles['ClassificationFormulaChoice-formula']
                }`}>
                <FormattedMessage id={`admin.upload.formula`} />
              </div>
              <div
                className={styles[`ClassificationFormulaChoice-flex-layout`]}>
                <div className={styles['Latex-item']}>
                  <div className={styles['Latex-itemLabel']}>
                    <FormattedHTMLMessage
                      id={`admin.upload.classification.formula.error.weights`}
                    />
                  </div>
                  <div className={styles['Latex-itemLatex']}>
                    <BlockMath math={formulas.Classification[0].math} />
                  </div>
                </div>
                <div style={{ marginLeft: '60px' }}>
                  <div
                    style={{ maxWidth: '375px', fontSize: '18px' }}
                    className={`AdminSectionTitle ${
                      styles['ClassificationFormulaChoice-standardize']
                    }`}>
                    <FormattedHTMLMessage
                      id={`admin.upload.classification.formula.standardize.formula`}
                    />
                  </div>
                  <Checkbox
                    disabled={disabled}
                    label={'YES'}
                    checked={
                      data.meta.scoreFunctionName ===
                      DSCClassificationScoreFnName.AVG
                    }
                    onChange={() => {
                      setDecision(
                        'meta.scoreFunctionName',
                        DSCClassificationScoreFnName.AVG,
                        setUpdateCompetition
                      );
                    }}
                  />
                  <Checkbox
                    disabled={disabled}
                    label={'NO'}
                    checked={
                      data.meta.scoreFunctionName ===
                      DSCClassificationScoreFnName.SUM
                    }
                    onChange={() => {
                      setDecision(
                        'meta.scoreFunctionName',
                        DSCClassificationScoreFnName.SUM,
                        setUpdateCompetition
                      );
                    }}
                  />
                </div>
              </div>
              <div
                className={
                  'AdminSectionText ' +
                  styles['ClassificationFormulaChoice-formula-layout-text']
                }>
                <ul>
                  <li>
                    <FormattedMessage
                      id={`admin.upload.classification.formula.explenation.1`}
                      values={{
                        1: Latex('y_i'),
                        2: Latex('i(i=1,...,n)')
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id={`admin.upload.classification.formula.explenation.2`}
                      values={{
                        1: Latex('\\hat{y}_i(i=1,...,n)'),
                        2: Latex('{y}_i')
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id={`admin.upload.classification.formula.explenation.3`}
                      values={{
                        1: Latex('W_{[\\hat{y}_i,y_i]}'),
                        2: Latex('{y}_i'),
                        3: Latex('\\hat{y}_i(i=1,...,n)')
                      }}
                    />
                  </li>
                </ul>
                For example:
                <ul>
                  <li>
                    <FormattedMessage
                      id={`admin.upload.classification.formula.explenation.4`}
                      values={{
                        1: Latex('\\hat{y}_i = l'),
                        2: Latex(
                          'y_i = l → W_{[\\hat{y}_i,y_i]} = W_{[l,l]} = 0'
                        )
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id={`admin.upload.classification.formula.explenation.4`}
                      values={{
                        1: Latex('\\hat{y}_i = j'),
                        2: Latex('y_i = l → W_{[\\hat{y}_i,y_i]} = W_{[j,l]}')
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={styles['ClassificationFormulaChoice-matrixContainer']}>
              <div
                className={`AdminSectionTitle ${
                  styles['ClassificationFormulaChoice-matrixTitle']
                }`}>
                <FormattedHTMLMessage
                  id={`admin.upload.classification.matrix.title`}
                />
              </div>
              <div
                className={`AdminSectionText ${
                  styles['ClassificationFormulaChoice-matrixSubtitle']
                }`}>
                <FormattedHTMLMessage
                  id={`admin.upload.classification.matrix.subtitle`}
                />
              </div>
              <Matrix
                disabled={disabled}
                matrix={data.meta.weightsMatrix}
                categories={
                  data.meta.weightsMatrix && data.meta.weightsMatrix.length
                }
                onChange={(value: any) => {
                  setDecision(
                    'weightsMatrix',
                    range(value).map(n => n + 1 + ''),
                    setUpdateCompetition
                  );
                }}
              />
              <div
                className={`AdminSectionText ${
                  styles['ClassificationFormulaChoice-matrixDescription']
                }`}>
                <FormattedMessage
                  id={`admin.upload.classification.matrix.description`}
                  values={{
                    1: Latex('W_{[j,l]}'),
                    2: Latex('j'),
                    3: Latex('l')
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClassificationFormulaChoice;
