import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import moment from 'moment';
import classes from './style.module.scss';
import Table from '../Table';
import Paragraph from '../Paragraph';
import Button from '../Button';
import Section from '../Section';
import { IPage, ICompetition } from '@vas/competition-service-share';
import {
  ADMIN_COMPETITION,
  ADMIN_COMPETITION_LEADERBOARD
} from '../../shared/routes';
import leaderboardIconUrl from '../../assets/icons/leaderboard.svg';
import modifyIconUrl from '../../assets/icons/modify.svg';
import deleteIconUrl from '../../assets/icons/delete.svg';
import { NavLink } from 'react-router-dom';
import Status from '../Status';
import { formatRoute } from 'react-router-named-routes';
import Modal from '../Modal';
import { AuthGate } from '../Auth';
import Loader from '../Loader';

interface IProps {
  competitions: IPage<ICompetition>;
  competition: ICompetition;
  loading: boolean;
  storing: boolean;
  deletedCompetition: boolean;
  onCreateCompetition(data?: any): ICompetition;
  onUpdateCompetition(data?: any): ICompetition;
  onDeleteCompetition(data?: any): any;
  onGetCompetitions(data: any): IPage<ICompetition>;
  error: any;
  removed: boolean;
  history: any;
  user : {
    id : string
  }
}

interface IState {
  showModal: boolean;
  competitionIdToRemove: string | null;
  error: any;
}

const TableRows = 8;

class Admin extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      competitionIdToRemove: null,
      error: null
    };
    this.handleShowRemoveModalClicked = this.handleShowRemoveModalClicked.bind(
      this
    );
    this.handleModalCloseClicked = this.handleModalCloseClicked.bind(this);
  }
  componentDidMount(): void {
    this.props.onGetCompetitions({
      ownerID: this.props.user.id,
      page: {
        cursor: '0',
        limit: TableRows
      }
    });
  }
  handleModalCloseClicked() {
    this.setState({
      showModal: false,
      competitionIdToRemove: null,
      error: ''
    });
  }
  handleNewCompetitionClicked = () => {
    this.props.onCreateCompetition({ owners: [this.props.user.id] });
  };
  handleEditCompetitionClicked = (id: string) => {
    this.props.history.push(
      formatRoute(ADMIN_COMPETITION, { competitionID: id })
    );
  };
  handleShowRemoveModalClicked = (id: string) => {
    let showModal = !this.state.showModal;
    this.setState({
      competitionIdToRemove: id,
      showModal
    });
  };
  handleRemoveCompetitionClicked = () => {
    const { competitionIdToRemove } = this.state;
    if (competitionIdToRemove) {
      this.props.onDeleteCompetition({ competitionID: competitionIdToRemove });
      this.setState({
        competitionIdToRemove: null,
        showModal: false
      });
    }
  };
  componentWillReceiveProps(
    nextProps: Readonly<IProps>,
    nextContext: any
  ): void {
    if (nextProps.deletedCompetition) {
      this.setState(
        {
          competitionIdToRemove: null,
          showModal: false
        },
        () => {
          this.props.onGetCompetitions({
            ownerID: this.props.user.id,
            page: {
              cursor: '0',
              limit: TableRows
            }
          });
        }
      );
    }
    if (
      this.props.competition !== nextProps.competition &&
      nextProps.competition
    ) {
      this.props.history.push(
        formatRoute(ADMIN_COMPETITION, {
          competitionID: nextProps.competition.id
        })
      );
    }
  }

  render() {
    const { showModal, error } = this.state;
    const { competitions, loading, onGetCompetitions } = this.props;

    if (typeof window !== 'undefined') {
      showModal
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto');
    }

    if (error) {
      return (
        <div className={classes.Admin}>
          <span className={classes['Admin-error']}>{error}</span>
        </div>
      );
    }

    const leaderboard = (id: string) => (
      <NavLink
        to={formatRoute(ADMIN_COMPETITION_LEADERBOARD, { competitionID: id })}
        className={classes['Admin-leaderboard']}>
        <img src={leaderboardIconUrl} alt={'leaderboard'} />
        <span>Leaderboard</span>
      </NavLink>
    );

    return (
      <div className={classes.Admin}>
        {' '}
        {showModal && (
          <Modal
            titleId={'admin.competition.remove'}
            showCloseButton={false}
            onModalCloseClicked={this.handleModalCloseClicked}>
            <Button type={'undo'} clicked={this.handleModalCloseClicked}>
              <FormattedMessage id={'button.undo.remove'} />
            </Button>
            <Button
              type={'confirm'}
              clicked={this.handleRemoveCompetitionClicked}>
              <FormattedMessage id={'button.accept.remove'} />
            </Button>
          </Modal>
        )}
        <Section styles={{ padding: '0' }}>
          <Paragraph large title={<FormattedMessage id={`admin.title`} />} />
          <Button
            styles={{ margin: '20px 0 50px 0' }}
            clicked={this.handleNewCompetitionClicked}>
            <FormattedMessage id={'admin.competition.add'} />
          </Button>
          { !competitions.totalCount && loading && <Loader /> }
          {competitions.totalCount > 0 && (
            <Paragraph
              large
              styles={{
                maxWidth: '485px',
                fontWeight: '100',
                fontSize: '22px'
              }}
              descriptionId={`admin.list.title`}
              hideSeparator={true}
            />
          )}
          {competitions.totalCount > 0 ? (
            <Table
              pagination={true}
              serverSidePagination={true}
              cursor={competitions.cursor}
              totalCount={competitions.totalCount}
              paginationRows={TableRows}
              fetchMore={(page: number) => {
                onGetCompetitions({
                  ownerID: this.props.user.id,
                  page: {
                    cursor: (page - 1) * TableRows,
                    limit: TableRows
                  }
                });
              }}
              headers={[
                { content: <FormattedMessage id="admin.name.text" /> },
                { content: <FormattedMessage id="admin.expiringDate.text" /> },
                { content: <FormattedMessage id="admin.top.text" /> },
                { content: <FormattedMessage id="admin.state.text" /> },
                { content: <FormattedMessage id="admin.blank.text" /> }
              ]}
              data={competitions.results.map((competition: ICompetition, n) => [
                {
                  content: (
                    <div>
                      <div>
                        <NavLink to={`/competitions/${competition.id}`}><strong>{competition.title}</strong></NavLink>
                      </div>
                      <div>
                        {' '}
                        <small>{`Prof: ${competition.meta.teacher}`}</small>
                      </div>
                    </div>
                  )
                },
                {
                  content: moment(new Date(competition.endDate)).format(
                    'DD.MM.YYYY - HH:mm'
                  )
                },
                { content: leaderboard(competition.id) },
                {
                  content: <Status status={competition.status} />
                },
                {
                  content: (
                    <div>
                      <button
                        onClick={() =>
                          this.handleEditCompetitionClicked(competition.id)
                        }>
                        <img
                          src={modifyIconUrl}
                          style={{ marginRight: '20px' }}
                          alt={'edit'}
                        />
                      </button>
                      <button
                        onClick={() =>
                          this.handleShowRemoveModalClicked(competition.id)
                        }>
                        <img src={deleteIconUrl} alt={'delete'} />
                      </button>
                    </div>
                  )
                }
              ])}
            />
          ) : (
              <span
                className={classes['Admin-error']}
                style={{ minHeight: '200px', color: 'white' }}>
                <FormattedHTMLMessage id="admin.competitions.missing" />
              </span>
            )}
        </Section>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  competitions: state.competitionState.competitions,
  competition: state.competitionState.lastCreatedCompetition,
  deletedCompetition: state.competitionState.deletedCompetition,
  error: state.competitionState.error,
  loading: state.competitionState.isByOwnerFetching,
  storing: state.competitionState.isStoring,
  removed: state.competitionState.removed
});

const mapDispatchToProps = (dispatch: any) => ({
  onGetCompetitions: (data: any) =>
    dispatch(actions.getCompetitionsByOwner(data)),
  onCreateCompetition: (data: any) => dispatch(actions.createCompetition(data)),
  onUpdateCompetition: (data: any) => dispatch(actions.updateCompetition(data)),
  onDeleteCompetition: (data?: any) => dispatch(actions.deleteCompetition(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
