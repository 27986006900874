import { InputTemplate, IProps, IState } from '../';
import classes from './styles.module.scss';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

interface IToggleInputProps extends IProps {
  value: boolean;
  /**
   * Value label to display if checked
   */
  valueId?: string;
  /**
   * On change event, will pass current toggle checked value
   */
  onChange?: (checked: boolean) => void;
}

interface IToggleInputState extends IState {
  value: boolean;
}

const DEFAULT_CLASSES = [classes['Input--reverse'], classes['Input--end']].join(
  ' '
);
/**
 * This class implements InputTemplate
 */
export class ToggleInputInput extends InputTemplate<
  IToggleInputProps,
  IToggleInputState
> {
  props: IToggleInputProps = { value: false };
  state: IToggleInputState = { value: false };
  classes = classes;

  constructor(props: IToggleInputProps) {
    super(props);
    this.props = props;
    if (this.props.value === true) {
      this.state.value = true;
    }
  }

  /**
   * Overrides super class value method
   * @param checked true if checked
   */
  value(checked?: boolean): boolean {
    if (!this.props.controlled) {
      this.state.value =
        typeof checked !== 'undefined' ? checked : !this.state.value;
      this.setState(this.state);
    }

    return this.state.value || false;
  }

  /**
   * Utils function to check if input is checked
   */
  checked() {
    return this.props.controlled ? this.props.value : this.state.value;
  }

  render() {
    return (
      <div
        style={this.props.styles}
        className={[
          classes.ToggleSwitch,
          this.checked() ? classes['is-active'] : '',
          this.getCustomClasses()
        ].join(' ')}>
        {this.props.labelId && this.getLabel()}
        {
          <FormattedMessage
            id={this.props.placeholderId || 'general.emptyMessage'}
            defaultMessage={''}>
            {(placeholder: any) => (
              <div
                className={[
                  classes.Input,
                  classes['Input--shrink'],
                  classes['Input--end'],
                  this.getCustomClasses()
                ].join(' ')}>
                {this.props.placeholderId && (
                  <label className={classes['Input--small--label']}>
                    {placeholder}
                  </label>
                )}
                <div className={classes['ToggleSwitch-button-container']}>
                  <button
                    onClick={(e: any) => {
                      this.value();
                      if (this.props.onChange) {
                        this.props.onChange(this.checked());
                      }
                    }}
                    className={classes['ToggleSwitch-button']}>
                    <div className={classes['ToggleSwitch-content']}>
                      <div className={classes['ToggleSwitch-round']} />
                    </div>
                  </button>
                </div>
                {this.props.valueId && (
                  <small className={classes['ToggleSwitch-value']}>
                    <FormattedMessage id={this.props.valueId} />
                  </small>
                )}
              </div>
            )}
          </FormattedMessage>
        }
      </div>
    );
  }
}

export default ToggleInputInput;
