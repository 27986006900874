import React, { Component } from 'react';
import classes from './style.module.scss';
import farIllustrationImageUrl from '../../assets/img/welcome-stone.png';
import illustrationImageUrl from '../../assets/img/welcome-illustration.png';

interface State {
  x: number;
  y: number;
  z: number;
  angle: number;
}

const SCREEN_WIDTH = screen.width;
const SCREEN_HEIGHT = screen.height;
const FRAME_RATE = 1000 / 24; /// 24 fps
class WelcomeIllustration extends Component {
  state: State = { x: 0, y: 0, z: -1, angle: 0 };
  cMousePos: any = { x: 0, y: 0 };
  timeouts: any[] = [];

  constructor(props: any) {
    super(props);
    this.handleMouseMovement = this.handleMouseMovement.bind(this);
    this.state.x = 0;
    this.state.y = 0;
    this.state.z = 0;
  }

  start(): void {
    this.timeouts.push(
      setInterval(
        () => {
          this.setState({
            x: -(this.cMousePos.x - SCREEN_WIDTH / 2) / 25,
            y: -(this.cMousePos.y - SCREEN_HEIGHT / 2) / 25,
            z: 1,
            angle:
              (Math.atan2(
                this.cMousePos.x - SCREEN_WIDTH,
                -(this.cMousePos.y - SCREEN_HEIGHT)
              ) *
                (180 / Math.PI)) /
              25
          });
        },
        FRAME_RATE,
        0
      )
    );
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.handleMouseMovement);
    window.addEventListener('mouseenter', this.handleMouseMovement);
    window.addEventListener('mouseleave', this.handleMouseMovementLeave);
    setTimeout(() => {
      this.start();
    }, 1000);
  }
  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMovement);
    window.removeEventListener('mouseenter', this.handleMouseMovement);
    window.addEventListener('mouseleave', this.handleMouseMovementLeave);
    this.timeouts.filter(t => clearTimeout(t));
  }
  handleMouseMovement(ev: MouseEvent): void {
    this.cMousePos = { x: ev.pageX, y: ev.pageY };
  }
  handleMouseMovementLeave(ev: MouseEvent): void {
    this.cMousePos = { x: 0, y: 0 };
  }
  render() {
    return (
      <div className={classes.WelcomeIllustration}>
        <img
          className={classes['WelcomeIllustration-far']}
          src={farIllustrationImageUrl}
          style={{
            transform: `translate3d(${-this.state.x / 1.5}px, ${-this.state.y /
              1.5}px, ${this.state.z}px)`
          }}
          alt="welcome"
        />
        <img
          className={classes['WelcomeIllustration-overlay']}
          src={illustrationImageUrl}
          style={{
            transform: `translate3d(${this.state.x / 2}px, ${this.state.y /
              2}px, ${this.state.z / 2}px)`
          }}
          alt="welcome"
        />
      </div>
    );
  }
}

export default WelcomeIllustration;
