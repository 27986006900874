import React from 'react';
import classes from './style.module.scss';

interface CompetitionImageProps {
	src?: string;
	children?: any;
	styles?: object;
}

const CompetitionImage = ({
	src,
	children,
	styles
}: CompetitionImageProps) => {
	const backgroundImage = {
		backgroundImage: `url(${src})`
	};

	return (
		<div style={styles} className={classes.CompetitionImage}>
			<div
				className={classes['CompetitionImage-cover']}
				style={backgroundImage}>
				{children}
			</div>
		</div>
	);
};

export default CompetitionImage;
