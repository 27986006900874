import React, { Component } from 'react';
import UserClient from './client';
import { get, isEqual, isUndefined } from 'lodash';

interface IUser {
  id: string;
  scopes: IUserScopes;
}

interface IUserScopes {
  [propName: string]: any;
}

interface AuthContextProps {
  user: IUser | null;
}

export const AuthContext = React.createContext<AuthContextProps>({
  user: null
});

/**
 * Provider
 */
interface AuthProviderProps {
  endpoint: string;
}

interface AuthProviderState {
  user: IUser | null;
}

export class AuthProvider extends Component<
  AuthProviderProps,
  AuthProviderState
> {
  private client: UserClient;
  private interval: any;

  constructor(props: AuthProviderProps) {
    super(props);
    this.client = new UserClient({ endpoint: props.endpoint });
    this.state = { user: null };
  }

  componentDidMount() {
    this.fetch();
    this.interval = setInterval(() => {
      this.fetch();
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async fetch() {
    const info = await this.client.getUser();
    this.setState(() => ({
      user: info.user
    }));
  }

  getContext() {
    return {
      user: this.state.user
    };
  }

  render() {
    return (
      <AuthContext.Provider value={this.getContext()}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

/**
 * Gate
 */
interface AuthGateProps {
  scope?: string;
  value?: any;
  children: (props: ChildProps) => React.ReactNode;
}

interface ChildProps {
  user: IUser | null;
  isAuthenticated: boolean;
  isAuthorized: boolean;
  scope: any;
}

export class AuthGate extends Component<AuthGateProps> {
  render() {
    return (
      <AuthContext.Consumer>
        {({ user }) => {
          let isAuthenticated = !!user;
          let isAuthorized = false;
          const scope = user
            ? get(user.scopes, this.props.scope || '')
            : undefined;

          if (user && this.props.scope && !isUndefined(scope)) {
            isAuthorized = true;
          } else {
            isAuthorized = false;
          }
          return this.props.children({
            user,
            isAuthenticated,
            isAuthorized,
            scope
          });
        }}
      </AuthContext.Consumer>
    );
  }
}
