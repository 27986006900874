"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var axios_1 = require("axios");

var urlJoin = require("urljoin");

var DSCClient =
/** @class */
function () {
  function DSCClient(config) {
    var _this = this;

    this.config = {
      endpoint: "",
      authToken: "",
      competitionsNS: "cs",
      submissionsNS: "sbs",
      adminNS: "adm"
    };

    this.getCompetition = function (data) {
      var competitionID = data.competitionID;
      return _this.competitions.get("/competitions/" + competitionID).then(_this.getData);
    };

    this.getCompetitions = function (data) {
      var page = data.page;
      return _this.competitions.get("/competitions", {
        params: page
      }).then(_this.getData);
    };

    this.getCompetitionsByUser = function (data) {
      var page = data.page,
          userID = data.userID;
      return _this.competitions.get("/competitions-by-user/" + userID, {
        params: page
      }).then(_this.getData);
    };

    this.getCompetitionsByOwner = function (data) {
      var page = data.page,
          ownerID = data.ownerID;
      return _this.competitions.get("/competitions-by-owner/" + ownerID, {
        params: page
      }).then(_this.getData);
    };

    this.deleteCompetition = function (data) {
      var competitionID = data.competitionID;
      return _this.competitions.delete("/competitions/" + competitionID).then(function () {
        return Promise.resolve();
      });
    };

    this.createCompetition = function (data) {
      return _this.competitions.post("/competitions", data).then(_this.getData);
    };

    this.updateCompetition = function (data) {
      var competitionID = data.competitionID,
          competition = data.competition;
      return _this.competitions.put("/competitions/" + competitionID, competition).then(_this.getData);
    };

    this.updateCompetitionMeta = function (data) {
      var competitionID = data.competitionID,
          meta = data.meta;
      return _this.competitions.put("/competitions/" + competitionID + "/meta", meta).then(_this.getData);
    };

    this.publishCompetition = function (data) {
      var competitionID = data.competitionID;
      return _this.competitions.post("/competitions/" + competitionID + "/publish").then(_this.getData);
    };

    this.resetCompetition = function (data) {
      var competitionID = data.competitionID;
      return _this.competitions.post("/competitions/" + competitionID + "/reset").then(_this.getData);
    };

    this.loadCompetitionDataset = function (data) {
      var competitionID = data.competitionID,
          datasetURL = data.datasetURL;
      return _this.admin.post("competitions/" + competitionID + "/datasets", {
        datasetURL: datasetURL
      }).then(function () {
        return _this.getCompetition({
          competitionID: competitionID
        });
      });
    };

    this.getPlayers = function (data) {
      var competitionID = data.competitionID,
          page = data.page;
      return _this.competitions.get("/competitions/" + competitionID + "/players", {
        params: page
      }).then(_this.getData);
    };

    this.setPlayers = function (data) {
      var competitionID = data.competitionID,
          players = data.players;
      return _this.competitions.post("/competitions/" + competitionID + "/players", players).then(function () {
        return Promise.resolve();
      });
    };

    this.appendPlayers = function (data) {
      var competitionID = data.competitionID,
          players = data.players;
      return _this.competitions.put("/competitions/" + competitionID + "/players", players).then(_this.getData);
    };

    this.deletePlayers = function (data) {
      var competitionID = data.competitionID;
      return _this.competitions.delete("/competitions/" + competitionID + "/players").then(function () {
        return Promise.resolve();
      });
    };

    this.deletePlayer = function (data) {
      var competitionID = data.competitionID,
          playerID = data.playerID;
      return _this.competitions.delete("/competitions/" + competitionID + "/players/" + playerID).then(function () {
        return Promise.resolve();
      });
    };

    this.getTeam = function (data) {
      var competitionID = data.competitionID,
          teamID = data.teamID;
      return _this.competitions.get("/competitions/" + competitionID + "/teams/" + teamID).then(_this.getData);
    };

    this.getTeams = function (data) {
      var competitionID = data.competitionID;
      return _this.competitions.get("/competitions/" + competitionID + "/teams").then(_this.getData);
    };

    this.deleteTeam = function (data) {
      var competitionID = data.competitionID,
          teamID = data.teamID;
      return _this.competitions.delete("/competitions/" + competitionID + "/teams/" + teamID).then(function () {
        return Promise.resolve();
      });
    }; // manca una corrispondenza per .put('/competitions/:competitionID/teams/:teamID')


    this.sendSubmission = function (data) {
      var competitionID = data.competitionID,
          payload = data.payload;
      return _this.submissions.post("/submissions", payload).then(_this.getData);
    };

    this.getLastSubmission = function (data) {
      var competitionID = data.competitionID,
          teamID = data.teamID;
      return _this.submissions.get("/submissions/" + competitionID + "/teams/" + teamID + "/last-submission").then(_this.getData);
    };

    this.sendWorkflow = function (data) {
      return _this.submissions.post("/workflows", data).then(_this.getData);
    };

    this.getLastWorkflow = function (data) {
      var competitionID = data.competitionID,
          teamID = data.teamID;
      return _this.submissions.get("/submissions/" + competitionID + "/teams/" + teamID + "/last-workflow").then(_this.getData);
    };

    this.getWorkflows = function (data) {
      var competitionID = data.competitionID;
      return _this.submissions.get("/submissions/" + competitionID + "/workflows").then(_this.getData);
    }; //manca /submissions/:competitionID/teams/:teamID/


    this.getLeaderboard = function (data) {
      var competitionID = data.competitionID,
          leaderboardID = data.leaderboardID;
      return _this.competitions.get("/competitions/" + competitionID + "/leaderboards/" + leaderboardID).then(_this.getData);
    };

    Object.assign(this.config, config);
    this.competitions = this.httpInit(this.config.endpoint, this.config.competitionsNS, this.config.authToken);
    this.submissions = this.httpInit(this.config.endpoint, this.config.submissionsNS, this.config.authToken);
    this.admin = this.httpInit(this.config.endpoint, this.config.adminNS, this.config.authToken);
  }

  DSCClient.prototype.httpInit = function (endpoint, namespace, authToken) {
    var instance = axios_1.default.create({
      baseURL: urlJoin(endpoint, namespace),
      headers: {
        Authorization: authToken
      }
    });
    instance.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      console.log(error);

      if (error.response) {
        var payload = error.response.data;

        if (payload.error) {
          return Promise.reject(new Error(payload.error));
        }
      }

      return Promise.reject(error);
    });
    return instance;
  };

  DSCClient.prototype.getData = function (res) {
    return res.data;
  };

  return DSCClient;
}();

exports.default = DSCClient;