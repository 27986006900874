import React from 'react';
import classes from './style.module.scss';

interface progressBarProps {
	value?: number;
	styles?: object;
}

const progressBar = ({ value, styles }: progressBarProps) => {

	const innerStyles = {
		width: value && value > 100 ? '100%': `${value}%`,
	};

	return <div className={classes.ProgressBar} style={styles}><div className={classes["ProgressBar-inner"]} style={innerStyles} /></div>;
};

export default progressBar;
