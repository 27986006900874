import React, { Component } from 'react';
import styles from './style.module.scss';
import threeImage from '../../../../assets/img/arrow_three.svg';
import downloadIcon from '../../../../assets/icons/download.svg';
interface IFile {
  url?: string;
  name: string;
  disabled?: boolean;
}
interface Props {
  links: Array<IFile>;
  disabled?: boolean[];
}
interface State {}

class UploadedDatasets extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  render() {
    const { links, disabled } = this.props;
    return (
      <div className={styles['UploadedDatasets']}>
        <img src={threeImage} />
        <div className={styles['UploadedDatasets-list']}>
          {links.map((link: IFile, index: number) => (
            <div
              className={[
                styles['UploadedDatasets-list-item'],
                link.disabled || false ? styles['is-disabled'] : ''
              ].join(' ')}
              key={index}>
              <span>{link.name}</span>
              <a href={link.url || '#'} download>
                <img src={downloadIcon} alt="download" />
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default UploadedDatasets;
