import React, { Component } from 'react';
import { ButtonStatus, IInputFile } from '../types';
import ZipButton from '../ZipButton';
const basePath = window.ZIPPER_ENDPOINT;
interface Props {
  files: IInputFile[];
  zipName: string;
}
interface State {
  status: ButtonStatus;
  statusUrl: string;
  downloadUrl: string;
  tailIndex: number;
}

class ZipController extends Component<Props, State> {
  triggerer: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      status: ButtonStatus.ACTIVE,
      statusUrl: '',
      downloadUrl: '',
      tailIndex: 0
    };
    this.trigger = this.trigger.bind(this);
  }
  componentDidMount() {
    this.triggerer = setInterval(this.trigger, 1000);
  }
  trigger() {
    const { status } = this.state;
    switch (status) {
      case ButtonStatus.WAITING:
      case ButtonStatus.PROCESSING:
        this.checkStatus();
        return;
      default:
        return;
    }
  }
  checkStatus() {
    const { statusUrl } = this.state;
    fetch(statusUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        switch (data.status) {
          case 'WAITING':
            this.setState({
              status: ButtonStatus.WAITING,
              tailIndex: data.index
            });
            break;
          case 'PROCESSING':
            this.setState({
              status: ButtonStatus.PROCESSING
            });
            break;
          case 'READY':
            this.setState({
              status: ButtonStatus.READY
            });
            break;
        }
      })
      .catch(err => console.error(err));
  }
  request() {
    const { zipName, files } = this.props;
    fetch(`${basePath}insert`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        zipName,
        files
      })
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          status: ButtonStatus.WAITING,
          statusUrl: data.statusUrl,
          downloadUrl: data.downloadUrl,
          tailIndex: data.index
        });
      })
      .catch(err => console.error(err));
  }
  render() {
    const { status, downloadUrl, tailIndex } = this.state;
    return (
      <ZipButton
        request={() => this.request()}
        status={status}
        download={downloadUrl}
        tailIndex={tailIndex}
      />
    );
  }
}

export default ZipController;
