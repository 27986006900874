import React, { Component, Fragment } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import UploadBox from '../UploadBox';
import { CompetitionStatus } from '@vas/competition-service-share';
import { IDSCompetition, ISubmission, SubmissionStatus } from '@vas/dsc-share';
import { getUploadEndopoint } from '../../shared/utilities';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { IDSCompetitionView } from '../../shared/interfaces';
import isNil from 'lodash/isNil';

interface UploadInfo {
  name: string;
  url: string;
  date: Date;
}

interface ISubmissionsUploaderProps {
  competition: IDSCompetitionView;
  submission: ISubmission | undefined;
  sendSubmission: Function;
  disabled?: boolean;
  teamID: string;
  playerID: string;
}

export default class SubmissionUploader extends Component<
  ISubmissionsUploaderProps
> {
  onSubmissionUploaded(uploaded: UploadInfo) {
    const { sendSubmission, competition } = this.props;
    if (this.props.teamID && this.props.playerID) {
      sendSubmission(competition.id, {
        competitionID: competition.id,
        teamID: this.props.teamID,
        playerID: this.props.playerID,
        previsionUrl: uploaded.url
      });
    } else {
      console.error('Utente non abilitato a fare submission');
    }
  }

  getMessage(): JSX.Element {
    let descID = 'upload.submission.description';
    return <FormattedMessage id={descID} />;
  }

  disableUpload() {
    const { competition, submission, disabled } = this.props;
    return (
      disabled ||
      !competition ||
      !competition.published ||
      !competition.isRunning ||
      competition.status === CompetitionStatus.COMPLETED
    );
  }

  render() {
    const { submission, competition } = this.props;
    return (
      <Fragment>
        <UploadBox
          title={<FormattedMessage id={'upload.submission.title'} />}
          description={this.getMessage()}
          multiple={false}
          accept="text/plain"
          endpoint={getUploadEndopoint()}
          disabled={this.disableUpload()}
          onUploaded={file => this.onSubmissionUploaded(file)}
        />
        {submission && submission.status == SubmissionStatus.READY && (
          <FormattedHTMLMessage
            id="competition.submission.ready"
            values={{
              submissionDate: moment(submission.creationDate).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              previsionUrl: submission.previsionUrl,
              fileName: `${submission.teamID}-submission-${moment(
                submission.creationDate
              ).format('YYYY-MM-DD-HH-mm-ss')}.txt`,
              score: submission.results
                ? competition.isCompleted
                  ? (!isNil(submission.results.totalScore) && submission.results.totalScore.toFixed(2) )
                  : ( !isNil(submission.results.partialScore) && submission.results.partialScore.toFixed(2))
                : '-'
            }}
          />
        )}
        {submission && submission.status == SubmissionStatus.ERROR && (
          <FormattedHTMLMessage
            id="competition.submission.error"
            values={{
              submissionDate: moment(submission.creationDate).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              fileName: `${submission.teamID}-submission-${moment(
                submission.creationDate
              ).format('YYYY-MM-DD-HH-mm-ss')}.txt`,
              errorfileName: `${submission.teamID}-submission-${moment(
                submission.creationDate
              ).format('YYYY-MM-DD-HH-mm-ss')}.errors.txt`,
              previsionUrl: submission.previsionUrl,
              errorsUrl: submission.errorsLogUrl
            }}
          />
        )}
      </Fragment>
    );
  }
}
