import React, { Component, Fragment } from 'react';
import UploadBox from '../UploadBox';
import last from 'lodash/last';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { IDSCompetitionView } from '../../shared/interfaces';
import UploadedFiles from '../UploadedFiles';
import { CompetitionStatus } from '@vas/competition-service-share';
import moment from 'moment';
import { toast } from 'react-toastify';

interface IProps {
  competition: IDSCompetitionView;
  workflow: any;
  sendWorkflow: Function;
  getLastWorkflow: Function;
  disabled?: boolean;
}

class WorkflowUploader extends Component<IProps> {
  private interval: any;

  constructor(props: IProps) {
    super(props);
    this.interval = null;
  }

  render() {
    const { competition, sendWorkflow, workflow, disabled } = this.props;
    const lastWorkflow = workflow;
    return (
      <Fragment>
        <UploadBox
          title={<FormattedMessage id={'upload.workflow.title'} />}
          description={<FormattedMessage id={'upload.workflow.description'} />}
          multiple={false}
          endpoint={window.UPLOAD_ENDPOINT}
          disabled={
            disabled ||
            !competition ||
            !competition.published ||
            !competition.isRunning ||
            competition.status === CompetitionStatus.COMPLETED
          }
          onUploaded={file => sendWorkflow(file)}
        />
        {lastWorkflow && (
          <p>
            <a href={lastWorkflow.workflowUrl} download={true}>
              Your last workflow document
            </a>{' '}
            was uploaded{' '}
            {moment().from(moment(lastWorkflow.creationDate), true)} ago
          </p>
        )}

        <UploadedFiles
          files={
            lastWorkflow
              ? [
                  {
                    url: lastWorkflow.url,
                    name: lastWorkflow.name
                  }
                ]
              : []
          }
        />
      </Fragment>
    );
  }
}

export default WorkflowUploader;
