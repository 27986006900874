import { combineReducers } from 'redux';
import competitionReducer from './competition';
import submissionReducer from './submission';
import playerReducer from './player';
import teamReducer from './team';
import leaderboardReducer from './leaderboard';

export const rootReducer = combineReducers({
  competitionState: competitionReducer,
	playerState: playerReducer,
	teamState: teamReducer,
  submissionState: submissionReducer,
	leaderboardState: leaderboardReducer,
});
