import React from 'react';
import { updateObject } from '../../shared/utilities';
import { ISubmission, SubmissionStatus } from '@vas/dsc-share';
import { FormattedHTMLMessage } from 'react-intl';
import { toast } from 'react-toastify';
import moment from 'moment';

interface IAction {
  type: string;
  submissions: Array<ISubmission>;
  submission: ISubmission;
  status: SubmissionStatus;
  error: string;
  removed: boolean;
  response?: any;
}

const INITIAL_STATE = {
  submissions: [],
  submission: {},
  status: null,
  isFetching: false,
  isStoring: false,
  didInvalidate: false,
  error: null,
  removed: null
};

export function submissionID(opts: { competitionID: string; teamID: string }) {
  return `${opts.competitionID}:${opts.teamID}`;
}

// CREATE
/** @deprecated - da rimuovere */
const createSubmissionStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    submission: null,
    isStoring: true,
    error: null,
    didInvalidate: false,
    isStored: null
  });
};

/** @deprecated - da rimuovere */
const createSubmissionSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    submission: action.response.submission,
    isStoring: false
  });
};

/** @deprecated - da rimuovere */
const createSubmissionFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isStoring: false,
    didInvalidate: true
  });
};

// FETCH / READ
/** @deprecated - da rimuovere */
const fetchSubmissionStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    submission: null,
    isFetching: true,
    error: null,
    didInvalidate: false
  });
};

/** @deprecated - da rimuovere */
const fetchSubmissionSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    submission: action.response.submission,
    isFetching: false
  });
};

/** @deprecated - da rimuovere */
const fetchSubmissionFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

// FETCH COMPETITION STATUS
/** @deprecated - da rimuovere */
const fetchSubmissionStatusStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    status: null,
    isFetching: true,
    error: null,
    didInvalidate: false
  });
};

/** @deprecated - da rimuovere */
const fetchSubmissionStatusSuccess = (
  state = INITIAL_STATE,
  action: IAction
) => {
  return updateObject(state, {
    status: action.status,
    isFetching: false
  });
};

/** @deprecated - da rimuovere */
const fetchSubmissionStatusFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

// FETCH (ALL) SUBMISSION
/** @deprecated - da rimuovere */
const getSubmissionsStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    submissions: [],
    isFetching: true,
    error: null,
    didInvalidate: false
  });
};

/** @deprecated - da rimuovere */
const getSubmissionsSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    submissions: action.response.submissions,
    isFetching: false
  });
};

/** @deprecated - da rimuovere */
const getSubmissionsFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

// UPDATE COMPETITION
/** @deprecated - da rimuovere */
const updateSubmissionStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    submission: null,
    isStoring: true,
    error: null,
    didInvalidate: false,
    isStored: null
  });
};

/** @deprecated - da rimuovere */
const updateSubmissionSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    submission: action.submission,
    isStoring: false
  });
};

/** @deprecated - da rimuovere */
const updateSubmissionFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isStoring: false,
    didInvalidate: true
  });
};

// DELETE COMPETITION
/** @deprecated - da rimuovere */
const deleteSubmissionStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isStoring: true,
    removed: null,
    error: null,
    didInvalidate: false
  });
};

/** @deprecated - da rimuovere */
const deleteSubmissionSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, { removed: action.removed, isStoring: false });
};

/** @deprecated - da rimuovere */
const deleteSubmissionFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    removed: false,
    didInvalidate: true,
    isStoring: false
  });
};

/// GET LAST SUBMISSION

const getLastSubmissionStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isFetching: true,
    error: null,
    didInvalidate: null
  });
};

const getLastSubmissionSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    submission: {
      ...state.submission,
      [submissionID(action.response)]: action.response
    },
    isFetching: false
  });
};

const getLastSubmissionFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

//SEND SUBMISSION

const sendSubmissionStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isFetching: true
  });
};

const sendSubmissionSuccess = (state = INITIAL_STATE, action: IAction) => {
  const submission = action.response;

  if (submission.status == SubmissionStatus.READY) {
    toast.success(
      () => (
        <FormattedHTMLMessage
          id="competition.submission.ready"
          values={{
            submissionDate: moment(submission.creationDate).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            previsionUrl: submission.previsionUrl,
            fileName: `${submission.teamID}-submission-${moment(
              submission.creationDate
            ).format('YYYY-MM-DD-HH-mm-ss')}.txt`,
            score: submission.results
              ? submission.results.partialScore.toFixed(2)
              : '-'
          }}
        />
      ),
      {
        toastId: 'submission.ready'
      }
    );
  }
  if (submission.status == SubmissionStatus.ERROR) {
    toast.error(
      () => (
        <FormattedHTMLMessage
          id="competition.submission.error"
          values={{
            submissionDate: moment(submission.creationDate).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            previsionUrl: submission.previsionUrl,
            fileName: `${submission.teamID}-submission-${moment(
              submission.creationDate
            ).format('YYYY-MM-DD-HH-mm-ss')}.txt`,
            errorfileName: `${submission.teamID}-submission-${moment(
              submission.creationDate
            ).format('YYYY-MM-DD-HH-mm-ss')}.errors.txt`,
            errorsUrl: submission.errorsLogUrl
          }}
        />
      ),
      {
        toastId: 'submission.error'
      }
    );
  }

  return updateObject(state, {
    submission: {
      ...state.submission,
      [submissionID(action.response)]: action.response
    },
    isFetching: false
  });
};

const submissionReducer = (state = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    // CREATE
    case `ADD_SUBMISSION_START`:
      return createSubmissionStart(state, action);
    case `ADD_SUBMISSION_SUCCESS`:
      return createSubmissionSuccess(state, action);
    case `ADD_SUBMISSION_FAIL`:
      return createSubmissionFail(state, action);
    // READ/FETCHING (ALL)
    case `GET_COMPETITIONS_START`:
      return getSubmissionsStart(state, action);
    case `GET_COMPETITIONS_SUCCESS`:
      return getSubmissionsSuccess(state, action);
    case `GET_COMPETITIONS_FAIL`:
      return getSubmissionsFail(state, action);
    // READ/GETING
    case `FETCH_SUBMISSION_START`:
      return fetchSubmissionStart(state, action);
    case `FETCH_SUBMISSION_SUCCESS`:
      return fetchSubmissionSuccess(state, action);
    case `FETCH_SUBMISSION_FAIL`:
      return fetchSubmissionFail(state, action);
    case `FETCH_SUBMISSION_STATUS_START`:
      return fetchSubmissionStatusStart(state, action);
    case `FETCH_SUBMISSION_STATUS_SUCCESS`:
      return fetchSubmissionStatusSuccess(state, action);
    case `FETCH_SUBMISSION_STATUS_FAIL`:
      return fetchSubmissionStatusFail(state, action);
    // UPDATE
    case `UPDATE_SUBMISSION_START`:
      return updateSubmissionStart(state, action);
    case `UPDATE_SUBMISSION_SUCCESS`:
      return updateSubmissionSuccess(state, action);
    case `UPDATE_SUBMISSION_FAIL`:
      return updateSubmissionFail(state, action);
    // DELETE
    case `DELETE_SUBMISSION_START`:
      return deleteSubmissionStart(state, action);
    case `DELETE_SUBMISSION_SUCCESS`:
      return deleteSubmissionSuccess(state, action);
    case `DELETE_SUBMISSION_FAIL`:
      return deleteSubmissionFail(state, action);
    // GET LAST
    case `GET_LAST_SUBMISSION_START`:
      return getLastSubmissionStart(state, action);
    case `GET_LAST_SUBMISSION_SUCCESS`:
      return getLastSubmissionSuccess(state, action);
    case `GET_LAST_SUBMISSION_FAIL`:
      return getLastSubmissionFail(state, action);
    //SEND SUBMISSION
    case `SEND_SUBMISSION_START`:
      return sendSubmissionStart(state, action);
    case `SEND_SUBMISSION_SUCCESS`:
      return sendSubmissionSuccess(state, action);
    /* case `SEND_SUBMISSION_FAIL`:
      return sendSubmissionFail(state, action); */
    default:
      return state;
  }
};

export default submissionReducer;
