import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions';

import Loader from '../components/Loader';
import Error from '../components/Error';
import NotFound from '../components/NotFound';

import { IDSCompetitionView } from '../shared/interfaces';
import WorkflowUploader from '../components/WorkflowUploader';

const LAST_WORKFLOW_INTERVAL = 5000;

interface OwnProps {
  competition: IDSCompetitionView;
  teamID: string;
}

interface StateProps {
  workflow?: any;
  error?: any;
  loading?: boolean;
}

interface DispatchProps {
  sendWorkflow: Function;
  getLastWorkflow: Function;
}

interface UploadInfo {
  name: string;
  url: string;
  date: Date;
}

type Props = OwnProps & StateProps & DispatchProps;

class WorkflowUploaderController extends Component<Props> {
  interval: any;

  constructor(props: Props) {
    super(props);
    this.sendWorkflow = this.sendWorkflow.bind(this);
    this.getLastWorkflow = this.getLastWorkflow.bind(this);
  }

  sendWorkflow(file: UploadInfo) {
    const { competition, teamID } = this.props;
    if (competition.id) {
      this.props.sendWorkflow({
        competitionID: competition.id,
        teamID,
        workflowURL: file.url
      });
    }
  }

  getLastWorkflow() {
    const { competition, teamID } = this.props;
    if (competition.id && teamID) {
      this.props.getLastWorkflow({
        competitionID: competition.id,
        teamID
      });
    }
  }

  componentDidMount() {
    this.getLastWorkflow();
    this.startLastWorkflowPoolQuery();
  }
  /*
  getLastWorkflow() {
    this.props.getLastWorkflow();
  } */

  startLastWorkflowPoolQuery() {
    clearInterval(this.interval);
    this.interval = setInterval(
      () => this.getLastWorkflow(),
      LAST_WORKFLOW_INTERVAL
    );
  }

  componentDidUpdate(prevProps: OwnProps) {
    if (this.props.teamID != prevProps.teamID) {
      this.getLastWorkflow();
    }
    if (this.props.competition.id != prevProps.competition.id) {
      this.startLastWorkflowPoolQuery();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    if (this.props.error) {
      return <Error />;
    }

    if (this.props.loading && !this.props.competition) {
      return <Loader />;
    }

    if (!this.props.loading && !this.props.competition) {
      return null;
    }

    return (
      <WorkflowUploader
        workflow={this.props.workflow}
        competition={this.props.competition}
        sendWorkflow={this.sendWorkflow}
        getLastWorkflow={this.getLastWorkflow}
        disabled={!this.props.competition || !this.props.teamID}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  workflow: state.competitionState.workflow,
  error: state.competitionState.error,
  loading: state.competitionState.isFetching
});

const mapDispatchToProps = (dispatch: any) => ({
  sendWorkflow: (data: any) => dispatch(actions.sendWorkflow(data)),
  getLastWorkflow: (data: any) => dispatch(actions.getLastWorkflow(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowUploaderController);
