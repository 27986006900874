import React, { Component, CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import classes from './style.module.scss';

interface Props {
  /**
   * Current status
   * Available values: [RUNNING | COMPLETED]
   */
  status?: string;
  customStyle?: CSSProperties;
}

interface State {}

class Status extends Component<Props, State> {
  render() {
    const { status } = this.props;
    let statusID = '';
    let colorClass = '';
    switch (status) {
      case 'DRAFT':
        statusID = 'status.draft';
        break;
      case 'PUBLISHED':
        statusID = 'status.published';
        colorClass = 'Status-ball-green';
        break;
      case 'RUNNING':
        statusID = 'status.running';
        colorClass = 'Status-ball-green';
        break;
      case 'COMPLETED':
        statusID = 'status.completed';
        break;
      default:
        statusID = 'status.completed';
    }
    return (
      <div className={classes.Status} style={this.props.customStyle || {}}>
        <div
          className={[classes['Status-ball'], classes[colorClass] || ''].join(
            ' '
          )}
        />
        <div className={classes['Status-label']}>
          <FormattedMessage id={statusID} />
        </div>
      </div>
    );
  }
}

export default Status;
