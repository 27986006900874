import { DSCRegressionScoreFnName, DSCClassificationScoreFnName, DSCompetitionKind } from "@vas/dsc-share";


/* const Regression = {
  [DSCRegressionScoreFnName.DIFF_SUM] : {
    label: 'WEIGHTED SUM OF SQUARES',
    math: `\\sum_{i=1}^n w_i (y_i-\\hat{y}_i)^2`
  },
  [DSCRegressionScoreFnName.DIFF_AVG] : {
    label: 'WEIGHTED MEAN SQUARE ERROR',
    math: `\\frac{1}{\\sum_{i=1}^n w_i} \\sum_{i=1}^n w_i (y_i-\\hat{y}_i)^2`
  },
  [DSCRegressionScoreFnName.STDDEV_SUM] : {
    label: 'WEIGHTED SUM OF ABSOLUTE DEVIATIONS',
    math: `\\sum_{i=1}^n w_i |y_i-\\hat{y}_i|`
  },
  [DSCRegressionScoreFnName.STDDEV_AVG] : {
    label: 'WEIGHTED MEAN ABSOLUTE DEVIATIONS',
    math: `\\frac{1}{\\sum_{i=1}^n w_i} \\sum_{i=1}^n w_i |y_i-\\hat{y}_i|`
  }
};

const Classification = {
  [DSCClassificationScoreFnName.SUM] : {
    label: 'Weighted miss-classification error',
    math: `\\sum_{i=1}^n W [\\hat{y}_i,y_i]`
  },
  [DSCClassificationScoreFnName.AVG] : {
    label: 'Weighted miss-classification error',
    math: `\\sum_{i=1}^n W [\\hat{y}_i,y_i]`
  }
} */

const Regression = [
  {
    id: DSCRegressionScoreFnName.STDDEV_SUM,
    label: 'WEIGHTED SUM OF SQUARES',
    math: `\\sum_{i=1}^n w_i (y_i-\\hat{y}_i)^2`
  },
  { 
    id: DSCRegressionScoreFnName.STDDEV_AVG,
    label: 'WEIGHTED MEAN SQUARE ERROR',
    math: `\\frac{1}{\\sum_{i=1}^n w_i} \\sum_{i=1}^n w_i (y_i-\\hat{y}_i)^2`
  },
  { 
    id: DSCRegressionScoreFnName.DIFF_SUM,
    label: 'WEIGHTED SUM OF ABSOLUTE DEVIATIONS',
    math: `\\sum_{i=1}^n w_i |y_i-\\hat{y}_i|`
  },
  { 
    id: DSCRegressionScoreFnName.DIFF_AVG,
    label: 'WEIGHTED MEAN ABSOLUTE DEVIATIONS',
    math: `\\frac{1}{\\sum_{i=1}^n w_i} \\sum_{i=1}^n w_i |y_i-\\hat{y}_i|`
  }
];

const Classification = [
  {
    id: DSCClassificationScoreFnName.SUM,
    label: 'Weighted miss-classification error',
    math: `\\sum_{i=1}^n W_{[\\hat{y}_i,y_i]}`
  },
  {
    id: DSCClassificationScoreFnName.AVG,
    label: 'Weighted miss-classification error',
    math: `\\sum_{i=1}^n W_{[\\hat{y}_i,y_i]}`
  }
]

interface IFormula {
  id: DSCRegressionScoreFnName | DSCClassificationScoreFnName,
  label: string,
  math: string
}

interface IDSCFormula {
  [DSCompetitionKind.REGRESSION] : IFormula
  [DSCompetitionKind.CLASSIFICATION] : IFormula
}


const Formulas = {
  Regression,
  Classification
}

export default Formulas;