import React, { Component } from 'react';
import classes from './style.module.scss';
import './style.scss';
import { FormattedMessage } from 'react-intl';

import EditorTextArea from '../../EditorTextArea';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import DatePicker from '../../DatePicker';
import Section from '../../Section';
import UploadBox from '../../UploadBox';
import UploadedFiles from '../../UploadedFiles';
import ProgressBar from '../../ProgressBar';
import Button from '../../Button';
import previewDarkIconUrl from '../../../assets/icons/eye_dark.svg';
import previewIconUrl from '../../../assets/icons/eye.svg';
import CheckBoxInput from '../../InputTemplate/CheckBoxInput';
import {
  IRegressionCompetitionMeta,
  IClassificationCompetitionMeta,
  IDSCompetition,
  DSCompetitionKind,
  DSCClassificationScoreFnName
} from '@vas/dsc-share';
import { toast } from 'react-toastify';
import { formatRoute } from 'react-router-named-routes';
import { COMPETITION } from '../../../shared/routes';
import { connect } from 'react-redux';
import CompetitionGuideline from '../../CompetitionGuideline';
import CompetitionSelectedFormula from '../../CompetitionSelectedFormula';
const ONE_DAY = 1000 * 60 * 60 * 24;
interface IProps {
  match?: any;
  data: IDSCompetition;
  setDecision: Function;
  setDecisions: Function;
  setUpdateCompetition: (lastStep?: boolean) => void;
  setPublishCompetition: (lastStep?: boolean) => void;
  history: any;
}

interface IState {
  data: object;
  progress: {
    value: number;
    show: boolean;
  };
  back: boolean;
}

class AdminCompetitionCrudDetails extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: this.props.data || {},
      progress: {
        value: 0,
        show: true
      },
      back: false
    };
    this.handleSubmitCompetition = this.handleSubmitCompetition.bind(this);
    this.handlePublishCompetition = this.handlePublishCompetition.bind(this);
  }

  onBackButtonMouseOut = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    this.setState({ back: false });
  };
  onBackButtonMouseOver = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    this.setState({ back: true });
  };
  onTextareaBlur = (ev: any, editorState: any, field: string) => {
    const { setDecision, setUpdateCompetition } = this.props;
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setDecision(field, html, setUpdateCompetition);
  };

  handleSubmitCompetition() {
    this.props.setUpdateCompetition();
  }

  handlePublishCompetition() {
    const { data } = this.props;
    let valid = true;
    let publishErrors: string[] = [];
    if (!data.title) {
      valid = false;
      publishErrors.push('Required challenge title!');
    }
    if (!data.meta.kind) {
      valid = false;
      publishErrors.push('UPLOAD DATASET: Kind not valid');
    }
    if (
      data.meta.kind === DSCompetitionKind.REGRESSION &&
      !data.meta.scoreFunctionName
    ) {
      valid = false;
      publishErrors.push(
        'UPLOAD DATASET: Missing Score Function in kind REGRESSION!'
      );
    }

    if (valid) {
      this.props.setPublishCompetition();
    } else {
      toast.error(
        () => (
          <div>
            <h3>Validation Errors:</h3>
            <ul>
              {publishErrors.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </ul>
          </div>
        ),
        {
          toastId: 'publish.errors'
        }
      );
    }
  }

  render() {
    const { progress, back } = this.state;
    const { data, setDecision, setUpdateCompetition, match } = this.props;
    const { competitionID } = match.params;

    const startDate = data.startDate ? new Date(data.startDate) : new Date();
    const endDate = data.endDate
      ? new Date(data.endDate)
      : new Date(new Date().getTime() + ONE_DAY);

    const sectionStyles = {
      padding: '40px 0 50px',
      borderBottom: '1px solid rgba(0,0,0,0.2)',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    };

    return (
      <div className={classes.AdminNewCompetitionDetails}>
        <Section inline styles={sectionStyles}>
          <EditorTextArea
            labelId={'competition.intro.title'}
            styles={{ maxWidth: '550px', marginRight: '20px' }}
            placeholderId={'admin.competition.intro.placeholder'}
            value={data.meta.intro}
            onBlur={(ev: any, editorState: any) =>
              this.onTextareaBlur(ev, editorState, 'meta.intro')
            }
          />
          <div>
            <label className={classes['AdminNewCompetitionDetails-label']}>
              <FormattedMessage id={'admin.upload.documentation'} />
            </label>
            <UploadBox
              title={
                <FormattedMessage
                  id={'admin.upload.documentation.placeholder'}
                />
              }
              endpoint={window.UPLOAD_ENDPOINT}
              onUploaded={(uploaded: any) => {
                const currentFiles = data.meta.documentation
                  ? data.meta.documentation
                  : [];
                currentFiles.push({
                  name: uploaded.name,
                  url: uploaded.url
                });
                setDecision(
                  'meta.documentation',
                  currentFiles,
                  setUpdateCompetition
                );
              }}
            />
            {progress && progress.show && (
              <ProgressBar
                styles={{ marginTop: '25px' }}
                value={progress.value}
              />
            )}
            <UploadedFiles
              files={data.meta.documentation}
              onDelete={(index: number) => {
                let newFiles = [...data.meta.documentation];
                newFiles.splice(index, 1);
                setDecision(
                  'meta.documentation',
                  newFiles,
                  setUpdateCompetition
                );
              }}
            />
          </div>
        </Section>
        <Section inline styles={sectionStyles}>
          <div className={classes['AdminNewCompetitionDetails-katex']}>
            <label className={classes['AdminNewCompetitionDetails-label']}>
              <FormattedMessage id={'competition.rating.title'} />
            </label>
            <label
              className={[
                classes['AdminNewCompetitionDetails-label'],
                classes['AdminNewCompetitionDetails-label--small']
              ].join(' ')}>
              <FormattedMessage id={'competition.latex.title'} />
            </label>
            <CompetitionSelectedFormula competition={data} />
          </div>
          <div className={classes['AdminNewCompetitionDetails-katex']}>
            <label className={classes['AdminNewCompetitionDetails-label']}>
              <FormattedMessage id={'competition.uploadGuidelines.title'} />
            </label>
            <CompetitionGuideline />
          </div>
          {/*  <EditorTextArea
            labelId={'competition.uploadGuidelines.title'}
            styles={{ maxWidth: '650px', marginBottom: '50px' }}
            placeholderId={'admin.competition.uploadGuidelines.placeholder'}
            value={data.meta.uploadGuidelines}
            onBlur={(ev: any, editorState: any) =>
              this.onTextareaBlur(ev, editorState, 'meta.uploadGuidelines')
            }
          /> */}
          <EditorTextArea
            styles={{ maxWidth: '650px', margin: '45px 20px 0 0' }}
            placeholderId={'admin.competition.rating.placeholder'}
            value={data.meta.rating}
            onBlur={(ev: any, editorState: any) =>
              this.onTextareaBlur(ev, editorState, 'meta.rating')
            }
          />
          <EditorTextArea
            labelId={'competition.regulation.title'}
            styles={{ maxWidth: '650px' }}
            placeholderId={'admin.competition.regulation.placeholder'}
            value={data.meta.regulation}
            onBlur={(ev: any, editorState: any) =>
              this.onTextareaBlur(ev, editorState, 'meta.regulation')
            }
          />
        </Section>
        <Section styles={sectionStyles}>
          <div className={classes['AdminNewCompetitionDetails-uploadWorkflow']}>
            <label className={classes['AdminNewCompetitionDetails-label']}>
              <FormattedMessage id={'upload.workflow.title'} />
            </label>
            <label
              style={{ margin: '25px 0 20px' }}
              className={[
                classes['AdminNewCompetitionDetails-label'],
                classes['AdminNewCompetitionDetails-label--small']
              ].join(' ')}>
              <FormattedMessage id={'upload.workflow.question'} />
            </label>
            <div>
              <div style={{ maxWidth: '180px' }}>
                <CheckBoxInput
                  value={data.meta.pdfSubmission || false}
                  controlled={true}
                  placeholderId={'generic.yes'}
                  onChange={() => {
                    setDecision(
                      'meta.pdfSubmission',
                      true,
                      setUpdateCompetition
                    );
                  }}
                />
              </div>
              <div style={{ maxWidth: '11.25rem', marginLeft: '1rem' }}>
                <CheckBoxInput
                  value={!data.meta.pdfSubmission}
                  controlled={true}
                  placeholderId={'generic.no'}
                  onChange={() => {
                    setDecision(
                      'meta.pdfSubmission',
                      false,
                      setUpdateCompetition
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes['AdminNewCompetitionDetails-dates']}>
            <div style={{ marginRight: '100px' }}>
              <label className={classes['AdminNewCompetitionDetails-label']}>
                <FormattedMessage id={'admin.competition.startDate.title'} />
              </label>
              <DatePicker
                value={startDate}
                maxDate={endDate}
                minDate={new Date()}
                onChange={date => {
                  setDecision('startDate', date, setUpdateCompetition);
                }}
              />
            </div>
            <div>
              <label className={classes['AdminNewCompetitionDetails-label']}>
                <FormattedMessage id={'admin.competition.endDate.title'} />
              </label>
              <DatePicker
                minDate={startDate}
                value={endDate}
                onChange={date => {
                  setDecision('endDate', date, setUpdateCompetition);
                }}
              />
            </div>
          </div>
        </Section>
        <div className={classes['AdminNewCompetitionDetails-footer']}>
          <Button styles={{ margin: '0 30px' }} type={'secondary'}>
            <FormattedMessage id={'general.save'} />
          </Button>
          <Button
            type={'primary'}
            outline
            mouseOut={this.onBackButtonMouseOut}
            mouseOver={this.onBackButtonMouseOver}
            refresh={true}
            to={formatRoute(COMPETITION, { competitionID })}
            target={'_blank'}>
            {back ? (
              <img src={previewDarkIconUrl} alt={'preview'} />
            ) : (
              <img src={previewIconUrl} alt={'preview'} />
            )}
            <FormattedMessage id={'button.competition.preview'} />
          </Button>
          {!data.published && (
            <Button type={'primary'} clicked={this.handlePublishCompetition}>
              <FormattedMessage id={'button.competition.publish'} />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCompetitionCrudDetails);
