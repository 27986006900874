import { SelectInput, ISelectProps } from '../SelectInput';
import TextInput from '../TextInput';
import React from 'react';
import classes from './styles.module.scss';
import { ISelectItem } from '../../../shared/interfaces';

const AUTOCOMPLETE_CLASS = 'AutoCompleteInput';
// const NO_ITEMS_FOUND: ISelectItem = {
//   id: '-1',
//   value: '',
//   label: '🔍 Nessun risultato trovato' /// to dictionarize
// };
/**
 * This class implements SelectInput as autocomplete
 */
export class AutoCompleteInput extends SelectInput {
  classes = classes;
  internalClasses = [AUTOCOMPLETE_CLASS];

  constructor(props: ISelectProps) {
    super(props);
    this.props = props;
    this.props.customClasses;
    this.state.filteredItems = props.items;
  }

  filter(v: string) {
    this.state.showList = v !== '';
    if (v !== '') {
      this.state.filteredItems = this.props.items.filter(
        i =>
          i.value.toLowerCase().indexOf(v.toLowerCase()) > -1 ||
          i.label.toLowerCase().indexOf(v.toLowerCase()) > -1
      );
    } else {
      this.state.filteredItems = this.props.items;
    }

    if (this.state.filteredItems.length == 0) {
      this.state.filteredItems = [];
    }

    this.setState(this.state);
  }

  value(item?: ISelectItem): string {
    if (item && item.value) {
      this.onTextInputChange(item.label);
    }
    if (this.props.onChange) this.props.onChange(item ? item.value : null);
    return super.value(item);
  }

  onTextInputChange(v: string) {
    if (!this.props.controlled) {
      this.state.value = v;
      this.filter(v);
      this.setState(this.state);
    } else {
      this.filter(v);
    }

    if (this.props.onChange) this.props.onChange(v);
  }

  render() {
    return (
      <div className={this.classes.AutoCompleteWrapper}>
        <TextInput
          value={this.props.controlled ? this.props.value : this.state.value}
          controlled={true}
          customClasses={['AutoCompleteTextInput']}
          required={this.props.required}
          disabled={this.props.disabled}
          onChange={(v: string) => this.onTextInputChange(v)}
        />
        {super.render()}
      </div>
    );
  }
}

export default AutoCompleteInput;
