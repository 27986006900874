import { InputTemplate, IProps, IState } from '../';
import classes from '../styles.module.scss';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

interface ICheckBoxProps extends IProps {
  value: boolean;
  disabled?: boolean;
}

interface ICheckBoxState extends IState {
  value: boolean;
}

const DEFAULT_CLASSES = [classes['Input--reverse']].join(' ');
/**
 * This class implements InputTemplate
 */
export class CheckBoxInput extends InputTemplate<
  ICheckBoxProps,
  ICheckBoxState
> {
  props: ICheckBoxProps = { value: false };
  state: ICheckBoxState = { value: false };

  constructor(props: ICheckBoxProps) {
    super(props);
    this.props = props;
    if (this.props.value === true) {
      this.state.value = true;
    }
  }

  /**
   * Overrides super class value method
   * @param checked true if checked
   */
  value(checked?: boolean): boolean {
    if (!this.props.controlled) {
      this.state.value =
        typeof checked !== 'undefined' ? checked : !this.state.value;
      this.setState(this.state);
    }

    return this.state.value || false;
  }

  componentWillReceiveProps(nextProps: ICheckBoxProps) {
    this.setState({ value: nextProps.value });
  }

  /**
   * Overrides super method, if no custom class is passed then returns default classes
   */
  getCustomClasses(): string {
    if (!this.props.customClasses) {
      return DEFAULT_CLASSES;
    }
    return super.getCustomClasses();
  }

  /**
   * Utils function to check if input is checked
   */
  checked() {
    return this.props.controlled ? this.props.value : this.state.value;
  }

  render() {
    const { disabled } = this.props;
    return (
      <div style={this.props.styles}>
        {this.props.labelId && this.getLabel()}
        {
          <FormattedMessage
            id={this.props.placeholderId || 'general.emptyMessage'}
            defaultMessage={''}>
            {(placeholder: any) => (
              <div
                className={[classes.Input, this.getCustomClasses()].join(' ')}>
                <div
                  className={classes['Input--small--label']}
                  dangerouslySetInnerHTML={{
                    __html: placeholder
                  }}
                />
                <input
                  type="checkbox"
                  name="regression"
                  id="regression"
                  disabled={disabled}
                  checked={this.checked()}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    this.value(!this.checked());
                    if (this.props.onChange)
                      !disabled && this.props.onChange(this.checked());
                  }}
                />
              </div>
            )}
          </FormattedMessage>
        }
      </div>
    );
  }
}

export default CheckBoxInput;
