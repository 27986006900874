import React, { Component } from 'react';
import classes from './style.module.scss';
import { FormattedMessage } from 'react-intl';

interface Props {
  active: number;
  showTotals?: boolean;
  setActive(active: number): void;
}
interface State {}

class CompetitionLeaderboardButtons extends Component<Props, State> {
  render() {
    const { active, setActive } = this.props;
    return (
      <div className={classes.CompetitionLeaderboardButtons}>
        <div
          onClick={() => setActive(0)}
          className={active == 0 ? classes.isActive : ''}>
          <FormattedMessage
            id={`competition.leaderboard.${
              this.props.showTotals ? 'total' : 'partial'
            }`}
          />
        </div>
        <div
          onClick={() => setActive(1)}
          className={active == 1 ? classes.isActive : ''}>
          <FormattedMessage id={`competition.leaderboard.waiting`} />
        </div>
      </div>
    );
  }
}

export default CompetitionLeaderboardButtons;
