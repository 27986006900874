import React from 'react';
import classes from './style.module.scss';

interface CoverProps {
	reference?: any;
	children?: any;
	backgroundImageUrl?: string;
}

const Cover = ({ children, backgroundImageUrl, reference }: CoverProps) => {
	const backgroundStyles = {
		backgroundImage: `url(${backgroundImageUrl})`
	};

	return (
		<section className={classes.Cover} ref={reference}>
			<div className={classes['Cover-background']} style={backgroundStyles} />
			<div className={classes['Cover-content']}>{children}</div>
		</section>
	);
};

export default Cover;
