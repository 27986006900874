import React, { Fragment, Component, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { StickyContainer, Sticky } from 'react-sticky';
import moment from 'moment';
import { formatRoute } from 'react-router-named-routes';
import { animateScroll } from 'react-scroll';
import { RouteComponentProps } from 'react-router';

import range from 'lodash/range';

import styles from './style.module.scss';
import CompetitionCard from '../CompetitionCard';
import Section from '../Section';
import Paragraph from '../Paragraph';
import Loader from '../Loader';
import List from '../List';
import HighlightedBox from '../HighlightedBox';
import CompetitionLeaderboard from '../CompetitionLeaderboard';
import CompetitionCover from '../CompetionCover';
import alarmIconUrl from '../../assets/icons/alarm.svg';
import Timer from '../Timer';
import Navigation from '../Navigation';
import GeneratorCSV from '../GeneratorCSV';

import SubmissionUploaderController from '../../controllers/SubmissionUploaderController';
import WorkflowUploaderController from '../../controllers/WorkflowUploaderController';

import {
  ICompetition,
  CompetitionStatus,
  ILeaderBoard
} from '@vas/competition-service-share';
import { LEADERBOARD } from '../../shared/routes';
import { IDSCompetitionView } from '../../shared/interfaces';
import LeaderboardController from '../../controllers/LeaderboardController';
import CompetitionGuideline from '../CompetitionGuideline';
import { AuthGate } from '../Auth';
import CompetitionSelectedFormula from '../CompetitionSelectedFormula';
import { debug } from 'util';
import { DSCompetitionKind } from '@vas/dsc-share';
import Latex from '../Latex';

interface IProps {
  competition: IDSCompetitionView;
  goLeaderboard: boolean;
}

interface IState {
  stickyMenuHeight: number;
}

class Competition extends Component<IProps, IState> {
  private leaderboardHtmlElem = createRef<HTMLDivElement>();
  private coverHtmlElem = createRef<HTMLDivElement>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      stickyMenuHeight: 0
    };
    this.coverHtmlElem = React.createRef();
  }

  scrollToLeaderboard() {
    const leaderboardNode = this.leaderboardHtmlElem.current!;
    if (leaderboardNode && this.props.goLeaderboard) {
      const topOffset = leaderboardNode.getBoundingClientRect().top;
      animateScroll.scrollTo(topOffset, {
        duration: 500,
        spy: true,
        smooth: true
      });
    }
  }

  componentDidMount() {
    if (this.props.goLeaderboard) {
      this.scrollToLeaderboard();
    } else {
      window.scrollTo(0, 0);
    }

    const coverNode = this.coverHtmlElem.current;
    if (coverNode && coverNode.clientHeight != this.state.stickyMenuHeight) {
      this.setState({
        stickyMenuHeight: coverNode.clientHeight
      });
    }
  }

  render() {
    const { competition } = this.props;
    if (!competition) {
      return (
        <div className={styles['Competition-feedback']}>
          <Loader styles={{ margin: '0 20px 0 0' }} />
          <FormattedMessage id={'competition.fetch'} />
        </div>
      );
    }

    return (
      <StickyContainer>
        <div className={styles.Competition}>
          <div ref={this.coverHtmlElem}>
            <CompetitionCover competition={competition} />
          </div>
          <Sticky topOffset={this.state.stickyMenuHeight}>
            {({ style }) => (
              <div className={styles['Competition-gradient']} style={style}>
                <div className={styles['Competition-navigation']}>
                  <Navigation />
                  <div className={styles['Competition-time']}>
                    <img alt="alarm" src={alarmIconUrl} />
                    {competition.isRunning && (
                      <Timer
                        locale={'en'}
                        endDate={moment(new Date(competition.endDate))}
                        onDateExpire={
                          () => null // window.location.reload()
                        }>
                        <FormattedMessage id={'timer.ends.in'} />
                      </Timer>
                    )}
                    {!competition.isRunning &&
                      competition.published &&
                      competition.status !== CompetitionStatus.COMPLETED && (
                        <FormattedMessage id={'timer.not.started'} />
                      )}
                    {competition.status === CompetitionStatus.DRAFT && (
                      <FormattedMessage id={'competition.status.draft'} />
                    )}
                    {competition.status === CompetitionStatus.COMPLETED && (
                      <FormattedMessage id={'competition.status.completed'} />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Sticky>
          <InfoSection competition={competition} />
          <RatingSection competition={competition} />
          <SubmissionSection competition={competition} />
          <div ref={this.leaderboardHtmlElem}>
            <LeaderboardSection competition={competition} />
          </div>
        </div>
      </StickyContainer>
    );
  }
}

export default Competition;

const InfoSection = (props: { competition: IDSCompetitionView }) => (
  <Section
    inline
    id="intro"
    styles={{
      paddingTop: '75px',
      paddingBottom: '75px',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    }}>
    <Paragraph
      styles={{ maxWidth: '490px' }}
      title={<FormattedMessage id={'competition.intro.title'} />}
      description={props.competition.meta.intro}
    />
    <Paragraph
      styles={{ maxWidth: '490px' }}
      title={<FormattedMessage id={'competition.regulation.title'} />}
      description={props.competition.meta.regulation}
    />
    <List
      styles={{ maxWidth: '580px', marginTop: '40px' }}
      titleId={'competition.documentation.title'}
      showDownloadCta={true}
      items={
        props.competition.meta.documentation
          ? props.competition.meta.documentation
          : []
      }
    />
  </Section>
);

const getCompetitionCsv = (competition: any) => {
  const items = [
    {
      name: 'train.csv',
      url: competition.meta.trainingVectorUrl,
      description: ''
    },
    {
      name: 'test.csv',
      url: competition.meta.testVectorUrl,
      description: ''
    }
  ];
  if (competition.meta.kind == DSCompetitionKind.CLASSIFICATION) {
    return [
      ...items,
      {
        name: 'weights-matrix.csv',
        description: '',
        onClick: () =>
          GeneratorCSV(
            [
              [
                'ŷ/y',
                ...range(competition.meta.weightsMatrix.length).map(
                  (i: number) => i + 1
                )
              ],
              ...competition.meta.weightsMatrix.map(
                (row: number[], i: number) => [i + 1, ...row]
              )
            ],
            'weights-matrix.csv'
          )
      }
    ];
  } else {
    return items;
  }
};

const RatingSection = (props: { competition: IDSCompetitionView }) => (
  <Section
    inline
    id="rating"
    styles={{
      paddingTop: '75px',
      paddingBottom: '75px',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    }}
    dark>
    <List
      styles={{ maxWidth: '580px' }}
      titleId={'competition.datasets.title'}
      descriptionId={'competition.datasets.description'}
      items={getCompetitionCsv(props.competition)}
    />
    <HighlightedBox
      styles={{ maxWidth: '630px', margin: '0 0 75px 0' }}
      title={<FormattedMessage id={'competition.uploadGuidelines.title'} />}>
      <CompetitionGuideline />
    </HighlightedBox>
    <div>
      <Paragraph
        styles={{ maxWidth: '490px', margin: '0' }}
        title={<FormattedMessage id={'competition.rating.title'} />}
      />
      <CompetitionSelectedFormula competition={props.competition} />
      <Paragraph
        styles={{ maxWidth: '490px', margin: '0' }}
        description={props.competition.meta.rating}
        hideSeparator={true}
      />
    </div>
    <div>
      {props.competition.meta.kind == DSCompetitionKind.CLASSIFICATION ? (
        <ul className="AdminSectionText">
          <li>
            <FormattedMessage
              id={`admin.upload.classification.formula.explenation.1`}
              values={{
                1: Latex('y_i'),
                2: Latex('i(i=1,...,n)')
              }}
            />
          </li>
          <li>
            <FormattedMessage
              id={`admin.upload.classification.formula.explenation.2`}
              values={{
                1: Latex('\\hat{y}_i(i=1,...,n)'),
                2: Latex('{y}_i')
              }}
            />
          </li>
          <li>
            <FormattedMessage
              id={`admin.upload.classification.formula.explenation.3`}
              values={{
                1: Latex('W_{[\\hat{y}_i,y_i]}'),
                2: Latex('{y}_i'),
                3: Latex('\\hat{y}_i(i=1,...,n)')
              }}
            />
          </li>
        </ul>
      ) : (
        <ul className="AdminSectionText">
          <li>
            <FormattedMessage
              id="regression.formula.choice.label.1"
              values={{
                1: Latex('y_i'),
                2: Latex('i(i=1,...,n)')
              }}
            />
          </li>
          <li>
            <FormattedMessage
              id="regression.formula.choice.label.2"
              values={{
                1: Latex('\\hat{y}_i (i=1,...,n)'),
                2: Latex('y_i')
              }}
            />
          </li>
          <li>
            <FormattedMessage
              id="regression.formula.choice.label.3"
              values={{
                1: Latex('w_i'),
                2: Latex('y_i'),
                3: Latex('\\hat{y}_i (i=1,...,n)')
              }}
            />
          </li>
        </ul>
      )}
    </div>
  </Section>
);

const SubmissionSection = (props: { competition: IDSCompetitionView }) => (
  <Section
    id="submission"
    styles={{
      paddingTop: '75px',
      paddingBottom: '75px'
    }}>
    <Paragraph
      title={<FormattedMessage id={'competition.submission.title'} />}
    />
    <div className={styles['Competition-submissions']}>
      <div style={{ flexGrow: 1 }}>
        <AuthGate scope={`competitions.${props.competition.id}`}>
          {({ scope, isAuthorized }) => (
            <SubmissionUploaderController
              competition={props.competition}
              teamID={isAuthorized && scope.teamID}
              playerID={isAuthorized && scope.playerID}
            />
          )}
        </AuthGate>
      </div>
      <div style={{ flexGrow: 1 }}>
        {props.competition.meta.pdfSubmission && (
          <AuthGate scope={`competitions.${props.competition.id}`}>
            {({ scope, isAuthorized }) => (
              <WorkflowUploaderController
                competition={props.competition}
                teamID={isAuthorized && scope.teamID}
              />
            )}
          </AuthGate>
        )}
      </div>
    </div>
    <small className={styles['Competition-small']}>
      <FormattedMessage id={'upload.info'} />
    </small>
  </Section>
);

const LeaderboardSection = (props: { competition: IDSCompetitionView }) => (
  <Section
    id="leaderboard"
    /* referencehis.leaderboardAnchor} */
    styles={{
      paddingTop: '75px',
      paddingBottom: '75px',
      /* paddingTop: '4.6875rem' */
      minHeight: '50rem'
    }}>
    <Paragraph
      title={<FormattedMessage id={'competition.leaderboard.title'} />}
    />
    <LeaderboardController
      competitionID={props.competition.id}
      leaderboardID={props.competition.actualLeaderboardID}>
      {(data: { leaderboard: ILeaderBoard }) => (
        <CompetitionLeaderboard
          leaderboard={data.leaderboard}
          showTotals={props.competition.status === CompetitionStatus.COMPLETED}
        />
      )}
    </LeaderboardController>
  </Section>
);

const RelatedCompetition = (props: { competition: IDSCompetitionView }) => (
  <Fragment>
    {props.competition.relatedCompetitions &&
      props.competition.relatedCompetitions.length && (
        <Section
          id="other"
          styles={{
            paddingTop: '75px',
            paddingBottom: '75px'
          }}
          dark>
          <Paragraph
            title={<FormattedMessage id={'competition.other.title'} />}
          />
          <div className={styles['Competition-otherGames']}>
            {props.competition.relatedCompetitions.map(
              (competition: ICompetition, index: number) => {
                return (
                  <CompetitionCard
                    key={index}
                    id={competition.id}
                    title={competition.title}
                    to={`/competitions/${competition.id}`}
                    styles={{ margin: '0 20px 20px 0' }}
                    refresh
                    backgroundImageUrl={competition.imageURL}
                    hideOverlay={true}
                    leaderboardLink={formatRoute(LEADERBOARD, {
                      competitionID: competition.id,
                      anchor: 'leaderboard'
                    })}
                  />
                );
              }
            )}
          </div>
        </Section>
      )}
  </Fragment>
);
