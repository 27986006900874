import React from 'react';
import { FormattedMessage } from 'react-intl';
import classes from './style.module.scss';
import * as routes from '../../shared/routes';
import Button from '../Button';
import Paragraph from '../Paragraph';
import WelcomeIllustration from '../WelcomeIllustration';

const welcome = () => {
  return (
    <div className={classes.Welcome}>
      <div className={classes['Welcome-content']}>
        <div className={classes['Welcome-column']}>
          <Paragraph
            large
            styles={{ maxWidth: '485px' }}
            title={<FormattedMessage id={`app.title`} />}
            descriptionId={`welcome.description`}
          />
          <Button type={'primary'} to={routes.COMPETITIONS}>
            <FormattedMessage id={`welcome.cta`} />
          </Button>
        </div>
        <div className={classes['Welcome-column']}>
          <WelcomeIllustration />
        </div>
      </div>
    </div>
  );
};

export default welcome;
