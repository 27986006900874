export enum ButtonStatus {
  ACTIVE = 'ACTIVE',
  WAITING = 'WAITING',
  PROCESSING = 'PROCESSING',
  READY = 'READY',
}
export interface IInputFile {
  filename: string;
  url: string;
}
