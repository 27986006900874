import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions';

import Loader from '../components/Loader';
import Error from '../components/Error';
import NotFound from '../components/NotFound';

import {
  ISubmission,
  SubmissionRequestPayload,
  SubmissionStatus
} from '@vas/dsc-share';
import SubmissionUploader from '../components/SubmissionUploader';
import { IDSCompetitionView } from '../shared/interfaces';
import { toast } from 'react-toastify';
import { FormattedHTMLMessage } from 'react-intl';
import moment from 'moment';
import { submissionID } from '../store/reducers/submission';

const SUBMISSION_UPLOAD_INTERVAL = 10000;

interface OwnProps {
  competition: IDSCompetitionView;
  teamID: string;
  playerID: string;
}

interface StateProps {
  submission: ISubmission;
  error?: any;
  loading?: boolean;
}

interface DispatchProps {
  getLastSubmission: Function;
  sendSubmission: Function;
}

type Props = OwnProps & StateProps & DispatchProps;

/* interface ICompetitionParams {
  competitionID: string
}
 */
class SubmissionUploaderController extends Component<Props> {
  private interval: any;

  constructor(props: Props) {
    super(props);
    this.interval = null;
  }

  getLastSubmission() {
    const { competition, teamID } = this.props;
    if (competition && teamID) {
      this.props.getLastSubmission(competition.id, teamID);
    }
  }

  startLastSubmissionPoolQuery() {
    clearInterval(this.interval);
    this.interval = setInterval(
      () => this.getLastSubmission(),
      SUBMISSION_UPLOAD_INTERVAL
    );
  }

  componentDidMount() {
    this.getLastSubmission();
    this.startLastSubmissionPoolQuery();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.teamID != prevProps.teamID) {
      this.getLastSubmission();
    }
    if (this.props.competition.id != prevProps.competition.id) {
      this.startLastSubmissionPoolQuery();
    }
  }

  async sendSubmission(competitionID: string, submission: any) {
    this.props.sendSubmission(competitionID, submission);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    if (this.props.error) {
      return <Error />;
    }

    if (this.props.loading && !this.props.competition) {
      return <Loader />;
    }

    if (!this.props.loading && !this.props.competition) {
      return null;
    }

    return (
      <SubmissionUploader
        submission={this.props.submission}
        sendSubmission={(competitionID: string, data: any) =>
          this.sendSubmission(competitionID, data)
        }
        competition={this.props.competition}
        disabled={!this.props.competition || !this.props.teamID}
        teamID={this.props.teamID}
        playerID={this.props.playerID}
      />
    );
  }
}

const mapStateToProps = (state: any, props: OwnProps) => ({
  submission:
    state.submissionState.submission[
      submissionID({
        competitionID: props.competition.id,
        teamID: props.teamID
      })
    ],
  error: state.submissionState.error,
  loading: state.submissionState.isFetching
});

const mapDispatchToProps = (dispatch: any) => ({
  getLastSubmission: (competitionID: string, teamID: string) =>
    dispatch(actions.getLastSubmission({ competitionID, teamID })),
  sendSubmission: (competitionID: string, payload: SubmissionRequestPayload) =>
    dispatch(actions.sendSubmission({ competitionID, payload }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionUploaderController);
