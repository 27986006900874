import React from 'react';
import classes from './style.module.scss';

interface mainProps {
  children?: any;
}
const isIE11 =
  !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
const main = ({ children }: mainProps) => {
  if (isIE11) {
    return (
      <div className={classes.Main}>
        <div className={classes['Main-content']}>{children}</div>
      </div>
    );
  } else {
    return (
      <main className={classes.Main}>
        <div className={classes['Main-content']}>{children}</div>
      </main>
    );
  }
};

export default main;
