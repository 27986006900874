import React, { Component, CSSProperties } from 'react';
import classes from './style.module.scss';
import moment, { Moment } from 'moment';
import { FormattedMessage } from 'react-intl';

const humanizeDuration = require('humanize-duration');

interface Props {
  /**
   * Date to diff
   */
  endDate: Moment;
  /**
   * custom locale - default moment.locale()
   */
  locale?: string;
  /**
   * Custom css styles
   */
  styles?: CSSProperties;
  onDateExpire?: () => void;
}

interface State {
  currentDateFormatted?: JSX.Element;
}

class Timer extends Component<Props, State> {
  state: State = { currentDateFormatted: <span /> };
  tickInterval: any;
  constructor(props: any) {
    super(props);
    /// set locale
    moment.locale(this.props.locale);
  }

  componentDidMount() {
    this.tick();
    this.tickInterval = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.tickInterval);
  }

  tick() {
    const now = moment();
    const diff = this.props.endDate.diff(now, 'milliseconds');
    if (diff <= 0) {
      const { onDateExpire } = this.props;
      if (onDateExpire) {
        onDateExpire();
      }
      this.setState({
        currentDateFormatted: <FormattedMessage id={'label.expired'} />
      });
      return;
    }
    this.setState({
      currentDateFormatted: (
        <span>
          {this.props.children}
          {` ${
            /*humanizeDuration(diff, {
            language: this.props.locale,
            round: true,
            units: ['w', 'd', 'h', 'm', 's']
          })*/ this.props.endDate.from(
              now
            )
          }`}
        </span>
      )
    });
  }

  render() {
    return (
      <div className={classes.Timer} style={this.props.styles}>
        <div className={classes.TimerCounter}>
          {this.state.currentDateFormatted}
        </div>
      </div>
    );
  }
}

export default Timer;
