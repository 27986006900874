import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import React, { Fragment } from 'react';
import Latex from '../Latex';

const CompetitionGuideline = () => (
  <div className='competition-guideline'>
    <FormattedMessage
    id={`competition.uploadGuidelines.default.1`} />
    <br />
    <FormattedMessage
      id={`competition.uploadGuidelines.default.2`} values={{
        1: Latex('\\hat{y}_1')
      }} />
    <br />
    <FormattedMessage
      id={`competition.uploadGuidelines.default.3`} values={{
        1: Latex('\\hat{y}_2')
      }} />
    <br />
    <FormattedMessage
      id={`competition.uploadGuidelines.default.4`} values={{
        1: Latex('\\hat{y}_3')
      }} />
    <br />
    <FormattedMessage
      id={`competition.uploadGuidelines.default.5`} />
    <br />
    <FormattedMessage
      id={`competition.uploadGuidelines.default.6`}
      values={{
        1: Latex('\\hat{y}_1'),
        2: Latex('\\hat{y}_2'),
        3: Latex('\\hat{y}_3')
      }}
    />
    <br/>
    <FormattedHTMLMessage id={`competition.uploadGuidelines.default.link`} />
  </div>
)

export default CompetitionGuideline;