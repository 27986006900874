import { updateObject } from '../../shared/utilities';
import { ITeam } from '@vas/competition-service-share';

interface IAction {
  type: string;
  teams: Array<ITeam>;
  team: ITeam;
  error: string;
  removed: boolean;
  response: any;
}

const INITIAL_STATE = {
  teams: [],
  team: null,
  status: null,
  isFetching: false,
  isStoring: false,
  didInvalidate: false,
  error: null,
  removed: null
};

// GET TEAM
const getTeamStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    team: null,
    isFetching: true,
    error: null,
    didInvalidate: false
  });
};

const getTeamSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    team: action.team,
    isFetching: false
  });
};

const getTeamFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

// GET TEAM
const getTeamsStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    teams: null,
    isFetching: true,
    error: null,
    didInvalidate: false
  });
};

const getTeamsSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    teams: action.response,
    isFetching: false
  });
};

const getTeamsFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true
  });
};

// DELETE TEAM
const deleteTeamStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isStoring: true,
    removed: null,
    error: null,
    didInvalidate: false
  });
};

const deleteTeamSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, { removed: action.removed, isStoring: false });
};

const deleteTeamFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    removed: false,
    didInvalidate: true,
    isStoring: false
  });
};

const teamReducer = (state = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    // READ/FETCHING (ALL)
    case `GET_TEAM_START`:
      return getTeamStart(state, action);
    case `GET_TEAM_SUCCESS`:
      return getTeamSuccess(state, action);
    case `GET_TEAM_FAIL`:
      return getTeamFail(state, action);
    // READ/FETCHING (ALL)
    case `GET_TEAMS_START`:
      return getTeamsStart(state, action);
    case `GET_TEAMS_SUCCESS`:
      return getTeamsSuccess(state, action);
    case `GET_TEAMS_FAIL`:
      return getTeamsFail(state, action);
    // DELETE
    case `DELETE_TEAM_START`:
      return deleteTeamStart(state, action);
    case `DELETE_TEAM_SUCCESS`:
      return deleteTeamSuccess(state, action);
    case `DELETE_TEAM_FAIL`:
      return deleteTeamFail(state, action);

    default:
      return state;
  }
};

export default teamReducer;
