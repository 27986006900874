import React, { Component } from 'react';
import styles from './style.module.scss';
import UploadBox from '../../UploadBox';
import { FormattedMessage } from 'react-intl';
import Button from '../../Button';
import TextInput from '../../InputTemplate/TextInput';
import ProgressBar from '../../ProgressBar';
import { ICompetition } from '@vas/competition-service-share';
import { DSCompetitionKind } from '@vas/dsc-share';
import UploadedFiles from '../../UploadedFiles';
import CompetitionImage from '../../CompetitionImage';

/**
 * Current component properties
 */
interface IProps {
  data: ICompetition;
  goToNextTab: () => void;
  setDecision: Function;
  setUpdateCompetition: () => void;
}

/**
 * Current component state
 */
interface IState {
  progress: {
    value: number;
    show: boolean;
  };
}

class AdminNewCompetitionChoose extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      progress: {
        value: 0,
        show: true
      }
    };
  }

  render() {
    const { progress } = this.state;
    const { setDecision, data, setUpdateCompetition } = this.props;

    return (
      <div
        className={[styles.AdminNewCompetitionChoose, 'u-fullWidth'].join(' ')}>
        <div className={'u-fullWidth'}>
          <div className={styles['AdminNewCompetition--row']}>
            <div className={styles['AdminNewCompetition--column']}>
              <TextInput
                value={data.title}
                controlled={false}
                labelId={'competition.title.label'}
                placeholderId={'competition.title.placeholder'}
                onChange={title => setDecision('title', title)}
                onBlur={() => setUpdateCompetition()}
              />
              <TextInput
                value={data.meta && data.meta.teacher}
                controlled={false}
                labelId={'competition.creator.label'}
                placeholderId={'competition.creator.placeholder'}
                onChange={creator => setDecision('meta.teacher', creator)}
                onBlur={() => setUpdateCompetition()}
              />
              <TextInput
                value={data.meta && data.meta.project}
                controlled={false}
                labelId={'competition.course.label'}
                placeholderId={'competition.course.placeholder'}
                onChange={course => setDecision('meta.project', course)}
                onBlur={() => setUpdateCompetition()}
              />
            </div>
            <div
              className={styles['AdminNewCompetition--column']}
              style={{
                alignItems: 'flex-start',
                transform: 'translateY(-50px)'
              }}>
              <label className={styles['AdminNewCompetitionChoose-label']}>
                <FormattedMessage
                  id={'admin.competition.crud.choose.upload.image.title'}
                />
              </label>
              <div
                className={[
                  styles.InputTemplate,
                  styles['InputTemplate--vertical']
                ].join(' ')}>
                <UploadedFiles
                  onDelete={() =>
                    setDecision('imageURL', null, setUpdateCompetition)
                  }
                  files={
                    data.imageURL
                      ? [{ name: 'Image', url: data.imageURL }]
                      : undefined
                  }
                />
                {!!data.imageURL ? (
                  <CompetitionImage src={data.imageURL} />
                ) : (
                  <UploadBox
                    title={<FormattedMessage id={'upload.image.title'} />}
                    endpoint={window.UPLOAD_ENDPOINT}
                    description={
                      <FormattedMessage
                        id={
                          'admin.competition.crud.choose.upload.image.dimensions'
                        }
                      />
                    }
                    accept={'image/jpeg, image/png'}
                    onSelectedFiles={(uploaded: any) => {
                      setDecision(
                        'imageURL',
                        uploaded.url,
                        setUpdateCompetition
                      );
                    }}
                    onUploaded={(uploaded: any) => {
                      setDecision(
                        'imageURL',
                        uploaded.url,
                        setUpdateCompetition
                      );
                    }}
                    progress={{
                      show: true,
                      value: 70
                    }}
                  />
                )}
                {!data.imageURL && progress && progress.show && (
                  <ProgressBar
                    styles={{ marginTop: '25px' }}
                    value={progress.value}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles['AdminNewCompetition--row']}>
          <div
            className={[
              styles['AdminNewCompetition--column'],
              'u-fullWidth'
            ].join(' ')}>
            <div
              className={[
                styles.InputTemplate,
                styles['InputTemplate--end']
              ].join(' ')}>
              <Button styles={{ margin: '0 30px' }} type={'secondary'}>
                <FormattedMessage id={'general.save'} />
              </Button>
              <Button
                styles={{ margin: 'auto 0 0' }}
                type={'primary'}
                clicked={() => {
                  this.props.goToNextTab();
                }}>
                <FormattedMessage id={'general.next'} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminNewCompetitionChoose;
