import React from 'react';
import AppLogo from '../../../assets/img/AppLogo.png';
import AreaLogo from '../../../assets/img/AreaLogo.png';
import WelcomeLogo from '../../../assets/img/WelcomeLogo.png';
import Logo from '../../Logo';
import styles from './style.module.scss';


interface IHeaderLogosProps {
  isAuthenticated: Boolean
}

const HeaderLogos = (props: IHeaderLogosProps) => (
  <div className={styles.OverHeader}>
    <Logo to={`/`} />
    {
      (props.isAuthenticated === true) ? <><img className={styles.AppLogo} src={AppLogo} alt={'AppLogo'} /><img className={styles.AreaLogo} src={AreaLogo} alt={'AreaLogo'} /></> : <img className={styles.WelcomeLogo} src={WelcomeLogo} alt={'WelcomeLogo'} />
    }
  </div>
)

export default HeaderLogos;

