import DSCClient from '@vas/dsc-client';

const getSubmissionActions = (client: DSCClient) => [
  {
    actionPrefix: 'getLastSubmission',
    actionTypePrefix: 'GET_LAST_SUBMISSION',
    action: client.getLastSubmission,
  },
  {
    actionPrefix: 'sendSubmission',
    actionTypePrefix: 'SEND_SUBMISSION',
    action: client.sendSubmission,
  }
];

export default getSubmissionActions;
