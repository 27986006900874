import React from 'react';
import buttonStyles from './style.module.scss';
import { NavLink } from 'react-router-dom';

interface buttonProps {
  children: any;
  clicked?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  to?: string;
  target?: string;
  styles?: object;
  disabled?: boolean;
  type?: string;
  small?: boolean;
  outline?: boolean;
  refresh?: boolean;
  mouseOver?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  mouseOut?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}

const button = ({
  target,
  children,
  clicked,
  styles,
  disabled,
  type,
  to,
  small,
  outline,
  refresh,
  mouseOut,
  mouseOver
}: buttonProps) => {
  const buttonClass = [
    buttonStyles.Button,
    disabled ? buttonStyles['Button--disabled'] : '',
    type ? buttonStyles[`Button--${type}`] : '', 
    small ? buttonStyles['Button--small'] : '',
    outline ? buttonStyles['Button--outline'] : ''
  ].join(' ');

  // if (refresh) {
  //   return (
  //     <a style={styles} className={buttonClass} href={to} target={target}>
  //       {children}
  //     </a>
  //   );
  // }

  return to && !disabled ? (
    <NavLink style={styles} className={buttonClass} to={to} target={target}>
      {children}
    </NavLink>
  ) : (
    <button
      disabled={disabled}
      style={styles}
      onClick={clicked}
      className={buttonClass}
      onMouseOut={mouseOut}
      onMouseOver={mouseOver}>
      {children}
    </button>
  );
};

export default button;
