const getPlayerActions = (client: any) => [
  {
    actionPrefix: 'getPlayers',
    actionTypePrefix: 'GET_PLAYERS',
    action: client.getPlayers,
  },
  {
    actionPrefix: 'setPlayers',
    actionTypePrefix: 'SET_PLAYERS',
    action: client.setPlayers,
  },
  {
    actionPrefix: 'appendPlayers',
    actionTypePrefix: 'APPEND_PLAYERS',
    action: client.appendPlayers,
  },
  {
    actionPrefix: 'deletePlayer',
    actionTypePrefix: 'DELETE_PLAYER',
    action: client.deletePlayer,
  },
  {
    actionPrefix: 'deletePlayers',
    actionTypePrefix: 'DELETE_PLAYERS',
    action: client.deletePlayers,
  }
];

export default getPlayerActions;
