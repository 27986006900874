import React, { Fragment } from 'react';
import classes from './style.module.scss';
import uploadIconUrl from '../../assets/icons/upload.svg';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { FormattedHTMLMessage } from 'react-intl';
interface UploadInfo {
  name: string;
  url: string;
  date: Date;
}

interface uploadBoxProps {
  title: any;
  description?: any;
  styles?: object;
  multiple?: boolean;
  accept?: string | undefined;
  progress?: { value: number; show: boolean };
  files?: any[];
  disabled?: boolean;
  endpoint?: string;
  onSelectedFiles?: (uploaded: any, rejected: any) => void;
  onUploaded?: (info: UploadInfo) => void;
  onDelete?: (index: number) => void;
  local?: boolean;
}

const uploadBox = ({
  title,
  description,
  styles,
  multiple,
  accept,
  onSelectedFiles = () => {},
  onUploaded = () => {},
  disabled,
  endpoint = '/upload',
  local = false
}: uploadBoxProps) => {
  const onDrop = async (acceptedFiles: File[], rejectedFiles: File[]) => {
    if (local) {
      onSelectedFiles(acceptedFiles, rejectedFiles);
    } else {
      for (const file of acceptedFiles) {
        const form = new FormData();
        form.append('file', file);
        const res = await fetch(`${endpoint}`, {
          method: 'POST',
          body: form
        });
        if (res.ok) {
          const uploadInfo = await res.json();
          onUploaded(uploadInfo);
          toast.success(() => <FormattedHTMLMessage id="file.uploaded" />, {
            toastId: 'file.uploaded'
          });
        } else {
          toast.error(() => <FormattedHTMLMessage id="file.error" />, {
            toastId: 'file.error'
          });
          console.log('error', res.status, await res.text());
        }
      }
    }
  };
  return (
    <div className={classes.UploadBoxContainer}>
      <Dropzone
        onDrop={onDrop}
        disabled={disabled || false}
        multiple={multiple ? multiple : false}
        accept={accept}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              style={styles}
              className={[
                [
                  classes.UploadBox,
                  disabled ? classes['is-disabled'] : ''
                ].join(' '),
                isDragActive ? classes['is-active'] : ''
              ].join(' ')}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <span className={classes['UploadBox-title']}>
                  <img
                    className={classes['UploadBox-image']}
                    src={uploadIconUrl}
                    alt="upload"
                  />
                  {title}
                </span>
              ) : (
                <Fragment>
                  <div className={classes['UploadBox-title']}>
                    <img
                      className={classes['UploadBox-image']}
                      src={uploadIconUrl}
                      alt="upload"
                    />
                    {title}
                  </div>
                  {description && (
                    <span className={classes['UploadBox-description']}>
                      {description}
                    </span>
                  )}
                </Fragment>
              )}
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};

export default uploadBox;
