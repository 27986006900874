import React from 'react';
import { Link } from 'react-scroll';
import classes from './style.module.scss';
import { FormattedMessage } from 'react-intl';

const navigation = () => {
	return (
		<ul className={classes.Navigation}>
			<li className={classes['Navigation-item']}>
				<Link
					className={classes['Navigation-link']}
					activeClass={classes['is-active']}
					spy={true}
					smooth={true}
					duration={500}
					offset={-50}
					to='intro'>
					<FormattedMessage id={'navigation.intro'} />
				</Link>
			</li>
			<li className={classes['Navigation-item']}>
				<Link
					className={classes['Navigation-link']}
					activeClass={classes['is-active']}
					spy={true}
					smooth={true}
					duration={500}
					offset={-50}
					to='rating'>
					<FormattedMessage id={'navigation.rating'} />
				</Link>
			</li>
			<li className={classes['Navigation-item']}>
				<Link
					className={classes['Navigation-link']}
					activeClass={classes['is-active']}
					spy={true}
					smooth={true}
					duration={500}
					offset={-50}
					to='submission'>
					<FormattedMessage id={'navigation.submission'} />
				</Link>
			</li>
			<li className={classes['Navigation-item']}>
				<Link
					className={classes['Navigation-link']}
					activeClass={classes['is-active']}
					spy={true}
					smooth={true}
					duration={500}
					offset={-50}
					to='leaderboard'>
					<FormattedMessage id={'navigation.leaderboard'} />
				</Link>
			</li>
		</ul>
	);
};

export default navigation;
