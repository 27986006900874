import React, { Component } from 'react';
import MatrixInput from './MatrixInput';
import range from 'lodash/range';
import styles from './style.module.scss';
import Latex from '../Latex';

interface Props {
  onChange: (value: any) => void;
  matrix: number[][];
  categories: number;
  disabled?: boolean;
}
interface State {}

class Matrix extends Component<Props, State> {
  handleMatrixChange(row: number, col: number, n: number) {
    const { onChange } = this.props;
    let newMatrix = [...this.props.matrix];
    newMatrix[row][col] = n;
    onChange(newMatrix);
  }
  render() {
    const { categories, matrix, disabled } = this.props;
    const blocks = categories + 1;
    return (
      <div className={styles.MatrixContainer}>
        <div className={styles.MatrixLabelW}>{Latex('W')} =</div>
        <div className={styles.MatrixLabelReal}>TRUE</div>
        <div className={styles.MatrixLabelForecast}>PREDICTED</div>
        <div className={styles.Matrix}>
          {range(blocks).map(row => (
            <div key={row} style={{ height: `${100 / blocks}%` }}>
              {range(blocks).map(col =>
                col === 0 && row === 0 ? (
                  <div
                    key={col}
                    className={styles.MatrixHeader}
                    style={{ width: `${100 / blocks}%` }}>
                    <div className={styles.MatrixHeaderYHat}>
                      {Latex('\\hat{y}')}
                    </div>
                    <div className={styles.MatrixHeaderY}>{Latex('y')}</div>
                  </div>
                ) : col === 0 || row === 0 ? (
                  <div
                    key={col}
                    className={styles.MatrixHeader}
                    style={{ width: `${100 / blocks}%` }}>
                    <div className={styles.MatrixIndexLabel}>
                      {col === 0 ? row : col}
                    </div>
                  </div>
                ) : (
                  <MatrixInput
                    key={col}
                    value={col === row ? 0 : matrix[row - 1][col - 1]}
                    disabled={disabled || col == row}
                    size={`${100 / blocks}%`}
                    onChange={(n: number) =>
                      this.handleMatrixChange(row - 1, col - 1, n)
                    }
                  />
                )
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Matrix;
