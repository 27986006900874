import React, { Fragment } from 'react';
import classes from './style.module.scss';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import CompetitionImage from '../CompetitionImage';
import { textTruncate } from '../../shared/helpers';

import playersIconUrl from '../../assets/icons/players.svg';
import leaderboardIconUrl from '../../assets/icons/leaderboard.svg';
import submissionIconUrl from '../../assets/icons/submission.svg';
import regressionIconUrl from '../../assets/icons/regression.svg';
import classificationIconUrl from '../../assets/icons/classification.svg';
import { NavLink } from 'react-router-dom';

import { DSCompetitionKind } from '@vas/dsc-share';

interface competitionCardProps {
  id: string;
  backgroundImageUrl?: string;
  title: string;
  project?: string;
  teacher?: string;
  duration?: string;
  players?: number;
  submissions?: number;
  kind?: DSCompetitionKind;
  to?: string;
  styles?: object;
  refresh?: boolean;
  hideOverlay?: boolean;
  disabled?: boolean;
  leaderboardLink: string;
}

const competitionCard = ({
  id,
  title,
  backgroundImageUrl,
  project,
  teacher,
  duration,
  players,
  submissions,
  to,
  styles,
  kind,
  refresh,
  hideOverlay,
  disabled,
  leaderboardLink
}: competitionCardProps) => {
  const longTitle = textTruncate(title, 50, '...');
  const shortTitle = textTruncate(title, 20, '...');

  const isRegression = kind === DSCompetitionKind.REGRESSION;

  const competitionCardClass = [
    classes.CompetitionCard,
    isRegression
      ? classes['CompetitionCard--violet']
      : classes['CompetitionCard--lightBlue']
  ].join(' ');

  let competitionCardTpl = (
    <div style={styles} className={competitionCardClass}>
      <CompetitionImage styles={{ margin: '10px' }} src={backgroundImageUrl}>
        <div className={classes['CompetitionCard-kind']}>
          {isRegression ? (
            <img src={regressionIconUrl} alt="regression" />
          ) : (
            <img src={classificationIconUrl} alt="classification" />
          )}
        </div>

        <h2 className={classes['CompetitionCard-title']}>{longTitle}</h2>
        {!hideOverlay && (
          <div className={classes['CompetitionCard-overlay']}>
            <div className={classes['CompetitionCard-content']}>
              <h2 className={classes['CompetitionCard-title']}>{shortTitle}</h2>
              <div className={classes['CompetitionCard-description']}>
                <FormattedMessage id={'label.course'} /> {project}
              </div>
              <div className={classes['CompetitionCard-description']}>
                <FormattedMessage id={'label.teacher'} /> {teacher}
              </div>
              <div className={classes['CompetitionCard-description']}>
                <FormattedMessage id={'label.duration'} />
                <strong>{duration}</strong>
              </div>

              <ul className={classes['CompetitionCard-list']}>
                <li className={classes['CompetitionCard-item']}>
                  <img src={playersIconUrl} alt={'players'} />
                  {players}
                </li>
                <li className={classes['CompetitionCard-item']}>
                  <img src={submissionIconUrl} alt={'submissions'} />
                  {submissions}
                </li>
                {!disabled && (
                  <li className={classes['CompetitionCard-item']}>
                    <NavLink to={leaderboardLink}>
                      <img src={leaderboardIconUrl} alt={'leaderboard'} />
                      <FormattedMessage id={`label.leaderboard`} />
                    </NavLink>
                  </li>
                )}
              </ul>

              {to && !disabled && (
                <Button
                  styles={{ margin: 'auto 0 0', width: '200px' }}
                  to={to}
                  type={'secondary'}
                  refresh={refresh}
                  small>
                  <FormattedMessage id={'general.join'} />
                </Button>
              )}
            </div>
          </div>
        )}
      </CompetitionImage>
    </div>
  );

  competitionCardTpl = !hideOverlay ? (
    competitionCardTpl
  ) : (
    <a style={{ display: 'inline-flex' }} href={to}>
      {competitionCardTpl}
    </a>
  );

  return competitionCardTpl;
};

export default competitionCard;
