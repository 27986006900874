import { InputTemplate, IProps, IState } from '../';
import classes from './styles.module.scss';
import React from 'react';
import { SelectItem, DEFAULT as DEF_SI } from './SelectItem/index';
import { ISelectItem } from '../../../shared/interfaces';
import downIcon from '../../../assets/icons/arrow_down.svg';

export interface ISelectProps extends IProps {
  value: string;
  /**
   * Reference for React Object
   */
  reference?: any;
  /**
   * Select items of type ISelectItem
   * @see ISelectItem
   */
  items: ISelectItem[];
  /**
   * Set to true if list must show up
   */
  showList?: boolean;
  /**
   * Set to true if no empty option is allowed
   */
  noEmpty?: string;
  /**
   * Function called when a SelectItem is clicked
   * @see ISelectItem
   */
  onItemClicked?: (item: ISelectItem) => void;
  /**
   * Function called when the Select is clicked
   */
  onClick?: (ev: any) => void;
}

export interface ISelectState extends IState {
  /**
   * True if list must show
   */
  showList?: boolean;
  /**
   * Current select Item
   */
  selectedItem?: ISelectItem;
  /**
   * Current filtered Items
   */
  filteredItems?: ISelectItem[];
  /**
   * Show or hide trigger button
   */
  showTriggerButton?: boolean;
}

/**
 * Default props
 */
export const DEFAULT_PROPS = {
  value: '',
  items: [DEF_SI],
  onItemClicked: (item: ISelectItem) => {},
  placeholder: 'Select an item...',
  selectedItem: null
};

export const DEFAULT_STATE = {
  value: '',
  showTriggerButton: true
};

/**
 * This class implements InputTemplate
 */
export abstract class SelectInput extends InputTemplate<
  ISelectProps,
  ISelectState
> {
  props: ISelectProps = DEFAULT_PROPS;
  state: ISelectState = DEFAULT_STATE;
  /**
   * Override parent classes
   */
  classes = classes;

  constructor(props: ISelectProps) {
    super(props);
    this.init(props);
  }

  /**
   * Initialize the select
   * @param props the select properties
   */
  init(props: ISelectProps) {
    this.props.onClick = (ev: any) => this.onSelectClick(ev);
    this.props = Object.assign(this.props, props);

    this.state = this.defaultState();
    // check if select is controlled, if it is then set the selected item to the passed value
    if (this.props.controlled && this.props.value !== '') {
      this.state.selectedItem = this.props.items.filter(
        o => o.value === this.props.value
      )[0];
    }
  }

  /**
   * Function called on select click, toggle showList status
   * @param ev MouseEvent
   */
  onSelectClick(ev: any): void {
    const showList = !this.state.showList;
    this.state.showList = showList;
    this.setState(this.state);
  }

  /**
   * Returns all select options
   */
  allOptions(): JSX.Element[] {
    const items =
      this.state.filteredItems ||
      (this.props.noEmpty ? this.props.items : [DEF_SI, ...this.props.items]);
    return items.map((item: ISelectItem, i: number) => {
      /// da sistemare selectedItem
      return (
        <SelectItem
          key={i}
          onClick={(item: ISelectItem) => {
            if (this.props.onItemClicked) this.props.onItemClicked(item);
            if (!this.props.controlled) {
              this.value(item);
            }
            this.onSelectClick(null);
          }}
          selectName={name}
          item={item}
        />
      );
    });
  }

  /**
   * Set selected item if exists
   * @param item?
   */
  value(item?: ISelectItem): string {
    if (typeof item !== 'undefined') {
      this.state.selectedItem = item;
    }
    return super.value(item ? item.value : '');
  }

  render() {
    return (
      <div
        className={[
          classes.Input,
          classes.SelectInput,
          classes['Input--vertical'],
          this.state.showList ? classes['is-open'] : '',
          this.getCustomClasses()
        ].join(' ')}>
        {this.getLabel()}
        <div
          style={this.props.styles}
          className={[classes.Input, classes['Input--vertical']].join(' ')}
          ref={this.props.reference}>
          <button
            className={classes['SelectInput-button']}
            onClick={(ev: any) => {
              this.onSelectClick(ev);
            }}
            disabled={this.props.disabled}
            data-name={name}>
            {this.state.selectedItem != null
              ? this.state.selectedItem.label
              : this.props.placeholderId}
            <img
              className={classes['SelectInput-arrow']}
              src={downIcon}
              alt="down"
            />
          </button>
          <div
            className={[
              classes.Input,
              classes['Input--vertical'],
              classes['SelectInput-list'],
              this.state.showList ? classes['is-visible'] : ''
            ].join(' ')}>
            {this.allOptions()}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Returns a default dynamic state, according with props
   */
  private defaultState(): ISelectState {
    return {
      value:
        this.props.noEmpty && this.props.items.length > 0
          ? this.props.items[0].value
          : '',
      showList: this.props.showList || false,
      selectedItem:
        this.props.noEmpty && this.props.items.length > 0
          ? this.props.items[0]
          : DEF_SI
    };
  }
}

export default SelectInput;
