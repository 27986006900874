import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import styles from './style.module.scss';
import ClassificationSvg from '../../../../assets/img/classification.svg';
import Latex from '../../../Latex';

interface Props {
  setUpdateCompetition: (lastStep?: boolean) => void;
}
interface State {}

class UploadDataset extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  getDescriptions() {
    return [
      {
        title: 's',
        descriptionID: 'admin.upload.classification.image.description.S',
        values: {}
      },
      {
        title: 'y',
        descriptionID: 'admin.upload.classification.image.description.Y',
        values: {}
      },
      {
        title: 'X',
        descriptionID: 'admin.upload.classification.image.description.X',
        values: {}
      }
    ];
  }
  render() {
    return (
      <div className={styles.UploadDataset}>
        <span className={`AdminSectionTitle ${styles['UploadDataset-title']}`}>
          <FormattedMessage id={`admin.upload.dataset`} />
        </span>
        <div className={styles['UploadDataset-subtitle']}>
          <FormattedMessage id={`admin.upload.dataset.description`} />
        </div>
        <div className={styles[`ClassificationUploadDataset-imageContainer`]}>
          <img
            src={ClassificationSvg}
            className={styles[`ClassificationUploadDataset-image`]}
          />
          <div
            className={styles[`ClassificationUploadDataset-imageDescription`]}>
            {this.getDescriptions().map((desc, index) => (
              <div
                key={index}
                className={
                  styles[`ClassificationUploadDataset-imageDescriptionItem`]
                }>
                <div
                  className={`AdminSectionTitle ${
                    styles[`AdminSectionTitle`]
                  }`}>
                  {desc.title}
                </div>
                <div className={`AdminSectionText`}>
                  <FormattedHTMLMessage id={desc.descriptionID} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{ maxWidth: '800px', margin: '100px 0' }}
          className={`AdminSectionText ${styles['UploadDataset-text']}`}>
          <FormattedHTMLMessage id="admin.upload.dataset.notes" />
        </div>
      </div>
    );
  }
}

export default UploadDataset;
