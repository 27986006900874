const getTeamActions = (client: any) => [
  {
    actionPrefix: 'getTeam',
    actionTypePrefix: 'GET_TEAM',
    action: client.getTeam
  },
  {
    actionPrefix: 'getTeams',
    actionTypePrefix: 'GET_TEAMS',
    action: client.getTeams
  },
  {
    actionPrefix: 'deleteTeam',
    actionTypePrefix: 'DELETE_TEAM',
    action: client.deleteTeam
  }
];

export default getTeamActions;
