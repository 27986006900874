import { InputTemplate, IProps } from '../';
import classes from './styles.module.scss';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

interface INumberProps extends IProps {
  value: number;
  /**
   * Input placeholder text
   */
  placeholderId?: string;
  onBlur?: (value: number) => void;
}
/**
 * This class implements InputTemplate
 */
export class NumberInput extends InputTemplate<INumberProps> {
  props: INumberProps = { value: 0 };
  constructor(props: INumberProps) {
    super(props);
    this.props = props;
  }
  render() {
    const { disabled } = this.props;
    return (
      <div className={[classes.NumberInput, this.getCustomClasses()].join(' ')}>
        {this.props.labelId && (
          <label>
            <FormattedMessage id={this.props.labelId} />
          </label>
        )}
        <input
          type="number"
          value={this.props.value}
          name={this.props.name}
          disabled={disabled}
          placeholder={this.props.placeholderId || 'no.placeholder.set'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            this.value(value);
            if (this.props.onChange) this.props.onChange(value);
          }}
          onBlur={(e: ChangeEvent<HTMLInputElement>) => {
            const value = Number(e.target.value);
            this.value(value);
            if (this.props.onBlur) this.props.onBlur(value);
          }}
        />
      </div>
    );
  }
}

export default NumberInput;
