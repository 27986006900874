import React, { Component, CSSProperties } from 'react';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
export interface IProps {
  /**
   * InputTemplate default value (or current)
   */
  value: any;
  /**
   * If true, the value is controlled by the parent
   */
  controlled?: boolean;
  /**
   * InputTemplate name
   */
  name?: string;
  /**
   * InputTemplate custom styles
   */
  styles?: CSSProperties;
  /**
   * InputTemplate disabled status
   */
  disabled?: boolean;
  /**
   * If true, sets error status on InputTemplate
   */
  error?: boolean;
  /**
   * If true, a value must exists
   */
  required?: boolean;
  /**
   * Custom classes
   */
  customClasses?: string[];
  /**
   * Input label id for dictionary
   */
  labelId?: string;
  /**
   * Select placeholder
   */
  placeholderId?: string;
  /**
   * Theme for TextInput
   */
  theme?: 'dark' | 'light' | undefined;
  /**
   * On Change event
   */
  onChange?: (el: any) => void;
}

export interface IState {
  /**
   * Current value
   */
  value: any;
}

const DEFAULTPROPS = {
  value: ''
};

const DEFAULTSTATE = {
  value: ''
};

/**
 * InputTemplate
 * ---------------------------
 * Abstract class for form inputs.
 * PROPERTIES
 * ----------------------------
 * @param P the props interface
 * @param S the state interface
 */
export class InputTemplate<P = {}, S = {}> extends Component<
  P | IProps,
  S | IState
> {
  /**
   * Current props
   */
  props: IProps = DEFAULTPROPS;
  /**
   * Current state
   */
  state: IState = DEFAULTSTATE;
  /**
   * Css Classes for element - remember to override in child
   */
  classes: any;
  /**
   * Custom classes not driven by other components
   */
  internalClasses = [''];

  /**
   * Class constructor
   * @param props current props
   * @param state current state
   */
  constructor(props?: P, state?: S) {
    super(props as P, state);
    this.classes = classes;
  }

  /**
   * Returns custom classes for the Input
   */
  getCustomClasses(): string {
    let res = [];
    let c: any;
    if (this.props.customClasses) {
      for (c of this.props.customClasses) {
        res.push(this.classes[c]);
      }
    }

    if (this.internalClasses) {
      for (c of this.internalClasses) {
        res.push(this.classes[c]);
      }
    }

    if (this.props.disabled) {
      res.push(this.classes['is-disabled']);
    }

    if (this.props.required) {
      res.push(this.classes['is-required']);
    }

    if (this.props.theme && this.props.theme === 'dark') {
      res.push(this.classes['is-dark']);
    }

    return res.join(' ');
  }

  /**
   * Returns current InputTemplate value
   */
  value(value?: any): any | void {
    // if value is null read the value
    if (typeof value === 'undefined') {
      return this.props.controlled ? this.props.value : this.state.value;
    }
    // else set the value
    if (!this.props.controlled) {
      this.setState({ value });
    }
  }
  /**
   * Validate current InputTemplate
   */
  validate(): boolean {
    return this.props.required ? this.value() != null : true;
  }

  getLabel(): JSX.Element | void {
    if (this.props.labelId) {
      return (
        <label className={classes['Input-label']}>
          <FormattedMessage id={this.props.labelId || 'no.label.set'} />
        </label>
      );
    }
  }
}
