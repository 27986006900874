import React, { Component } from 'react';
import classes from './style.module.scss';
import { IOption } from '../../shared/interfaces';

interface IProps {
	label: any;
	options: Array<IOption>;
	clicked: (value: string) => void;
	handleSwitchFilterClicked?: (value: string) => void;
	type?: string;
	disabled?: boolean;
}

class SwitchFilter extends Component<IProps> {
	constructor(props: IProps) {
		super(props);

		this.handleSwitchFilterClicked = this.handleSwitchFilterClicked.bind(this);
	}

	handleSwitchFilterClicked = (value: string) => {
		this.props.clicked(value);
	};

	render() {
		const { label, options, type, disabled }: IProps = this.props;

		const switchFilterClass = [
			classes.SwitchFilter,
			disabled ? classes['SwitchFilter--disabled'] : '',
			type ? classes[`SwitchFilter--${type}`] : ''
		].join(' ');

		const optionsTpl = options.map((option: IOption) => {
			const { value, label, selected } = option;

			const optionClass = [
				classes['SwitchFilter-option'],
				selected ? classes['SwitchFilter-option--selected'] : ''
			].join(' ');

			return (
				<button
					key={value}
					onClick={() => this.handleSwitchFilterClicked(value)}
					className={optionClass}>
					{label}
				</button>
			);
		});

		return (
			<div className={switchFilterClass}>
				{label && (
					<label className={classes['SwitchFilter-label']}>{label}</label>
				)}
				<div className={classes['SwitchFilter-content']}>{optionsTpl}</div>
			</div>
		);
	}
}

export default SwitchFilter;
