import { updateObject } from '../../shared/utilities';
import { ICompetition, IPage, IPlayer } from '@vas/competition-service-share';

interface IAction {
  type: string;
  players: IPage<ICompetition>;
  player: IPlayer;
  error: string;
  removed: boolean;
  response?: any;
}

const INITIAL_STATE = {
  players: null,
  player: null,
  status: null,
  isFetching: false,
  isStoring: false,
  didInvalidate: false,
  error: null,
  removed: null
};

// APPEND PLAYER
const appendPlayersStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    appendPlayersResults: null,
    isStoring: true,
    error: null,
    didInvalidate: false,
    isStored: null
  });
};

const appendPlayersSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    refetchPlayers: true,
    appendPlayersResults: action.response,
    isStoring: false
  });
};

const appendPlayersFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isStoring: false,
    didInvalidate: true
  });
};

// GET PLAYERS
const getPlayersStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    refetchPlayers: false,
    isFetching: true,
    error: null,
    didInvalidate: false
  });
};

const getPlayersSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    players: action.response,
    isFetching: false
  });
};

const getPlayersFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.response.error,
    isFetching: false,
    didInvalidate: true
  });
};

// SET PLAYERS
const setPlayersStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    player: null,
    isStoring: true,
    error: null,
    didInvalidate: false,
    isStored: null
  });
};

const setPlayersSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    refetchPlayers: true,
    player: action.response.player,
    isStoring: false
  });
};

const setPlayersFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.error,
    isStoring: false,
    didInvalidate: true
  });
};

// DELETE PLAYER
const deletePlayerStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isStoring: true,
    removed: null,
    error: null,
    didInvalidate: false
  });
};

const deletePlayerSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    refetchPlayers: true,
    removed: action.removed,
    isStoring: false
  });
};

const deletePlayerFail = (state = INITIAL_STATE, action: IAction) => {
  console.log('action deletePlayerFail', action);
  return updateObject(state, {
    error: action.error,
    removed: false,
    didInvalidate: true,
    isStoring: false
  });
};

// DELETE PLAYERS
const deletePlayersFail = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    error: action.response.error,
    removed: false,
    didInvalidate: true,
    isStoring: false
  });
};

const deletePlayersStart = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    isStoring: true,
    removed: null,
    error: null,
    didInvalidate: false
  });
};

const deletePlayersSuccess = (state = INITIAL_STATE, action: IAction) => {
  return updateObject(state, {
    removed: action.response.removed,
    isStoring: false
  });
};

const playerReducer = (state = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    // READ/FETCHING (ALL)
    case `GET_PLAYERS_START`:
      return getPlayersStart(state, action);
    case `GET_PLAYERS_SUCCESS`:
      return getPlayersSuccess(state, action);
    case `GET_PLAYERS_FAIL`:
      return getPlayersFail(state, action);
    // SET
    case `SET_PLAYERS_START`:
      return setPlayersStart(state, action);
    case `SET_PLAYERS_SUCCESS`:
      return setPlayersSuccess(state, action);
    case `SET_PLAYERS_FAIL`:
      return setPlayersFail(state, action);
    // APPEND
    case `APPEND_PLAYERS_START`:
      return appendPlayersStart(state, action);
    case `APPEND_PLAYERS_SUCCESS`:
      return appendPlayersSuccess(state, action);
    case `APPEND_PLAYERS_FAIL`:
      return appendPlayersFail(state, action);
    // DELETE
    case `DELETE_PLAYER_START`:
      return deletePlayerStart(state, action);
    case `DELETE_PLAYER_SUCCESS`:
      return deletePlayerSuccess(state, action);
    case `DELETE_PLAYER_FAIL`:
      return deletePlayerFail(state, action);
    // DELETE
    case `DELETE_PLAYERS_START`:
      return deletePlayersStart(state, action);
    case `DELETE_PLAYERS_SUCCESS`:
      return deletePlayersSuccess(state, action);
    case `DELETE_PLAYERS_FAIL`:
      return deletePlayersFail(state, action);

    default:
      return state;
  }
};

export default playerReducer;
